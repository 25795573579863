import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_URL
const baseService = axios.create({})

baseService.interceptors.request.use(
	(config) => {
		config.baseURL = baseUrl
		config.headers.accept = 'application/json'
		return config
	},
	(error) => {
		if (!(error instanceof Error)) {
			error = new Error(error)
		}

		return error
	}
)

baseService.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		const { status } = error.response || {}
		console.error(status || 'An error occurred')

		const errorStatus = error?.response?.status
		if (error.code === 'ERR_NETWORK' || (errorStatus && (errorStatus < 200 || errorStatus > 299) && (errorStatus < 400 || errorStatus > 499))) throw error
	}
)

export default baseService
