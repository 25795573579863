import { makeAutoObservable } from 'mobx'
import { fetchAllNews } from '../services/newsService'
import { ROW_PER_PAGE } from '../constants/constant'

class NewsStore {
	data = []
	isLoading = false
	currentPage = 1
	hasNext = false

	constructor() {
		makeAutoObservable(this)
	}

	fetchData = async () => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchAllNews(this.currentPage, ROW_PER_PAGE)
			if (this.currentPage === 1) {
				this.data = response.data.data
			} else {
				this.data = this.data.concat(response.data.data)
			}
			this.hasNext = response.data.hasNextPage
		} catch (error) {
			console.error(error)
		} finally {
			this.isLoading = false
		}
	}

	nextPage = async () => {
		this.currentPage += 1
		this.fetchData()
	}
}

const newsStore = new NewsStore()
export default newsStore
