import React from 'react'
import { useTranslation } from 'react-i18next'
import ProcedureSection from '../../components/Investment/RegulationsIncentives/ProcedureSection'
import LicenseSection from '../../components/Investment/RegulationsIncentives/LicenseSection'
import IncentiveSection from '../../components/Investment/RegulationsIncentives/IncentiveSection'
import HeadingUnderline from '../../components/HeadingUnderline'
import Sidebar from '../../components/Sidebar'

function RegulationInsentive() {
	const { t } = useTranslation()
	const tabs = [
		{
			key: 'procedure',
			content: <ProcedureSection />
		},
		{
			key: 'license',
			content: <LicenseSection />
		},
		{
			key: 'incentive',
			content: <IncentiveSection />
		}
	]

	return (
		<>
			<h1 className='text-3xl font-bold mb-2 mt-8'>{t('procedure-invest')}</h1>
			<HeadingUnderline />
			<div className='flex flex-col md:flex-row mt-7'>
				<Sidebar tabData={tabs} contentWidth={'50'} />
				<div className='w-full md:w-1/4 flex flex-col gap-5'>
					<div className='bg-gray-200'>
						<div className='flex justify-center items-center'>
							<img src='/assets/images/Logo_BKPM.webp' alt='Logo BKPM' className='p-5 w-40' />
						</div>
						<div className='bg-primary-light h-10 text-white flex justify-center items-center'>{t('contact-us')}</div>
						<div className='p-3 flex flex-col gap-2'>
							<div className='flex gap-2'>
								<div className='w-1/5 text-2xl flex justify-center'>
									<i className='fa fa-map-marker'></i>
								</div>
								<div className='w-4/5'>
									<a
										className='hover:text-gold'
										href='https://www.google.co.id/maps/place/Badan+Koordinasi+Penanaman+Modal+(BKPM)/@-6.2268264,106.816056,19z/data=!4m13!1m7!3m6!1s0x2e69f1561dd21e47:0x3454eb0c8ed94391!2sJl.+Jend.+Gatot+Subroto+No.44,+Karet+Semanggi,+Kecamatan+Setiabudi,+Kota+Jakarta+Selatan,+Daerah+Khusus+Ibukota+Jakarta+12930!3b1!8m2!3d-6.2268889!4d106.8163582!3m4!1s0x2e69f157946a6f6d:0xd6377b76be2cd0a5!8m2!3d-6.2266941!4d106.8162006'
									>
										Jl. Jend. Gatot Subroto No.44, Daerah Khusus Jakarta 12930
									</a>
								</div>
							</div>
							<div className='flex gap-2'>
								<div className='w-1/5 text-2xl flex justify-center'>
									<i className='fa fa-phone'></i>
								</div>
								<div className='w-4/5'>(+6221) 5252008</div>
							</div>
							<div className='flex gap-2'>
								<div className='w-1/5 text-2xl flex justify-center'>
									<i className='fa fa-envelope'></i>
								</div>
								<div className='w-4/5'>info@bkpm.go.id</div>
							</div>
							<div className='flex gap-2'>
								<div className='w-1/5 text-2xl flex justify-center'>
									<i className='fa fa-globe'></i>
								</div>
								<div className='w-4/5'>
									<a href='https://www.bkpm.go.id' className='hover:text-gold'>
										www.bkpm.go.id
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className='bg-gray-200'>
						<div className='flex justify-center items-center'>
							<img src='/assets/images/Logo_DPMTSP.png' alt='Logo DPMTSP' className='p-5 w-40' />
						</div>
						<div className='bg-primary-light h-10 text-white flex justify-center items-center'>{t('contact-us')}</div>
						<div className='p-3 flex flex-col gap-2'>
							<div className='flex gap-2'>
								<div className='w-1/5 text-2xl flex justify-center'>
									<i className='fa fa-map-marker'></i>
								</div>
								<div className='w-4/5'>
									<a
										className='hover:text-gold'
										href='https://www.google.co.id/maps/place/DPMPTSP+Provinsi+Jawa+Tengah/@-6.9832879,110.4054576,17z/data=!3m1!4b1!4m5!3m4!1s0x2e708b4927db6189:0x439c487706e546a4!8m2!3d-6.9832879!4d110.4076463'
									>
										Jl. Mgr Soegiyopronoto No.1 Kota Semarang, Jawa Tengah 50131
									</a>
								</div>
							</div>
							<div className='flex gap-2'>
								<div className='w-1/5 text-2xl flex justify-center'>
									<i className='fa fa-phone'></i>
								</div>
								<div className='w-4/5'>(+6224) 3547091 - 3547438 - 3541487</div>
							</div>
							<div className='flex gap-2'>
								<div className='w-1/5 text-2xl flex justify-center'>
									<i className='fa fa-globe'></i>
								</div>
								<div className='w-4/5'>
									<a href='http://dpmptsp.jatengprov.go.id/' className='hover:text-gold'>
										dpmptsp.jatengprov.go.id
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default RegulationInsentive
