import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { investmentRealizationStore } from '../../stores/_rootStore'
import Breadcrumb from '../../components/Breadcrumb'
import { observer } from 'mobx-react'
import i18n from '../../i18n'

function InvestmentRealization() {
	const { t } = useTranslation()

	useEffect(() => {
		const fetchData = async () => {
			try {
				await investmentRealizationStore.fetchData()
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
	}, [])

	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('invest') }]

	return (
		<div className='flex flex-col justify-center mb-12'>
			<div className='max-w-6xl w-full mt-5 md:mt-24 p-2 md:p-0 md:mx-auto'>
				<Breadcrumb items={breadcrumbItems} />
				{investmentRealizationStore.isLoading && <p>Loading...</p>}
				<div className='flex flex-col gap-5 py-5'>
					{investmentRealizationStore.data &&
						(i18n.language === 'id' ? (
							<>
								<img src={`${process.env.REACT_APP_IMAGE_URL}${investmentRealizationStore.data?.post_meta.filter((item) => item.meta_key === 'bannerpma')[0].meta_value}`} alt='banner' />
								<img src={`${process.env.REACT_APP_IMAGE_URL}${investmentRealizationStore.data?.post_meta.filter((item) => item.meta_key === 'bannerpmdn')[0].meta_value}`} alt='banner' />
							</>
						) : (
							<>
								<img src={`${process.env.REACT_APP_IMAGE_URL}${investmentRealizationStore.data?.post_meta.filter((item) => item.meta_key === 'bannerpmaen')[0].meta_value}`} alt='banner' />
								<img src={`${process.env.REACT_APP_IMAGE_URL}${investmentRealizationStore.data?.post_meta.filter((item) => item.meta_key === 'bannerpmdnen')[0].meta_value}`} alt='banner' />
							</>
						))}
				</div>
			</div>
		</div>
	)
}

export default observer(InvestmentRealization)
