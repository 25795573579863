import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "flag-icons/css/flag-icons.min.css";
import { FaRegHandshake, FaDollarSign } from "react-icons/fa";

const LanguageDropdown = ({ onLanguageChange, currentLanguage }) => {
	const languageOptions = [
		{ code: "id", label: "Indonesia", flagCode: "id" },
		{ code: "en", label: "English", flagCode: "gb" },
		{ code: "ko", label: "Korea", flagCode: "kr" },
		{ code: "zh", label: "Chinese", flagCode: "cn" },
		{ code: "ja", label: "Japanese", flagCode: "jp" },
	];

	return (
		<ul className="absolute bg-secondary text-white shadow-md rounded top-12 xl:right-0">
			{languageOptions.map((option) => (
				<li key={option.code}>
					<button
						onClick={() => onLanguageChange(option.code)}
						className={`px-6 py-2 w-full flex items-center hover:bg-gray-100 hover:text-black ${
							option.code === currentLanguage ? "" : ""
						}`}
					>
						{/* <span className={`fi fi-${option.flagCode} mr-2`}></span> */}
						{option.label}
					</button>
				</li>
			))}
		</ul>
	);
};

const Header = () => {
	const [isSearchVisible, setSearchVisible] = useState(false);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [keyword, setKeyword] = useState("");
	const { t, i18n } = useTranslation();
	const location = useLocation();

	const toggleDropdown = () => setDropdownVisible((prev) => !prev);

	const toggleSearchScreen = () => {
		setSearchVisible((prev) => !prev);
		if (!isSearchVisible) {
			document.getElementById("keyword")?.focus();
		}
	};

	const handleSearchSubmit = (e) => {
		e.preventDefault();
		window.location.href = `/search/find?keyword=${keyword}`;
	};

	const langMapping = {
		indonesia: "id",
		english: "en",
		korea: "ko",
		mandarin: "zh",
		japanese: "ja",
	};

	const changeLanguageCI = (langCode) => {
		const ciLanguageName = Object.keys(langMapping).find(
			(key) => langMapping[key] === langCode
		);

		if (!ciLanguageName) {
			return;
		}

		localStorage.setItem("i18nextLng", langCode);
		fetch(`/language/change/${ciLanguageName}`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		}).catch((error) => console.error("Error changing language:", error));
	};

	const handleChangeLanguage = (code) => {
		i18n.changeLanguage(code);
		changeLanguageCI(code);
		setDropdownVisible(false);
	};


	const navItems = [
		{ to: '/', label: t('home'), icon: null },
		{ to: '/investment', label: t('invest'), icon: FaRegHandshake },
		{
			to: '/trade',
			label: t('commerce'),
			image: 'boat_icon.png'
		},
		{
			href: '/pariwisata/pariwisata',
			label: t('tourism'),
			image: 'pariwisata_white.png'
		},
		{
			href: '/investment_challenge',
			label: t('investment-challenge'),
			icon: FaDollarSign
		},
		{ to: '/about_us', label: t('about-us'), icon: null },
		{ to: '/faq', label: t('faq'), icon: null },
		{ to: '/contact_us', label: t('contact-us'), icon: null }
	]

	return (
		<>
			<nav className="top-0 w-full z-90 bg-primary text-white shadow font-thin">
				<div className="mx-8 flex flex-row justify-between items-center py-2">
					<div className="navbar-header flex items-center">
						<Link to="/">
							<img src="/assets/images/logo.webp" alt="Logo" className="h-12" />
						</Link>
					</div>

					<div className="navbar-header flex xl:hidden items-center">
						<div
							onClick={() => setMobileMenuOpen((prev) => !prev)}
							className="font-bold cursor-pointer"
							to="/"
						>
							MENU
						</div>
					</div>

					<div className="navbar-collapse items-center mt-4 xl:mt-0 hidden xl:flex">
						<ul className="flex space-x-4 gap-4 flex-nowrap items-center justify-center">
							{navItems.map((item, index) => (
								<li key={index}>
									{item.to ? (
										<Link
											to={item.to}
											className={`nav-link ${
												location.pathname === item.to ? "font-bold" : ""
											}`}
										>
											{item.icon && <item.icon className="icon-class" />}
											{item.image && (
												<img
													src={`/assets/images/${item.image}`}
													className="w-4"
												/>
											)}
											{item.label}
										</Link>
									) : (
										<a
											href={item.href}
											className={`nav-link ${
												location.pathname === item.href ? "font-bold" : ""
											}`}
										>
											{item.icon && <item.icon className="icon-class" />}
											{item.image && (
												<img
													src={`/assets/images/${item.image}`}
													className="w-4"
												/>
											)}
											{item.label}
										</a>
									)}
								</li>
							))}

							<li>
								<button onClick={toggleSearchScreen} className="nav-link">
									<i className="fa fa-search"></i>
								</button>
							</li>
							<li className="dropdown relative">
								<button
									onClick={toggleDropdown}
									className="flex gap-2 items-center"
								>
									{t("language")}
									<i className="fa fa-caret-down"></i>
								</button>
								{dropdownVisible && (
									<LanguageDropdown
										onLanguageChange={handleChangeLanguage}
										currentLanguage={i18n.language}
									/>
								)}
							</li>
						</ul>
					</div>
				</div>

				{mobileMenuOpen && (
					<div className="navbar-collapse items-center mt-4 xl:mt-0 xl:hidden">
						<ul className="flex gap-8 px-12 pb-8 flex-col xl:flex-nowrap justify-center">
							{navItems.map((item, index) => (
								<li key={index} onClick={() => setMobileMenuOpen(false)}>
									{item.to ? (
										<Link
											to={item.to}
											className={`nav-link ${
												location.pathname === item.to ? "font-bold" : ""
											}`}
										>
											{item.icon && (
												<>
													<i className={`fa ${item.icon}`}></i>
												</>
											)}
											{item.image && (
												<>
													<img
														src={`/assets/images/${item.image}`}
														className="w-4"
													/>{" "}
												</>
											)}
											{item.label}
										</Link>
									) : (
										<a
											href={item.href}
											className={`nav-link ${
												location.pathname === item.href ? "font-bold" : ""
											}`}
										>
											{item.icon && (
												<>
													<i className={`fa ${item.icon}`}></i>
												</>
											)}
											{item.image && (
												<>
													<img
														src={`/assets/images/${item.image}`}
														className="w-4"
													/>{" "}
												</>
											)}
											{item.label}
										</a>
									)}
								</li>
							))}
							<li onClick={() => setMobileMenuOpen(false)}>
								<button onClick={toggleSearchScreen} className="nav-link">
									<i className="fa fa-search"></i>
								</button>
							</li>
							<li
								className="dropdown relative"
								onClick={() => setMobileMenuOpen(false)}
							>
								<button
									onClick={toggleDropdown}
									className="flex gap-2 items-center"
								>
									{t("language")}
									<i className="fa fa-caret-down"></i>
								</button>
								{dropdownVisible && (
									<div className="absolute">
										<LanguageDropdown
											onLanguageChange={handleChangeLanguage}
											currentLanguage={i18n.language}
										/>
									</div>
								)}
							</li>
						</ul>
					</div>
				)}
			</nav>

			{isSearchVisible && (
				<div className="fixed inset-0 bg-black text-white bg-opacity-75 flex items-start pt-[20vh] justify-center">
					<button
						onClick={toggleSearchScreen}
						className="mt-4 fixed right-32 top-16 text-2xl"
					>
						<i className="fa fa-close"></i>
					</button>

					<div className="flex flex-col items-center justify-center w-full">
						<h1 className="mb-8 font-semibold">{t("search")}</h1>
						<form
							onSubmit={handleSearchSubmit}
							className="flex justify-center gap-2 w-full"
						>
							<input
								type="text"
								id="keyword"
								value={keyword}
								onChange={(e) => setKeyword(e.target.value)}
								placeholder={t("search")}
								className="border text-black border-gray-300 py-2 px-4 rounded w-full max-w-[420px]"
							/>
							<button
								type="submit"
								className="w-12 h-12 flex items-center justify-center bg-primary text-white p-2 rounded"
							>
								<i className="fa fa-search"></i>
							</button>
						</form>
					</div>
				</div>
			)}
		</>
	);
};

export default Header;
