import React from 'react'
import { useTranslation } from 'react-i18next'
import 'font-awesome/css/font-awesome.min.css'

const ProcedureSection = () => {
	const { t } = useTranslation()

	return (
		<div>
			<div className='mb-12'>
				<h4 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2 mb-2'>PEMERINTAH MENJAMIN KUALITAS DAN PROSEDUR PERIZINAN YANG MUDAH:</h4>
				<ul className='list-disc pl-4 bg-white'>
					<li className='list-item'>Pelayanan Terpadu Satu Pintu untuk memperoleh persetujuan izin dan aturan</li>
					<li className='list-item'>Izin investasi dalam waktu 3 jam</li>
					<li className='list-item'>Bebas biaya dalam semua proses pengurusan izin</li>
				</ul>
			</div>
			<h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2 mb-2'>{t('procedure')}</h3>
			<div className='grid grid-cols-1 md:grid-cols-5 gap-2 gap-y-5 text-center text-sm bg-white'>
				<div className='flex flex-col items-center'>
					<div>
						<img src='/assets/images/investment/how-to-invest/Asset prosedur1.png' alt={t('step-invest-1')} />
					</div>
					<div>{t('step-invest-1')}</div>
				</div>
				<div className='flex flex-col items-center'>
					<div className='h-12 flex items-center'>
						<div className='border-l-4 h-12 md:border-l-0 md:border-b-4 border-gray-300 md:w-12'></div>
					</div>
				</div>
				<div className='flex flex-col items-center text-center'>
					<div>
						<img src='/assets/images/investment/how-to-invest/Asset prosedur2.png' alt={t('step-invest-2')} />
					</div>
					<div>{t('step-invest-2')}</div>
				</div>
				<div className='flex flex-col items-center'>
					<div className='h-12 flex items-center'>
						<div className='border-l-4 h-12 md:border-l-0 md:border-b-4 border-gray-300 md:w-12'></div>
					</div>
				</div>
				<div className='flex flex-col items-center text-center'>
					<div>
						<img src='/assets/images/investment/how-to-invest/Asset prosedur6.png' alt={t('step-invest-3')} className='w-full aspect-auto' />
					</div>
					<div>{t('step-invest-3')}</div>
				</div>

				{/*  */}
				<div className='md:flex flex-col items-center col-span-4 hidden'></div>
				<div className='flex flex-col items-center'>
					<div className='h-12 flex items-center justify-center'>
						<div className='border-l-4 h-12 border-gray-300'></div>
					</div>
				</div>
				{/*  */}
				<div className='flex flex-col items-center text-center order-12 md:order-none'>
					<div>
						<img src='/assets/images/investment/how-to-invest/Asset prosedur3.png' alt={t('step-invest-4')} />
					</div>
					<div>{t('step-invest-4')}</div>
				</div>
				<div className='flex flex-col items-center order-11 md:order-none'>
					<div className='h-12 flex items-center'>
						<div className='border-l-4 h-12 md:border-l-0 md:border-b-4 border-gray-300 md:w-12'></div>
					</div>
				</div>
				<div className='flex flex-col items-center text-center order-10 md:order-none'>
					<div>
						<img src='/assets/images/investment/how-to-invest/Asset prosedur4.png' alt={t('step-invest-5')} />
					</div>
					<div>{t('step-invest-5')}</div>
				</div>
				<div className='flex flex-col items-center order-9 md:order-none'>
					<div className='h-12 flex items-center'>
						<div className='border-l-4 h-12 md:border-l-0 md:border-b-4 border-gray-300 md:w-12'></div>
					</div>
				</div>
				<div className='flex flex-col items-center text-center'>
					<div>
						<img src='/assets/images/investment/how-to-invest/Asset prosedur2.png' alt={t('step-invest-6')} />
					</div>
					<div>{t('step-invest-6')}</div>
				</div>
			</div>
		</div>
	)
}

export default ProcedureSection
