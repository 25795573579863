import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'font-awesome/css/font-awesome.min.css'
import App from './App'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
)
