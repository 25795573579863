import React from 'react'
import Breadcrumb from './Breadcrumb'

function PageDetail({ children, bannerImg, contentRight, breadcrumbItems }) {
	return (
		<div>
			<div className='w-full'>
				<img src={`${process.env.REACT_APP_IMAGE_URL}${bannerImg}`} alt='Banner' className='w-full h-[25vh] object-cover' />
			</div>
			<div className='p-2 md:p-0 mb-16 md:mb-0 md:py-5 md:px-20'>
				<div className='py-5'>
					<Breadcrumb items={breadcrumbItems} />
				</div>
				<div className='grid md:grid-cols-10 gap-5'>
					<div className={contentRight ? 'md:col-span-7' : 'md:col-span-10'}>{children}</div>
					{contentRight && <div className='md:col-span-3'>{contentRight}</div>}
				</div>
			</div>
		</div>
	)
}

export default PageDetail
