import baseService from './baseService'
import i18n from '../i18n'

export const fetchIndustrialArea = async (page, limit, searchTerm) => {
	try {
		const response = await baseService.get('post', {
			params: { lang: i18n.language, page: page, limit: limit, status: 'publish', 'term-slug': 'kawasan-industri', meta: true, search: searchTerm }
		})
		const data = response.data
		return data
	} catch (error) {
		console.log(error)
	}
}

export const fetchInfrastructure = async (page, limit, searchTerm) => {
	try {
		const response = await baseService.get('post', {
			params: { lang: i18n.language, page: page, limit: limit, status: 'publish', 'term-slug': 'infrastruktur', meta: true, search: searchTerm }
		})
		const data = response.data
		return data
	} catch (error) {
		console.log(error)
	}
}

export const fetchSuccessStory = async (page, limit, searchTerm) => {
	try {
		const response = await baseService.get('post', {
			params: { lang: i18n.language, page: page, limit: limit, status: 'publish', 'term-slug': 'investment-success-story', meta: true, search: searchTerm }
		})
		const data = response.data
		return data
	} catch (error) {
		console.log(error)
	}
}

export const fetchInvestmentPotential = async (page, limit, slug = 'potensi-investasi', searchTerm) => {
	try {
		const response = await baseService.get('post', {
			params: { lang: i18n.language, page: page, limit: limit, status: 'publish', 'term-slug': slug, meta: true, search: searchTerm }
		})
		const data = response.data
		return data
	} catch (error) {
		console.log(error)
	}
}

export const fetchDetailPostByPostName = async (postName) => {
	try {
		const response = await baseService.get(`post/${postName}`, {
			params: { lang: i18n.language, meta: true }
		})
		const data = response.data
		return data
	} catch (error) {
		console.log(error)
	}
}
