import { makeAutoObservable } from 'mobx'
import { fetchDetailPostByPostName } from '../services/investmentService'

class LinkStore {
	isLoading = false
	data = undefined

	constructor() {
		makeAutoObservable(this)
	}

	fetchData = async (postName) => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchDetailPostByPostName(postName)
			this.data = response.data
		} catch (error) {
			console.error(error)
		} finally {
			this.isLoading = false
		}
	}
}

const linkStore = new LinkStore()
export default linkStore
