export const handleImageError = (e) => {
	e.target.src = '../assets/images/no-data-image.jpg'
}

export const formatDate = (dateString) => {
	const date = new Date(dateString)

	const day = String(date.getDate()).padStart(2, '0')
	const month = String(date.getMonth() + 1).padStart(2, '0')
	const year = date.getFullYear()

	const formattedDate = `${day}/${month}/${year}`
	return formattedDate
}
