import React, { useEffect } from 'react'
import Breadcrumb from '../../components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { linkStore } from '../../stores/_rootStore'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import HeadingUnderline from '../../components/HeadingUnderline'
import i18n from '../../i18n'

function LinkPage() {
	const { postName } = useParams()
	const { t } = useTranslation()
	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: 'Link' }]
	const items = [1, 2, 3, 4]
	useEffect(() => {
		const fetchData = async () => {
			try {
				await linkStore.fetchData(postName)
			} catch (error) {
				console.error(error)
			}
		}

		fetchData()
		// eslint-disable-next-line
	}, [i18n.language])

	return (
		<div className='flex flex-col justify-center mb-12'>
			<div className='max-w-6xl w-full mt-8 md:mt-18 p-2 md:p-0 md:mx-auto'>
				<Breadcrumb items={breadcrumbItems} />
				{linkStore.isLoading && <p>Loading...</p>}
				{linkStore.data && (
					<>
						<h1 className='text-3xl font-bold mb-2 mt-8'>{linkStore.data.post_title}</h1>
						<HeadingUnderline />
						<div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-5'>
							{items.map((i) => {
								const linkMeta = linkStore.data.post_meta.filter((item) => item.meta_key === `link_${i}`)[0]
								const linkValue = linkMeta ? linkMeta.meta_value : null
								const titleMeta = linkStore.data.post_meta.filter((item) => item.meta_key === `title_${i}`)[0]
								const titleValue = titleMeta ? titleMeta.meta_value : null

								return (
									<div key={i}>
										{linkValue && (
											<a href={linkValue} target='_blank' rel='noopener noreferrer' className='font-bold bg-white border border-black hover:bg-gold hover:text-white block p-5 rounded-md text-center'>
												{titleValue}
											</a>
										)}
									</div>
								)
							})}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default observer(LinkPage)
