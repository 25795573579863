import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import General from "../../components/Faq/general";
import Trade from "../../components/Faq/trade";
import Investment from "../../components/Faq/investment";
import Tourism from "../../components/Faq/tourism";

const Faq = () => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState("general");

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	const getButtonClassName = (tab) =>
		`block w-full text-left my-2 ${
			activeTab === tab
				? "font-bold border-l-[10px] border-gold bg-[#e0aa3e33] py-3 px-[40px] uppercase"
				: "font-bold py-3 px-[50px] uppercase"
		}`;

	return (
		<div className="relative">
			<div className="flex justify-center relative">
				<div className="max-w-6xl w-full mt-2 font-sans">
					<div className="flex">
						<div className="w-1/4 bg-[#eaeaea] min-h-[783px] max-h-screen pt-[60px] text-xl">
							<button
								className={getButtonClassName("general")}
								onClick={() => handleTabChange("general")}
							>
								{t("general")}
							</button>
							<button
								className={getButtonClassName("trade")}
								onClick={() => handleTabChange("trade")}
							>
								{t("trade")}
							</button>
							<button
								className={getButtonClassName("investment")}
								onClick={() => handleTabChange("investment")}
							>
								{t("investment")}
							</button>
							<button
								className={getButtonClassName("tourist")}
								onClick={() => handleTabChange("tourist")}
							>
								{t("tourism")}
							</button>
						</div>
						<div className="w-3/4 p-4 space-y-8">
							{activeTab === "general" && <General />}
							{activeTab === "trade" && <Trade />}
							{activeTab === "investment" && <Investment />}
							{activeTab === "tourist" && <Tourism />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Faq;
