import React from 'react'

const Footer = () => {
	return (
		<>
			<div className='md:h-[100px]'></div>
			<div className='bg-primary bottom-0 bg-primary md:h-[100px] z-50 fixed flex items-center justify-center w-full'>
				<div className='w-full'>
					<div className='md:flex justify-between px-16 items-center h-full mb-3 hidden'>
						<a href='https://jatengprov.go.id'>
							<img src='/assets/images/Asset pemprov jateng_1.webp' alt='Pemprov Jateng' className='h-11' />
						</a>
						<a href='http://dpmptsp.jatengprov.go.id/perizinan'>
							<img src='/assets/images/Asset DPMPTSP.webp' alt='DPMPTSP Jateng' className='h-11' />
						</a>
						<a href='https://disperindag.jatengprov.go.id/v3/' target='_blank' rel='noopener noreferrer'>
							<img src='/assets/images/disperindag-logo.webp' alt='Disperindag' className='h-11' />
						</a>
						<a href='https://disporapar.jatengprov.go.id/' target='_blank' rel='noopener noreferrer'>
							<img src='/assets/images/disporapar-logo.webp' alt='Disporapar' className='h-11' />
						</a>
						<a href='http://www.bi.go.id'>
							<img src='/assets/images/Asset BI.webp' alt='Bank Indonesia' className='h-11' />
						</a>
						<a href='http://www.bkpm.go.id/'>
							<img src='/assets/images/Asset BKPM.webp' alt='BKPM' className='h-11' />
						</a>
						<a href='http://www.bi.go.id/en/iru/default.aspx'>
							<img src='/assets/images/Asset IRU.webp' alt='IRU' className='h-11' />
						</a>
						<a href='https://jasirah.id/' target='_blank' rel='noopener noreferrer'>
							<img src='/assets/images/Asset Jasirah.webp' alt='Jasirah' className='h-11' />
						</a>
					</div>
					<div className='bg-primary text-white font-bold bottom-0 w-full z-50 text-center px-4'>Copyright © 2024 Keris Jateng. All Rights Reserved</div>
				</div>
			</div>
		</>
	)
}

export default Footer
