import baseService from './baseService'
import i18n from '../i18n'

export const fetchAllRegions = async (page, limit) => {
	try {
		const response = await baseService.get('post', {
			params: { lang: i18n.language, page: page, limit: limit, status: 'publish', 'term-slug': 'regional', meta: true }
		})
		const data = response.data
		return data
	} catch (error) {
		console.log(error)
	}
}
