import { makeAutoObservable } from 'mobx'
import { fetchDetailPostByPostName } from '../services/investmentService'
import { fetchAllRegions } from '../services/regionService'

class RegionStore {
	isLoading = false
	data = undefined
	otherData = []

	constructor() {
		makeAutoObservable(this)
	}

	fetchData = async (postName) => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchDetailPostByPostName(postName)
			this.data = response.data
		} catch (error) {
			console.error(error)
		} finally {
			this.isLoading = false
		}
	}

	fetchOtherData = async () => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchAllRegions(1, 5)
			this.otherData = response.data.data
		} catch (error) {
			console.error(error)
		} finally {
			this.isLoading = false
		}
	}
}

const regionStore = new RegionStore()
export default regionStore
