import React from 'react'
import { useTranslation } from 'react-i18next'

export default function InvestmentSelect({ currentPage, setCurrentPage }) {
	const { t } = useTranslation()
	const changePageInvest = (page) => {
		setCurrentPage(page)
	}

	const list = [
		{
			pageName: 'procedure-invest',
			imgSrc: 'assets/images/investment/head/Asset_how to invest.png'
		},
		{
			pageName: 'facility-infrastructure',
			imgSrc: '/assets/images/investment/head/Asset_infrastructure.png'
		},
		{
			pageName: 'portfolio',
			imgSrc: '/assets/images/investment/head/Asset_investment portfolio.png'
		},
		{
			pageName: 'success-story',
			imgSrc: '/assets/images/investment/head/Asset_succes story.png'
		}
	]

	const renderList = (pageName, imgSrc) => {
		return (
			<li
				key={pageName}
				className={`flex flex-col items-center justify-center cursor-pointer w-full h-full rounded-lg ${currentPage === pageName ? 'bg-primary' : 'bg-primary-light'}`}
				onClick={() => changePageInvest(pageName)}
			>
				<img src={imgSrc} className='w-[30px] py-5 md:py-0 md:w-[40px]' style={{ height: 'auto' }} alt={pageName} />
				<div className='mt-4 hidden md:block'>
					<h4>{t(pageName)}</h4>
				</div>
			</li>
		)
	}

	return (
		<div className='h-[400px] relative'>
			<img src='/assets/images/image_slide/image_blank/banner2.png' alt='Background' className='brightness-75 object-cover w-full h-full overflow-hidden' />

			<div className='absolute z-40 -bottom-8 xl:-bottom-[80px]'>
				<div className='flex w-screen justify-center'>
					<div className='bg-primary-light flex items-center justify-center md:rounded-xl p-1 w-full xl:mx-16 xl:h-[160px]'>
						<ul className='flex justify-evenly w-full h-full text-white'>{list.map((item) => renderList(item.pageName, item.imgSrc))}</ul>
					</div>
				</div>
			</div>
		</div>
	)
}
