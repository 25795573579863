import React from 'react'
import { useTranslation } from 'react-i18next'
import HeadingUnderline from '../../../components/HeadingUnderline'
import Sidebar from '../../../components/Sidebar'
import FacilityInfrastructureSection from '../../../components/Investment/FacilityInfrastructure/FacilityInfrastuctureSection'

function FacilityInfrastructure() {
	const { t } = useTranslation()
	const tabs = [
		{
			key: 'industrial-area-invest',
			content: <FacilityInfrastructureSection category='industrial-area-invest' />
		},
		{
			key: 'infrastructure',
			content: <FacilityInfrastructureSection category='infrastructure' />
		}
	]

	return (
		<>
			<h1 className='text-3xl font-bold mb-2 mt-8'>{t('facility-infrastructure')}</h1>
			<HeadingUnderline />
			<div className='md:flex mt-7'>
				<Sidebar tabData={tabs} />
			</div>
		</>
	)
}

export default FacilityInfrastructure
