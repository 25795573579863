import { makeAutoObservable } from 'mobx'
import { ROW_PER_PAGE } from '../constants/constant'
import { fetchInvestmentPotential } from '../services/investmentService'

class InvestmentPotentialStore {
	isLoading = false
	data = []
	currentPage = 1
	hasNext = false
	searchTerm = ''
	slug = 'potensi-investasi'

	constructor() {
		makeAutoObservable(this)
	}

	fetchData = async () => {
		if (this.isLoading) return

		this.isLoading = true
		try {
			const response = await fetchInvestmentPotential(this.currentPage, ROW_PER_PAGE, this.slug, this.searchTerm)
			if (this.currentPage === 1) {
				this.data = response.data.data
			} else {
				this.data = this.data.concat(response.data.data)
			}
			this.hasNext = response.data.hasNextPage
		} catch (error) {
			console.error(error)
		} finally {
			this.isLoading = false
		}
	}

	nextPage = async () => {
		this.currentPage += 1
		await this.fetchData()
	}

	search = async (searchTerm) => {
		this.searchTerm = searchTerm
		this.currentPage = 1
		await this.fetchData()
	}

	resetState = () => {
		this.isLoading = false
		this.data = []
		this.currentPage = 1
		this.hasNext = false
		this.searchTerm = ''
		this.slug = 'potensi-investasi'
	}
}

const investmentPotentialStore = new InvestmentPotentialStore()
export default investmentPotentialStore
