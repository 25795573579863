import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function DiscoverMaps() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	useEffect(() => {
		const regions = document.querySelectorAll('svg g')

		const handleClick = (region) => {
			const postName = region.getAttribute('post-name')
			if (postName) {
				navigate('/region/' + postName)
			}
		}

		const clickHandler = (event) => {
			const region = event.currentTarget
			handleClick(region)
		}

		regions.forEach((region) => {
			region.addEventListener('click', clickHandler)
		})

		return () => {
			regions.forEach((region) => {
				region.removeEventListener('click', clickHandler)
			})
		}
	}, [navigate])

	return (
		<>
			<div className='bg-[#F9EED8] flex flex-col pt-16 pb-4 items-center justify-center'>
				<h2 className='font-semibold px-8 text-center'>{t('title-regional')}</h2>
				<div className='max-w-6xl w-full mt-4'>
					<svg
						version='1.1'
						xmlns='http://www.w3.org/2000/svg'
						xmlnsXlink='http://www.w3.org/1999/xlink'
						x='0px'
						y='0px'
						viewBox='0 30 1228 751'
						style={{ enableBackground: 'new 0 30 1228 751' }}
						xmlSpace='preserve'
						className='cursor-crosshair'
					>
						<g id='Background'>
							<rect id='XMLID_2_' x='0.5' y='0.5' className='st0' width='1227' height='800' />
						</g>
						<g id='Kabupaten_Brebes' className='region' from='home' post-name='kabupaten-brebes'>
							<path
								id='XMLID_17_'
								className='st3'
								d='M238.2,225c-0.5-2.4-4.6-1.7-10.7-6.3c-0.5-0.4-2.9-2.3-4.5-5.5c-0.7-1.5-1.3-3.2-0.8-3.5
						c0.4-0.3,1.1,0.6,1.7,0.3c0.7-0.4-0.3-2.2,0-4.8c0.3-2.6,1.4-3.5,0.8-4.3c-0.5-0.7-1.8-0.5-4.2-0.3c-2.3,0.1-4.8,0.1-6.5-0.2
						c-4.8-0.6-5.6-2.6-8.3-1.8c-1.7,0.5-1.7,1.4-3.7,1.8c-2.3,0.5-3.1-0.6-5-0.3c-2,0.3-2.2,1.6-6.2,5c-2.9,2.5-4.4,3.7-6.3,4.2
						c-3.2,0.7-5.4-0.8-6.2,0.3c-0.7,1,1,2.3,0.5,4.7c-0.4,1.8-1.7,2.1-1.5,3.3c0.2,1.6,2.6,2,2.5,2.8c-0.1,1.2-4,2.8-7.5,1.8
						c-1.7-0.5-1.9-1.2-5.2-3c0,0-1.8-1-4.5-2.2c-6.7-2.9-9.2-1.8-13-4c-2.5-1.4-3.6-3.2-6-6.8c-3.3-5-5.3-10.7-7-15.7
						c-1.5-4.2-2-6.5-4-7.2c-1.8-0.6-4.3,0.4-5.2,2c-0.7,1.2-0.3,2.4,0,4.2c0.3,2.4,0.1,4.3-0.3,8.2c-0.4,3.5-0.8,3.7-1.7,8.3
						c-0.7,4-0.5,4.4-1,7.8c-0.7,4.3-1.5,9.7-4.7,15.2c-0.5,0.9-2.3,3.9-5.5,7c-5.2,5.1-7.6,4.1-11,8.5c-2.7,3.5-3.9,7.7-4.3,9.3
						c-0.8,3-0.5,3.7-1.7,7.2c-0.9,2.6-1.6,3.8-1,4.5c1.1,1.4,5.5-1.4,6.7,0c0.8,0.9-0.9,2.6-1.5,6c-0.1,0.4-1.3,7,2.7,11.2
						c2.2,2.3,5.2,3.1,5.7,3.2c3.2,0.7,5.2-0.3,6,0.8c0.4,0.6-0.1,2.1-1.2,4.8c-0.6,1.6-1.1,2.8-2,4.5c-1.8,3.3-2.5,3.3-2.8,4.8
						c-0.5,2.5,1.4,3,1.3,5.8c0,3.1-2.2,3.1-3.2,7.3c-0.3,1.6-0.4,3.2-0.5,6.3c-0.1,2.8,0,3.3-0.3,3.8c-1.1,1.7-5.5,0.8-11-0.2
						c-0.5-0.1-1.7-0.3-2.7,0.3c-0.9,0.5-1.1,1.4-1.5,2.2c-1.4,2.8-3.6,2.1-6.2,5.3c-1.4,1.7-1.9,3.2-2,3.7c-0.5,1.7-0.4,2.8-0.3,4.5
						c0.1,6-1.5,7.3-0.3,9.5c0.7,1.3,1.5,1.3,4.3,3c6.7,4.1,6.1,6.8,9.7,7.5c4.2,0.8,7.2-2.6,9.5-0.7c0.8,0.7,0.5,1.2,1.8,2.5
						c1.9,1.9,4.1,2.4,4.5,2.5c0.9,0.2,2.2,0.5,3.7,0c1.8-0.6,1.9-1.7,3.3-2c1.8-0.4,3.6,1,4.7,1.8c0.6,0.5,3.1,2.6,3.7,6
						c0.2,1.5-0.1,2.1,0.5,2.8c0.9,1.1,2.3,0.3,5.3,0.5c4,0.2,4.2,1.7,7.6,1.5c2.5-0.1,3.7-0.9,4.3-0.2c0.7,0.8-0.4,2.1-0.2,4.5
						c0.2,1.6,0.8,2.7,1.5,3.8c1.2,2,2.8,3.6,7,6.5c4.7,3.2,4.4,2.3,6.3,4c1.5,1.3,4.8,4.1,6.7,8.8c1,2.5,1.3,5,3,5.5
						c0.7,0.2,0.8-0.1,2.2-0.2c0,0,1.4-0.1,6.3,1.5c0.5,3.4,1.3,4.3,2,4.5c1.1,0.2,1.8-1.3,3.8-2.2c1.5-0.6,2.8-0.5,5.8,0
						c6.9,1,10.3,1.5,11.5,1c2.7-1.2,3.2-3.6,4.8-3.3c1.6,0.3,1.4,2.8,3.8,4.5c1.7,1.2,4.7,2,6.7,0.5c1.5-1.1,0.1-3.8,1.7-5
						c0.6-0.5,1.4-0.5,1.7-0.5c5.4-0.4,17.5-17.2,20.7-22.7c0.3-0.5,1-1.8,2.5-2.3c0.6-0.2,1.2-0.2,1.8-0.2c2.2,0.1,3.9,0.6,4.7,0.8
						c3.1,0.7,3.3-0.8,7.3-0.5c3,0.2,4.3,1.2,5.5,0.3c1-0.7,0.5-1.6,1.5-2.8c1.7-2,4.2-1.5,4.5-2.7c0.2-0.7-0.7-1.1-2.7-3.5
						c-1.7-2.1-1.6-2.6-2.7-3.3c-0.1-0.1-2.7-1.8-4.3-0.8c-1.7,1-0.8,3.8-2.3,4.5c-1.1,0.5-2.4-0.8-4.8-2c-3.4-1.7-5.3-0.4-6.7-2
						c-0.9-1.1-0.3-1.8-1.2-3.3c-1.1-2-3-2.4-6-3.7c-4.3-1.9-3.3-2.3-8.2-4.7c-4.7-2.2-7.1-2.5-7.5-4.3c-0.1-0.6,0-1.2,1-2.8
						c3.5-5.8,7.2-6.5,7.3-9.5c0.1-1.7-0.1-1.4-0.7-4.6c-0.6-3.2-0.7-4.6-1.7-5.5c-1.1-1.1-2.5,0.2-5-0.6c-2.7-0.9-2.8-3-4.6-3
						c-2-0.1-4.3,2.3-4.6,4.6c-0.3,2.1,1.1,3.3,0.4,5c-0.4,1.1-1.4,2.3-2.5,2.3c-0.9,0-1.6-0.9-3-2.8c-1.4-1.9-1.5-2.5-2.4-3
						c-1.3-0.8-2.8-0.4-3.8-0.3c-6.9,1.3-15-0.9-15-0.9c0.1,0.2,0.6,2-0.3,2.8c-0.3,0.3-0.9,0.5-3.1-0.1c-2.9-0.8-4.7-1.2-4.8-2
						c0-1.5,7.1-2.1,7.5-5c0.1-0.7-0.3-1.3,0.1-2.4c0.4-1.3,1.2-1.2,1.4-2.1c0.3-1.5-1.8-2.4-1.5-3.9c0.1-0.7,0.6-0.7,1.6-1.9
						c0.7-0.9,1.6-1.9,1.6-3c0.2-2.2-2.9-3-2.8-5.1c0.1-1.2,1.2-2.1,1.9-2.6c0.5-0.4,0.9-0.6,3.8-1.5c2.5-0.8,2.7-0.8,3.9-1.3
						c2-0.8,1.7-0.9,2.6-1.1c1.3-0.3,1.9-0.1,4.6,0c1.3,0,2,0.1,2.1,0c1.6-0.9,0.2-5,1.1-11.1c1.3-8.3,0.5-10.4,1-10.6
						c0.5-0.3,0.7-0.1,1.9-0.4c2.5-0.5,3.6-1.5,6.3-3.1c4.9-3,6.3-2.6,7.3-4.5c0.7-1.4-0.1-1.7,0.8-3.6c1.1-2.5,2.7-3,2.8-4.8
						c0-1-0.5-1.6-1.4-2.8c-0.5-0.7-4-5.6-4.4-10.4c-0.2-1.8,0.3-1,0.5-4.5c0.2-4.8,0.4-9,0-12.3c0-0.3-0.1-1,0.3-1.6
						c0.7-1,2.2-1,3.5-1.1c2-0.3,3.3-1.1,5.9-2.8C233.6,231,238.8,227.7,238.2,225z'
							/>
							<text id='XMLID_165_' transform='matrix(1 0 0 1 136.7112 283.7838)' className='st4 st5 text-map'>
								{t('kab-brebes')}
							</text>
						</g>
						<g id='Kabupaten_Cilacap' className='region' from='home' post-name='kabupaten-cilacap'>
							<path
								id='XMLID_136_'
								className='st6'
								d='M347,555.5c-1.2-3.7-0.9-4.3-1.7-6.5c-1.5-4.3-3.7-4.2-3.7-6.7c0-2.9,3-4.8,2.5-5.5									c-0.3-0.3-1.6,1.1-3.7,1c-0.9,0-1.6-0.4-1.8-0.5c-12.1-5.7-25.6-8-38.3-12.3c-0.7-0.7-1.6-1.8-2.7-3.2c-1.8-2.4-2.5-3.9-3.5-5.3
						c-1.2-1.8-2.1-2.4-6-5.7c-8.7-7.4-10.5-9.7-13.8-9.5c-1.3,0.1-1.5,0.5-3.2,0.5c-3.8,0-6.1-2.3-7.2-1.3c-0.3,0.3-0.3,0.7-0.3,1.7
						c0,0.5-0.3,5.4-2.5,7.3c-0.7,0.7-1.4,0.7-4.3,1.2c-9.2,1.3-8.8,1.5-9.5,1.3c-2.9-0.6-4.1-2.2-9-3.8c-0.4-0.1-1.1-0.3-1.9-0.5
						c-2.2-0.3-3.5,0.3-5.3,0.6c-0.7,0.1-4.3,0.8-7.8-0.6c-1.8-0.7-3-1.9-4-2.8c-1.9-1.7-2-2.5-3.4-3.6c-1.3-1.1-2.6-1.6-5.6-2.4
						c-8.8-2.3-10.7-1.6-12.1-3.5c-1.5-2-0.2-3.8-2.1-5.8c-1-1-1.8-0.8-4.1-2c-2.2-1.1-2-1.5-5.4-3.6c-4.2-2.8-5.8-2.9-6.4-4.8
						c-0.3-1-0.2-2,0-3.5c0.4-2.9,1.5-3.3,1.1-4.9c-0.4-1.8-2.2-3-3.6-3.5c-0.9-0.3-1.6-0.3-1.9-0.3c-6.2,0.2-13.5,3.1-17.8,0.6
						c-2.8-1.6-4.3-5.6-3.4-8.1c0.2-0.6,0.7-1.4,3.3-3.3c4.3-3,5.3-2.3,8.3-4.4c4.5-3.2,6.4-8,6.8-9c0.3-0.9,0.6-1.7,0.8-2.3
						c1-3.3,1-4.9,1.5-7.4c0.3-1.5,0.9-4.2,2.5-7.1c1.5-2.7,2.5-3.3,3.5-6c0,0,0.8-2.2,0.9-4.4c0-0.6-0.1-1.4-0.4-2.3
						c-0.3,0.2-0.6,0.3-0.9,0.2c-0.7-0.2-1.5-1.1-2-4.5c-4.9-1.6-6.3-1.5-6.3-1.5c-1.4,0.1-1.5,0.4-2.2,0.2c-1.7-0.5-2-3-3-5.5
						c-1.8-4.7-5.2-7.6-6.7-8.8c-1.9-1.7-1.6-0.8-6.3-4c-4.2-2.9-5.8-4.5-7-6.5c-0.7-1.2-1.3-2.3-1.5-3.8c-0.2-2.4,0.9-3.7,0.2-4.5
						c-0.6-0.7-1.9,0.1-4.3,0.2c-3.4,0.1-3.7-1.3-7.6-1.5c-2.9-0.2-4.4,0.6-5.3-0.5c-0.6-0.7-0.2-1.3-0.5-2.8c-0.5-3.4-3.1-5.5-3.7-6
						c-1-0.8-2.8-2.3-4.7-1.8c-1.4,0.3-1.5,1.4-3.3,2c-1.5,0.5-2.8,0.2-3.7,0c-0.4-0.1-2.6-0.6-4.5-2.5c-1.3-1.3-1-1.8-1.8-2.5
						c-2.3-1.9-5.3,1.4-9.5,0.7c-3.6-0.7-3-3.4-9.7-7.5c-2.8-1.7-3.6-1.7-4.3-3c-1.2-2.2,0.4-3.5,0.3-9.5c0-1.5-0.1-2.6,0.2-4
						c-0.6,0-1.1,0-1.2,0c-0.1,0-0.1,0.2-1.3,1.5c-0.8,0.9-1.3,1.4-1.6,1.7c-0.5,0.4-1.9,1.6-3.9,1.8c-0.5,0-0.9,0-1.8-0.2
						c-1.8-0.3-2.8-0.5-3.3-1.2c-0.5-0.7,0-1.2-0.1-3.1c-0.1-1.2-0.5-3.4-1.5-3.7c-0.7-0.2-0.9,0.8-2,0.9c-1.5,0.1-2.3-1.5-3.1-1.2
						c-0.9,0.3-0.2,2.2-1.3,3.3c-1.3,1.2-3.5-0.3-7-0.1c-1.2,0.1-2.7,0.7-5.7,1.9c-2.1,0.9-4.3,1.9-9.2,4.4c-4,2.1-6,3.1-6.7,3.7
						c-1.4,1.2-4.8,4-6,8.8c-0.3,1.4-1,4.2,0.3,7c0.8,1.8,2.3,3.3,2.6,3.5c1.7,1.7,3.2,2.2,3.6,3.9c0.1,0.4,0,0.5,0,1.6
						c-0.1,2.6,0,3.9,0,4.8c0,1.8-0.6,3.1-2.5,6.8c-2.1,4.3-3.8,5.3-4.4,8.8c-0.1,0.9-0.2,1.6-0.2,2.2c-0.1,9.5,0.3,22.7,0.3,22.7
						c0.4,0.3,1,0.9,1.8,1.7c3.5,3.2,4.3,4.3,6.2,5.3c0.8,0.4,1.7,0.7,3.5,1.2c3.3,0.9,6.6,1.8,10.7,1.2c1.7-0.3,2.8-0.4,4.2-1.2
						c2.4-1.3,3.1-3.1,5-3.2c0.6,0,1.3,0.3,2.5,0.8c1.2,0.5,2.9,1.3,4.7,2.8c2.6,2.2,3.8,4.6,4.5,6.2c2.1,4.3,1,5.4,2.7,9
						c0.8,1.6,2.6,3.5,6.2,7.3c5,5.3,6.8,6.4,7.8,9.7c0.8,2.4,0.7,4.5,0.7,5c-0.2,4.7-2.7,6.3-1.8,9.3c0.3,1.1,0.6,0.8,2.8,4
						c1.8,2.6,2.2,3.6,3.8,6.2c3.9,6.2,4.4,4.9,5.3,7.5c1.7,4.6-0.5,7,2,11.8c0.6,1.3,1.1,1.6,1.2,2.7c0.2,2.3-1.6,3.5-3.5,7.7
						c-0.4,0.9-0.1,0.3-1.5,4c-1.7,4.4-2.3,5.5-2.2,7.3c0,0.6,0.2,3.1,1.5,3.7c1.2,0.5,2.5-0.8,3.2-0.2c0.5,0.4,0,1.1,0,2.5
						c-0.1,2.8,1.5,3.2,1.3,5.7c-0.2,1.9-1.2,2.3-0.8,3.7c0.2,0.9,0.9,1.4,2.3,2.3c2.6,1.7,3.9,2.6,5.8,3.3c3.1,1.3,4.7,1.9,6.8,1.7
						c2.4-0.3,2.5-1.3,5.3-1.7c1.2-0.1,1.5,0,5.5,0.5c4,0.5,3.9,0.3,6.5,0.7c1.9,0.3,3.8,0.5,6.2,1.2c4,1.1,5,2,8,2.2
						c2,0.1,1.8-0.2,3.7-0.2c3.9,0.2,5,1.9,10.8,4c4.2,1.5,4.1,0.8,6.8,2.2c3.2,1.5,4,2.8,6.7,3.2c1.8,0.2,2.4-0.2,4.3,0.3
						c2.2,0.6,2.3,1.5,4,2c2.9,0.8,4.2-1.3,6.8-0.7c1.9,0.4,1.7,1.7,5,4.5c2.8,2.4,2.5,1.1,8.7,5c1.2,0.7,2.7,1.7,3.8,1.2
						c0.7-0.3,0.9-1.1,1-1.3c1.5-4.1,8.6-10.4,12.7-13.2c1.5-1.1,3-1.9,4.8-2.3c1.7-0.4,2.9-0.2,4.7-0.2c6.6,0.3,7-1.2,11.5-0.5
						c3.1,0.5,2.9,1.2,6,1.5c1.7,0.2,3.4,0,6.8-0.3c4.1-0.4,4.3-0.6,6.2-0.7c3.1-0.1,4.1,0.4,7.7,0.8c4.8,0.6,4.2-0.2,11.3,0.2
						c5.9,0.3,6.9,0.9,9.8,0.2c1.8-0.5,2.6-1,4.3-0.8c2.8,0.2,3.1,1.9,6,2.5c3.1,0.7,3.5-1.1,6.5-0.3c2.7,0.7,3,2.3,5.8,2.7
						c1.8,0.2,2.2-0.3,4.2-0.3c2.7,0,4.2,1.1,6.7,2c3.1,1.2,3.3,0.5,8.8,1.7c8.1,1.7,9.6,3.6,12.3,2.3c2-0.9,3.8-3.1,3.8-5.3
						C347.5,556.8,347.4,556.5,347,555.5z'
							/>

							<path
								id='XMLID_135_'
								className='st6'
								d='M119.5,544.5c-0.6,0.2-0.5,0.7-2.4,4c-0.9,1.6-1.4,2.2-1.9,3.5c-0.2,0.6-0.6,1.5-0.8,2.6
						c-0.3,1.9,0.1,3.5,0.4,4.6c2,7.8,1.5,9.1,3,10c1.1,0.7,1.8,0.3,3.6,1.5c1.3,0.9,1.4,1.3,2.4,1.8c1.5,0.7,2.9,0.2,4.8-0.1
						c0.1,0,2.2-0.1,6.4-0.3c6-0.2,9-0.3,12.5,0.3c4.4,0.7,3.3,1.5,7.5,2.3c7.2,1.2,13.9-0.5,15.8,2.3c0.2,0.3,0.6,1.1,1.5,1.4
						c1.1,0.4,2.1-0.3,3-0.6c2-0.8,4,0,8,1.4c1.8,0.6,3.1,1.3,5.8,2.8c1.2,0.7,4.9,2.6,4.8,3c-0.1,0.2-1,0-1-0.1c0-0.1,1.1-0.1,4.4-0.3
						c4.4-0.2,5.1-0.3,6.5,0c1.4,0.3,2.5,0.9,3.3,1.3c1.8,0.9,3.4,2.2,3.4,2.3c-0.1,0.1-0.9-0.3-0.9-0.4c0-0.1,2.9,1.6,5.5,0.9
						c0.3-0.1,2.2-0.6,3-2.1c0.2-0.5,0.2-0.8,0-3.8c-0.2-1.9-0.2-2.6-0.4-4.1c-0.2-2.2-0.3-3.3-0.6-3.9c-0.2-0.5-0.8-1.6-2.1-2.4
						c-1.4-0.8-2.8-0.6-3.9-0.5c-1.8,0.2-1.7,0.7-2.8,0.6c-2-0.1-2.2-2.1-4.9-3c-0.7-0.2-0.5,0-2.9-0.5c-0.5-0.1-2.1-0.4-3.3-0.8
						c-2.2-0.6-2.8-1.3-4.9-2.3c-2.9-1.3-3.1-0.6-6.3-1.9c-3.8-1.5-3.3-2.4-6-3c-3.1-0.7-6.3-0.2-6.9-0.1c-1.2,0.2-1.7,0.4-3.1,0.5
						c-1.3,0.1-2.9,0.2-4.5-0.5c-1.6-0.7-1.6-1.4-2.9-2.3c-2.3-1.5-4.1-0.3-7.3-0.6c-2.2-0.3-2.7-1-7.6-3.9c-5-2.9-7.6-4.3-9.3-4.8
						c-6.2-1.5-8.7,2-13-0.3C122.2,547.3,121.1,544,119.5,544.5z'
							/>
							<text id='XMLID_133_' transform='matrix(1 0 0 1 87.7112 430.2838)' className='st4 st5 text-map'>
								{t('kab-cilacap')}
							</text>
						</g>
						<g id='Kabupaten_Banyumas' className='region' from='home' post-name='kabupaten-banyumas'>
							<path
								id='XMLID_37_'
								className='st21'
								d='M364.1,517.7c-0.4-1.4-1.1-3.5-2.4-6.1c-1.3-2.8-2-4.2-2.9-4.8c-2.3-1.5-4.6-0.1-5.6-1.6
						c-0.6-0.9-0.5-2.5,0.4-3.5c0.7-0.8,1.5-0.1,3.3-1c0.8-0.4,1.5-0.9,2.8-2c0.7-0.6,1.1-0.9,1.3-1.1c0.7-0.9,0.7-2,0.5-4.8
						c-0.3-4.4-0.5-6.2-0.5-6.3c-0.5,0.7-1.3,1.8-2.6,2.3c-2.4,0.9-4-0.9-6.1-0.1c-2.4,0.9-2.2,3.7-3.8,3.9c-2.1,0.2-2.4-5-6.9-7.9
						c-3.9-2.5-8.5-1.5-9-3.6c-0.2-0.6,0.3-0.7,0.4-2c0.3-3-1.7-4.6-1-5.5c0.6-0.7,1.9,0.3,3.5-0.6c1.2-0.7,2-2.2,1.6-2.9
						c-0.2-0.4-0.9-0.4-1.6-0.4c-1.7,0-3.4-0.4-5.1-0.4c-5.1,0.1-4.7-0.7-8.1-0.4c-1.7,0.2-5,0.5-6.3,1.6c-0.1,0-0.2,0.2-0.3,0.1
						c-0.4-0.2,0.6-3.2,2.3-5.8c1.2-1.9,3.3-5.2,6.1-5.4c0.5,0,1.2,0.1,1.5-0.4c0.6-0.7-0.3-2.4-0.6-3c-3.8-7-1.9-5.8-5.8-11.5
						c-2.9-4.4-4.9-4.7-6.5-8.5c-0.8-2-1.1-3.6-1.3-4.4c-1.7-9.3-7.7-13.2-7.8-18c0-0.4,0-1.7-0.5-3.3c-0.5-1.6-1.4-2.6-1.8-3.1
						c-2.8-3.8-1.2-6.7-4.4-11.9c-0.8-1.3-0.7-0.8-2.4-3.1c-2.6-3.7-3.5-5.9-4.8-8c-3.9-6.6-10.9-11.5-13.1-10.2
						c-0.1,0.1-0.3,0.4-0.8,0.9c-1.7,2.3-1.9,3.4-2.6,3.9c-1,0.8-2.8,0.3-4.1-0.3c-0.3,1.1-2.9,0.6-4.5,2.6c-1,1.3-0.5,2.1-1.5,2.8
						c-1.2,0.9-2.5-0.1-5.5-0.3c-4.1-0.3-4.2,1.2-7.3,0.5c-0.8-0.2-2.4-0.7-4.7-0.8c-0.7,0-1.2,0-1.8,0.2c-1.5,0.5-2.2,1.8-2.5,2.3
						c-3.1,5.5-15.3,22.3-20.7,22.7c-0.3,0-1,0-1.7,0.5c-1.6,1.2-0.2,3.9-1.7,5c-2,1.5-5,0.7-6.7-0.5c-2.4-1.7-2.2-4.2-3.8-4.5
						c-1.6-0.3-2.1,2.1-4.8,3.3c-1.2,0.5-4.6,0-11.5-1c-3.1-0.5-4.3-0.6-5.8,0c-1.4,0.6-2.2,1.5-2.9,1.9c0.3,0.9,0.4,1.7,0.4,2.3
						c-0.1,2.2-0.9,4.4-0.9,4.4c-1,2.7-2,3.3-3.5,6c-1.6,2.9-2.2,5.6-2.5,7.1c-0.5,2.5-0.5,4-1.5,7.4c-0.2,0.5-0.4,1.4-0.8,2.3
						c-0.4,1-2.2,5.8-6.8,9c-2.9,2.1-4,1.4-8.3,4.4c-2.6,1.8-3,2.6-3.3,3.3c-0.9,2.5,0.6,6.5,3.4,8.1c4.2,2.5,11.6-0.4,17.8-0.6
						c0.3,0,1,0,1.9,0.3c1.4,0.5,3.2,1.7,3.6,3.5c0.3,1.5-0.7,1.9-1.1,4.9c-0.2,1.5-0.3,2.5,0,3.5c0.6,1.8,2.1,2,6.4,4.8
						c3.3,2.2,3.1,2.5,5.4,3.6c2.4,1.2,3.1,1,4.1,2c1.9,1.9,0.7,3.8,2.1,5.8c1.4,1.9,3.3,1.2,12.1,3.5c3,0.8,4.3,1.3,5.6,2.4
						c1.4,1.2,1.5,2,3.4,3.6c1,0.9,2.2,2,4,2.8c3.4,1.5,7.1,0.8,7.8,0.6c1.7-0.3,3-0.9,5.3-0.6c0.9,0.1,1.5,0.3,1.9,0.5
						c4.9,1.6,6.1,3.2,9,3.8c0.7,0.1,0.3,0,9.5-1.3c3-0.4,3.6-0.5,4.3-1.2c2.2-2,2.5-6.9,2.5-7.3c0.1-0.9,0-1.4,0.3-1.7
						c1-0.9,3.4,1.3,7.2,1.3c1.6,0,1.8-0.4,3.2-0.5c3.3-0.2,5.1,2.1,13.8,9.5c3.9,3.3,4.8,3.9,6,5.7c1,1.5,1.7,2.9,3.5,5.3
						c1,1.4,2,2.5,2.7,3.2c12.7,4.3,26.2,6.6,38.3,12.3c0.2,0.1,0.9,0.5,1.8,0.5c2,0.1,3.4-1.3,3.7-1c0.1,0.2,0.1,0.4-0.1,0.7
						c3.8-1.9,9.4-4.6,12.5-5.2c0.4-0.1,1.4-0.2,2.4-0.9c1.7-1.2,1.4-2.5,3.1-5.1c1.5-2.3,2.3-2.1,2.8-3.6
						C365.2,521.4,364.8,520.2,364.1,517.7z'
							/>
							<text id='XMLID_268_' transform='matrix(1 0 0 1 212.3947 461.8543)' className='st4 st5 text-map'>
								{t('kab-banyumas')}
							</text>
						</g>
						<g id='Kabupaten_Tegal' className='region' from='home' post-name='kabupaten-tegal'>
							<path
								id='XMLID_128_'
								className='st24'
								d='M273.3,379.1c0.7-0.6,0.8-1.6,2.6-3.9c0.4-0.6,0.6-0.8,0.8-0.9c0.3-0.2,0.8-0.2,1.4-0.2
						c-1.8-3.2-4.2-6.3-4.6-9.5c-0.2-1.8-0.9-3.5-1.1-5.3c-0.1-0.4-0.2-1.3-0.8-2.1c-0.5-0.7-0.9-0.8-1.3-1.2c-0.7-0.7-0.7-1.9,0.3-8
						c0.3-1.6,0.5-2.7,0.3-4.2c-0.1-1.4-0.5-1.7-0.4-2.4c0.4-2.7,3.8-3.4,6.5-5.8c3-2.6,1.6-4.2,5.5-12.3c2.6-5.5,4-8.2,7-9.8
						c2.9-1.5,4.5-0.4,7-2.3c1.3-1,1.8-1.9,4.3-7.8c1.6-3.9,3.4-7.8,5-11.8c1.5-3.8,1.9-4.9,3.3-6.3c2.3-2.3,4.1-1.7,6.8-3.3
						c4.7-2.7,7.1-8.9,7-14.3c-0.1-4.4-1.9-7.6-3-9.3c11.7-17.5,14-24.2,14-24.3c0.2-0.7,0.6-1.8,1.3-3.5c1.5-3.8,2.8-5.8,2.5-6
						c-0.5-0.4-2.9,4.3-7.3,5c-2.5,0.4-2.8-0.9-5.5-0.5c-3.3,0.5-3.9,2.6-7.5,3.5c-2,0.5-3.1,0.1-5.3,0c-5-0.3-6.2,1.2-12,2
						c-3.2,0.4-10.9,1.5-18.5-1.3c-1.9-0.7-2.9-1.3-5-1.5c-4.6-0.5-5.7,1.9-9.8,1.5c-4.1-0.4-4-2.9-8.3-3c-2.4,0-4.1,0.7-5.8-0.5
						c-1.3-0.9-1.4-2.3-2-2.3c-0.7,0.1-1.3,2.1-1,4c0.3,1.6,0.9,1.7,1.3,3c0.7,2.9-1.6,5.7-2.3,6.5c-1.5,1.9-2.3,1.6-4,3.3
						c-3.3,3.2-2.1,5.7-4.5,8c-2.2,2.1-5.2,2.1-10.8,2c-2.8,0-4.7-0.1-7.1-0.4c0.6,4.6,3.8,9.2,4.3,9.8c0.8,1.2,1.4,1.8,1.4,2.8
						c0,1.7-1.7,2.2-2.8,4.8c-0.8,1.9-0.1,2.2-0.8,3.6c-0.9,1.9-2.3,1.5-7.3,4.5c-2.6,1.6-3.8,2.6-6.3,3.1c-1.2,0.3-1.4,0.1-1.9,0.4
						c-0.5,0.3,0.3,2.4-1,10.6c-0.9,6.1,0.4,10.3-1.1,11.1c-0.1,0.1-0.8,0-2.1,0c-2.7-0.1-3.4-0.3-4.6,0c-0.9,0.2-0.7,0.4-2.6,1.1
						c-1.1,0.4-1.4,0.4-3.9,1.3c-2.9,0.9-3.3,1.1-3.8,1.5c-0.7,0.5-1.8,1.4-1.9,2.6c-0.2,2.1,2.9,2.9,2.8,5.1c-0.1,1.1-0.9,2.1-1.6,3
						c-1,1.2-1.5,1.2-1.6,1.9c-0.3,1.5,1.8,2.3,1.5,3.9c-0.2,0.9-0.9,0.9-1.4,2.1c-0.4,1.1,0,1.7-0.1,2.4c-0.4,2.9-7.5,3.5-7.5,5
						c0,0.8,1.9,1.2,4.8,2c2.2,0.6,2.8,0.4,3.1,0.1c0.8-0.8,0.3-2.5,0.3-2.8c0,0,8.1,2.2,15,0.9c0.9-0.2,2.4-0.5,3.8,0.3
						c0.9,0.5,0.9,1.1,2.4,3c1.4,1.8,2.1,2.7,3,2.8c1.1,0,2.1-1.2,2.5-2.3c0.7-1.7-0.7-2.9-0.4-5c0.4-2.3,2.6-4.7,4.6-4.6
						c1.8,0,1.9,2.1,4.6,3c2.5,0.9,3.9-0.4,5,0.6c1,1,1.1,2.4,1.7,5.5c0.6,3.2,0.7,2.9,0.7,4.6c-0.1,3-3.8,3.7-7.3,9.5
						c-1,1.6-1.1,2.2-1,2.8c0.4,1.8,2.8,2.1,7.5,4.3c4.9,2.3,3.8,2.8,8.2,4.7c3,1.3,4.9,1.7,6,3.7c0.9,1.5,0.2,2.2,1.2,3.3
						c1.4,1.6,3.2,0.3,6.7,2c2.5,1.2,3.7,2.5,4.8,2c1.5-0.7,0.6-3.5,2.3-4.5c1.7-0.9,4.2,0.7,4.3,0.8c1.1,0.7,1,1.2,2.7,3.3
						c1.9,2.4,2.8,2.8,2.7,3.5c0,0,0,0,0,0c0,0,0,0,0,0C270.5,379.4,272.3,379.9,273.3,379.1z'
							/>
							<text id='XMLID_127_' transform='matrix(1 0 0 1 227.6773 297.7083)' className='st4 st5 text-map'>
								{t('kab-tegal')}
							</text>
						</g>
						<g id='Kota_Tegal' className='region' from='home' post-name='kota-tegal'>
							<path
								id='XMLID_60_'
								className='st24'
								d='M240.2,253c2.4-2.3,1.2-4.8,4.5-8c1.7-1.7,2.5-1.4,4-3.3c0.6-0.8,2.9-3.6,2.3-6.5
						c-0.3-1.3-1-1.4-1.3-3c-0.2-1.5,0.1-3,0.6-3.7c-1.1-1.9-2.4-3.3-4.2-3.9c-1.5-0.5-2.9-0.2-4,0c-1.4,0.2-2.7,0.4-3.8,0.5
						c0.4,2.7-4.6,6-5.8,6.7c-2.5,1.6-3.8,2.5-5.9,2.8c-1.3,0.2-2.8,0.1-3.5,1.1c-0.4,0.6-0.3,1.3-0.3,1.6c0.4,3.3,0.2,7.4,0,12.3
						c-0.2,3.5-0.7,2.7-0.5,4.5c0,0.2,0.1,0.4,0.1,0.6c2.3,0.4,4.2,0.4,7,0.4C235,255.1,238,255.1,240.2,253z'
							/>
							<text id='XMLID_214_' transform='matrix(1 0 0 1 222.2534 244.1655)' className='st4 st5 text-map'>
								{t('tegal')}
							</text>
						</g>
						<g id='Kabupaten_Pemalang' className='region' from='home' post-name='kabupaten-pemalang'>
							<path
								id='XMLID_121_'
								className='st10'
								d='M414.8,220.8c-0.6-0.2-1,0.6-2.1,0.7c-1.4,0.1-2.4-1.1-3.5-2.3c-2.3-2.2-2.4-1.5-5.3-4
						c-2.7-2.4-2.3-2.8-5.6-6.2c-2.1-2.2-4-3.7-6.4-5.7c-2.2-1.8-3.4-4-4.8-3.7c-0.5,0.1-0.5,0.4-1.7,1.3c-0.9,0.7-1.5,1.2-2.4,1.5
						c-1.9,0.7-2.9-0.2-4.6,0.8c-0.2,0.2-0.4,0.3-0.8,0.6c-2.3,2-3.4,3.1-5,4.7c-2.3,2.1-3.2,2.8-3.2,3.8c0,0.3,0.1,0.8-0.2,1.5
						c-0.2,0.6-0.6,0.9-0.8,1.1c-1.7,1.6-1.5,3-2.8,3.6c-0.5,0.2-0.7,0.1-1.6,0.1c-1.6,0-2.8,0.4-4.3,0.9c-4.6,1.5-7.1,4.2-12.4,5.3
						c-1.7,0.4-3.2,0.5-4.1,0.5c-3.8,0.2-6.5-0.3-7.1-0.4c-2.9-0.4-5.9,2-8.7,5.1c0.7,0.2,1.4,0.4,2.7,0.2c4.4-0.7,6.8-5.4,7.3-5
						c0.3,0.2-1,2.2-2.5,6c-0.7,1.7-1,2.8-1.3,3.5c0,0-2.3,6.8-14,24.3c1.1,1.6,2.9,4.9,3,9.3c0.1,5.3-2.3,11.5-7,14.3
						c-2.7,1.6-4.4,1-6.8,3.3c-1.4,1.3-1.7,2.5-3.3,6.3c-1.6,4-3.4,7.8-5,11.8c-2.4,5.8-2.9,6.8-4.3,7.8c-2.5,1.9-4.1,0.7-7,2.3
						c-3,1.5-4.4,4.3-7,9.8c-3.9,8.1-2.5,9.7-5.5,12.3c-2.7,2.3-6.1,3-6.5,5.8c-0.1,0.7,0.3,1,0.4,2.4c0.1,1.5-0.1,2.6-0.3,4.2
						c-1,6.1-1.1,7.3-0.3,8c0.4,0.4,0.8,0.5,1.3,1.2c0.6,0.9,0.7,1.8,0.8,2.1c0.2,1.8,0.9,3.5,1.1,5.3c0.4,3.2,2.8,6.3,4.6,9.5
						c1.6,0.3,3.9,1.6,6.2,3.7c1-1.5,2.1-3.1,4.1-3.3c2.1-0.2,3,1.4,5.9,1.3c2.1-0.1,2.7-1.1,4.9-2c4.5-1.9,5.5,0.5,10.6-0.9
						c2.1-0.6,5.6-2,8.8-4c0.8-0.5,1.7-1.2,3.1-1.4c2.4-0.4,3.1,1,5.1,0.6c2.1-0.4,2.1-1.9,4.9-3c2-0.8,2.2-0.1,4.3-0.8
						c3.9-1.3,4.7-4.5,6.8-4.1c1.4,0.3,1.3,1.8,3.5,2.6c0.5,0.2,2,0.7,3.4,0.1c2-0.8,1.7-3,3.6-3.6c1.2-0.4,1.6,0.4,3.1,0.1
						c0.8-0.1,1.9-0.6,4.3-3.5c2.9-3.7,3.5-6,4.4-5.9c0.8,0.2,0.9,2.4,1,3.3c0.2,2.7-0.6,3-0.5,5.1c0.1,1.9,0.8,1.6,2.1,6
						c0.5,1.7,0.6,2.3,1.3,3.1c0.8,1,1.3,0.9,3.1,2.1c1.6,1.1,2.9,2.3,4.1,3.4c1.5,1.4,1.8,1.8,2.6,2.1c1.3,0.5,1.7,0,3.4,0.3
						c2.3,0.4,2.5,1.6,4.4,1.9c2,0.3,3.8-0.8,4.4-1.1c2.2-1.4,3.9-4,3.6-6.5c-0.1-0.9-0.4-1.6-1-3c-0.9-2.1-1.3-2.1-1.6-3.4
						c-0.4-1.9,0.5-2.2,0.1-4c-0.4-1.7-1.4-1.9-2-3.6c-0.6-1.7,0-3.3,0.1-4c0.7-3.2-1.2-6.1-4.3-11.9c-1.1-2.1-2.3-4-1.8-6
						c0.3-0.9,0.6-1.2,0.8-2.1c0.2-1.8-1.1-2.5-1.9-4.6c-0.9-2.5-0.4-5.1,0-7.5c0.3-1.4,0.5-2.1,0.5-3.6c0-1.5-0.4-2.7-0.6-3.5
						c-0.5-1.6-1-2.8-1.1-3c-1.3-2.7-0.5-6.3-0.5-10.4c0.1-7-1.9-14.3-0.3-16.8c0.1-0.1,0.4-0.6,0.9-1.4c0.6-1.1,1.1-1.9,1.4-3
						c0.3-1.3-0.1-1.5,0.1-2.6c0.3-1.4,1.1-2.3,1.9-3.1c2.4-2.7,1.6-2.7,3.9-5.3c1.7-1.9,4.7-5.2,9.3-6.9c1.6-0.6,1.8-0.3,3.3-1
						c2.7-1.2,6.1-3.6,5.8-5.8c-0.1-0.8-0.7-0.9-0.9-2.1c-0.1-0.6,0-1.2,0.5-2.8c1-2.8,1.4-2.7,1.5-3.9c0.2-1.8-0.7-2.3-0.3-3.8
						c0.3-1.2,1-1.4,2-2.6c1.3-1.7,0.9-2.6,2-5.5c1.2-3.2,1.9-2.8,2.8-5.5C415.7,224.5,415.8,221.2,414.8,220.8z'
							/>
							<text id='XMLID_120_' transform='matrix(1 0 0 1 288.344 328.832)' className='st4 st5 text-map'>
								{t('kab-pemalang')}
							</text>
						</g>
						<g id='Kabupaten_Purbalingga' className='region' from='home' post-name='kabupaten-purbalingga'>
							<path
								id='XMLID_82_'
								className='st18'
								d='M419.2,383.8c-3-1.8-4.6-2.7-5.3-2.9c-2.6-0.5-5,0.7-5.8,1.1c-1.7,1-1.9,1.9-3.3,2
						c-1.3,0.1-1.4-0.8-5-2.8c-1.7-0.9-2.7-1.3-3-2.4c-0.2-0.8,0-1.5,0.1-1.8c0.3-1.1,0-2.9-2.7-6.5c-0.2,2.2-1.7,4.4-3.6,5.6
						c-0.6,0.4-2.3,1.4-4.4,1.1c-1.9-0.3-2.1-1.5-4.4-1.9c-1.6-0.3-2.1,0.2-3.4-0.3c-0.9-0.3-1.1-0.7-2.6-2.1c-1.2-1.1-2.5-2.3-4.1-3.4
						c-1.9-1.2-2.3-1.1-3.1-2.1c-0.7-0.8-0.8-1.5-1.3-3.1c-1.3-4.4-2-4.1-2.1-6c-0.1-2.1,0.7-2.5,0.5-5.1c-0.1-0.9-0.2-3.1-1-3.3
						c-0.8-0.2-1.5,2.2-4.4,5.9c-2.3,2.9-3.4,3.4-4.3,3.5c-1.5,0.2-1.9-0.5-3.1-0.1c-1.9,0.7-1.6,2.8-3.6,3.6c-1.4,0.6-2.9,0-3.4-0.1
						c-2.2-0.8-2.1-2.4-3.5-2.6c-2-0.4-2.8,2.8-6.8,4.1c-2,0.7-2.2-0.1-4.3,0.8c-2.8,1.1-2.8,2.6-4.9,3c-2,0.4-2.7-1-5.1-0.6
						c-1.4,0.2-2.3,0.9-3.1,1.4c-3.1,2-6.7,3.4-8.8,4c-5.1,1.4-6.2-1-10.6,0.9c-2.2,0.9-2.8,1.9-4.9,2c-2.8,0.2-3.8-1.4-5.9-1.3
						c-2,0.2-3.1,1.8-4.1,3.3c-0.1-0.1-0.2-0.2-0.3-0.3c2.1,1.8,4.2,4.2,5.8,6.9c1.2,2.1,2.1,4.2,4.8,8c1.7,2.4,1.6,1.8,2.4,3.1
						c3.2,5.1,1.5,8.1,4.4,11.9c0.4,0.5,1.2,1.5,1.8,3.1c0.5,1.6,0.5,2.8,0.5,3.3c0,4.8,6,8.7,7.8,18c0.1,0.8,0.4,2.4,1.3,4.4
						c1.6,3.8,3.6,4.1,6.5,8.5c3.8,5.7,2,4.5,5.8,11.5c0.3,0.6,1.2,2.3,0.6,3c-0.3,0.4-1,0.4-1.5,0.4c-2.8,0.1-4.9,3.5-6.1,5.4
						c-1.6,2.5-2.6,5.5-2.3,5.8c0.1,0.1,0.3-0.1,0.3-0.1c1.3-1.1,4.6-1.4,6.3-1.6c3.4-0.4,3,0.5,8.1,0.4c1.7,0,3.4,0.4,5.1,0.4
						c0.5,0,0.9,0,1.2,0.1c1-1.1,2.4-2.5,4.6-2.9c3.3-0.6,5.5,1.7,8.3,0.4c1-0.5,0.4-0.7,2.9-2.9c1.6-1.4,2.9-2.3,4.6-3.4
						c2.1-1.4,2.5-1.5,2.8-1.6c0.3-0.1,2.4-0.6,4.2,0.5c1,0.6,1.1,1.2,1.9,1.3c0.8,0.2,1.8-0.2,2.4-0.8c0.7-0.7,0.5-1.2,1.2-2.8
						c0.5-1.1,0.7-1.6,1.2-2c0.7-0.6,1.3-0.3,4.9-0.3c1.2,0,2.3,0,3.5,0.1c1.6,0,1.8,0,2.2,0.2c1.3,0.7,0.8,2.1,2,3
						c1.1,0.8,2.8,0.7,3.6,0c0.4-0.4,0.6-0.9,0.6-0.9c0.1-0.3,0.1-0.4,0.2-0.8c0.2-1.1,0.6-1.4,0.4-1.6c-0.2-0.2-0.6,0.1-1.1,0.1
						c-1.1,0-1.8-1.2-2-1.5c-0.7-1-0.6-2-0.5-3.8c0.1-1.7,0.2-2.9,1-4.3c0.6-1,1.3-1.5,2.8-2.5c1.5-1.1,2.9-2.1,5-2.8
						c2.7-0.9,3.8-0.3,5.4-1.5c1-0.8,1.6-1.7,1.9-2.4c-2.5-2-2.9-2.6-2.9-2.6c-0.5-0.8-0.2-0.9-0.9-2.8c-0.5-1.3-0.9-2.1-1.1-2.6
						c-3.4-7.1-3-13.5-3-13.5c0.1-2.2,0.3-3.9,1.5-4.8c1.2-0.9,3-0.5,5.9,0.1c3,0.6,3.9,1.4,5.6,0.9c0.3-0.1,1.6-0.5,2.5-1.6
						c0.8-1,1-2,1.1-2.6c0.5-2.3,1.3-3.9,2.5-6.5c1.1-2.3,2.3-4,3.4-5.4c1.8-2.4,2.3-2.5,3.4-4.1c0.9-1.3,2-3.4,2.8-6.4
						C419.2,383.8,419.2,383.8,419.2,383.8z'
							/>
							<text id='XMLID_267_' transform='matrix(1 0 0 1 303.0775 402.2838)' className='st4 st5 text-map'>
								{t('kab-purbalingga')}
							</text>
						</g>
						<g id='Kabupaten_Banjarnegara' className='region' from='home' post-name='kabupaten-banjarnegara'>
							<path
								id='XMLID_114_'
								className='st24'
								d='M536.8,359.9c-0.3-0.8-1-1.7-6.4-4.3c-4.2-2-5.1-2.1-5.6-2.1c-1.9-0.1-1.8,0.6-5,0.8
						c-2.3,0.1-4,0.2-5.9-0.6c-1.1-0.5-1.4-1-2-0.9c-1.5,0.3-1.5,3.4-3.3,3.8c-0.5,0.1-0.9-0.1-1.4-0.3c-1.6-0.6-1.6-1.9-2.6-2.6
						c-1.8-1.3-4.7,0.5-9.4,0.4c-0.5,0-1.5-0.1-2.1-0.8c-0.6-0.6-0.2-1.2-0.6-1.9c-0.4-0.8-1.3-0.9-5-1.8c-2.8-0.6-3-0.7-3.6-0.6
						c-0.4,0-1.8,0.2-3.9,1.5c-1.4,0.9-1.8,1.4-4.4,3.9c-2.9,2.7-2.6,2.3-4.9,4.5c-2.4,2.4-2.4,2.5-3,2.7c-2.5,0.7-3.7-2.2-7.8-2.5
						c-2.5-0.2-2.7,0.8-4.8,0.3c-0.2,0-4.1-1-5.3-4.1c-0.5-1.4-0.4-2.9-1.2-3.1c-0.4-0.1-0.7,0.2-0.9,0.3c-2.1,1.5-5.8-0.2-11.6-0.1
						c-0.5,0-0.7,0-0.9,0.1c-1.9,0.5-1.6,3-4.1,4.3c-1,0.5-2.1,0.8-3.1,2c-0.7,0.8-0.7,1.3-1.4,1.8c-1.1,0.9-2.4,1-2.9,1
						c-1.7,0.1-4.3,1.1-9.4,3.2c-3.2,1.3-5.4,2.5-9.3,4.9c-2.8,1.7-4.3,2.6-6.1,4.2c-0.7,0.6-1.4,1.3-1.9,1.9c0.1,0.5,0.1,0.9,0,1.2
						c-0.1,0.3-0.4,1-0.1,1.8c0.3,1.1,1.3,1.5,3,2.4c3.6,2,3.7,2.9,5,2.8c1.3-0.1,1.6-1,3.3-2c0.7-0.4,3.2-1.7,5.8-1.1
						c0.7,0.1,2.2,1.1,5.3,2.9c0,0,0,0,1.9,1.1c-0.7,3-1.9,5.1-2.8,6.4c-1.1,1.6-1.6,1.8-3.4,4.1c-1.1,1.4-2.3,3.1-3.4,5.4
						c-1.2,2.6-2,4.2-2.5,6.5c-0.1,0.7-0.3,1.7-1.1,2.6c-0.9,1.1-2.2,1.5-2.5,1.6c-1.7,0.5-2.6-0.2-5.6-0.9c-2.9-0.6-4.6-1-5.9-0.1
						c-1.2,0.9-1.4,2.6-1.5,4.8c0,0-0.4,6.4,3,13.5c0.2,0.5,0.7,1.3,1.1,2.6c0.6,1.8,0.4,2,0.9,2.8c0,0,0.4,0.6,2.9,2.6
						c-0.3,0.7-0.9,1.6-1.9,2.4c-1.6,1.2-2.7,0.6-5.4,1.5c-2.1,0.7-3.5,1.7-5,2.8c-1.4,1-2.2,1.5-2.8,2.5c-0.8,1.3-0.9,2.6-1,4.3
						c-0.1,1.7-0.2,2.8,0.5,3.8c0.2,0.3,0.9,1.5,2,1.5c0.6,0,1-0.3,1.1-0.1c0.2,0.2-0.2,0.6-0.4,1.6c-0.1,0.4,0,0.5-0.2,0.8
						c0,0-0.2,0.5-0.6,0.9c-0.7,0.7-2.5,0.8-3.6,0c-1.3-0.9-0.8-2.3-2-3c-0.4-0.2-0.6-0.2-2.2-0.2c-1.2,0-2.3-0.1-3.5-0.1
						c-3.7,0-4.2-0.3-4.9,0.3c-0.5,0.4-0.7,0.9-1.2,2c-0.7,1.6-0.5,2.2-1.2,2.8c-0.6,0.6-1.6,0.9-2.4,0.8c-0.9-0.2-1-0.7-1.9-1.3
						c-1.8-1.1-3.9-0.6-4.2-0.5c-0.3,0.1-0.8,0.2-2.8,1.6c-1.7,1.1-2.9,2-4.6,3.4c-2.5,2.2-1.9,2.4-2.9,2.9c-2.7,1.4-4.9-1-8.3-0.4
						c-2.1,0.4-3.6,1.8-4.6,2.9c0,0,0,0,0,0c0.2,0.1,0.3,0.1,0.4,0.3c0.4,0.7-0.4,2.2-1.6,2.9c-1.6,0.9-2.9-0.1-3.5,0.6
						c-0.7,0.9,1.3,2.5,1,5.5c-0.1,1.3-0.5,1.4-0.4,2c0.5,2.1,5.1,1.1,9,3.6c4.5,2.8,4.8,8.1,6.9,7.9c1.6-0.2,1.4-3,3.8-3.9
						c2.2-0.8,3.7,1,6.1,0.1c1.3-0.5,2.1-1.5,2.6-2.3c0,0,0,0.1,0,0.4c0.3-0.4,0.6-0.6,1-0.8c0.4-0.1,0.5-0.1,3.1-0.1
						c2.5,0,2.3-0.1,3.5,0c2.9,0.1,3.3,0.5,4.6,0.3c1.7-0.3,3-1.3,4.4-2.3c1.2-0.8,2.1-1.7,2.8-2.3c1.7,0.6,2.5,0.9,2.5,0.9
						c1.4,0.4,4.4,1.4,6.6,0.4c0.3-0.1,0.8-0.4,1.4-0.8c1.1-0.6,1.5-1,1.8-1.1c0.7-0.4,2.3,0,6,3.5c0,0,1.6-0.9,2-1.1
						c1.6-0.8,3.7,0.5,5.6,1.3c0.5,0.2,5,2,9.4,1.1c2-0.4,3.6-1.4,6.9-3.5c2.4-1.5,3.6-2.3,5-3.8c2.2-2.3,2.2-3.5,4.3-4.8
						c1.8-1,3.5-1.2,4.4-1.3c1.5-0.1,3.1-0.2,4.5,0.6c1.8,1.1,2,2.9,3.4,2.9c0.5,0,1-0.3,1.9-0.8c1.2-0.7,1.3-1.1,1.9-1.1
						c0.9-0.1,1.9,0.7,2.4,1.6c0.7,1.4-0.2,2.5,0.4,3c0.4,0.3,1.1-0.1,3-0.6c1.5-0.4,2-0.4,2.8-0.6c3.4-0.9,6.1-4.6,7.1-6
						c1-1.4,2.2-3.1,2.9-5.6c0.5-1.8,1.1-4.3,0.6-7.3c-0.1-0.9-0.4-1.5,0-2.3c0.6-1.2,2-0.9,3.6-2.4c1.1-1.1,1-1.8,1.9-2
						c0.8-0.2,1.1,0.4,2.8,0.5c0.9,0.1,0.8-0.1,1.3,0c1.7,0.4,1.9,3,2.8,3c1,0,1.7-3.4,2.3-3.3c0.1,0,0.2,0.3,0.2,0.4
						c0.4,1.6,3.6,5,6.9,5.6c0.9,0.2,0.9,0,4.3-0.5c2.9-0.4,6.1-0.8,9.8-0.8c3,0.1,3.7,0.4,5.3-0.1c1-0.3,1.8-0.9,3-0.6
						c1.2,0.2,1.5,1,2.2,0.9c0.8-0.2,0.7-1.2,1.9-3.5c0.9-1.8,1.4-2.1,1.3-2.9c-0.1-0.6-0.5-0.9-2-2.3c-3.1-2.7-3.1-2.9-4-3.4
						c-1.6-0.8-1.8-0.4-3-1.3c-1.4-1-1.5-1.8-2.8-2.5c-1-0.5-1.4-0.2-2.8-0.5c-2.1-0.4-2-1.3-5.8-3.3c-2.1-1.1-3.1-1.6-4.3-1.9
						c-1-0.2-1.5-0.2-1.9-0.6c-0.9-1-0.5-3.1-0.1-4.3c0.3-0.8,0.6-1.1,0.5-1.8c-0.1-1-0.9-1.9-0.8-2c0,0,0.1,0,0.1,0
						c0.5,0.2,1.7-0.4,1.9-0.5c1.2-0.6,2.2-2.9,2.3-3c2.7-5.9,3.4-6.6,3.9-9.5c0.2-1.3,0.1-1.1,0.3-1.8c0.9-3.5,3.8-3.9,6.1-7.5
						c1.2-1.9,0.4-1.7,2.1-5c1.6-2.9,2.2-3.1,3.6-6c1.1-2.3,1.6-3.4,1.9-4.9c0.2-1.3,0.2-2.3,1-3.5c0.4-0.6,0.8-0.8,1.3-1.6
						c0.6-1,0.6-1.9,0.8-2.4c0.6-1.8,1.4-3.4,2-4.5c0.7-1.3,3.1-5.5,7.6-7.3c0.6-0.2,2.5-0.5,6.4-1.1c1.6-0.2,2.5-0.3,3.6-0.1
						c1.2,0.2,1.5,0.6,2,0.4c1.1-0.5,1.1-2.8,1.1-5.4C537.1,361.6,537.1,360.5,536.8,359.9z'
							/>
							<text id='XMLID_113_' transform='matrix(1 0 0 1 411.6055 419.127)' className='st4 st5 text-map'>
								{t('kab-banjarnegara')}
							</text>
						</g>
						<g id='Kabupaten_Kebumen' className='region' from='home' post-name='kabupaten-kebumen'>
							<path
								id='XMLID_117_'
								className='st13'
								d='M512.8,457.3c-0.7,0.2-1-0.6-2.2-0.9c-1.2-0.2-2,0.3-3,0.6c-1.6,0.5-2.3,0.2-5.3,0.1
							c-3.7-0.1-6.8,0.4-9.8,0.8c-3.3,0.5-3.3,0.7-4.3,0.5c-3.3-0.7-6.5-4-6.9-5.6c0-0.1-0.1-0.3-0.2-0.4c-0.5-0.1-1.2,3.3-2.3,3.3
							c-0.9,0-1.1-2.6-2.8-3c-0.4-0.1-0.4,0.1-1.3,0c-1.6-0.1-2-0.7-2.8-0.5c-0.8,0.2-0.7,0.9-1.9,2c-1.6,1.5-3,1.1-3.6,2.4
							c-0.4,0.7-0.1,1.3,0,2.3c0.5,3-0.2,5.5-0.6,7.3c-0.7,2.6-1.9,4.3-2.9,5.6c-1,1.4-3.7,5.1-7.1,6c-0.7,0.2-1.3,0.2-2.8,0.6
							c-1.9,0.5-2.6,1-3,0.6c-0.6-0.5,0.3-1.6-0.4-3c-0.4-0.9-1.5-1.7-2.4-1.6c-0.6,0.1-0.7,0.5-1.9,1.1c-0.9,0.5-1.4,0.8-1.9,0.8
							c-1.4,0-1.5-1.8-3.4-2.9c-1.4-0.9-3-0.7-4.5-0.6c-0.8,0.1-2.6,0.2-4.4,1.3c-2.1,1.2-2,2.4-4.3,4.8c-1.4,1.5-2.6,2.2-5,3.8
							c-3.3,2.1-4.9,3.1-6.9,3.5c-4.3,0.9-8.8-0.9-9.4-1.1c-2-0.8-4-2-5.6-1.3c-0.4,0.2-2,1.1-2,1.1c-3.7-3.5-5.3-3.9-6-3.5
							c-0.3,0.1-0.7,0.5-1.8,1.1c-0.6,0.3-1.1,0.6-1.4,0.8c-2.2,1-5.2,0.1-6.6-0.4c0,0-0.8-0.3-2.5-0.9c-0.6,0.6-1.5,1.4-2.8,2.3
							c-1.4,1-2.6,1.9-4.4,2.3c-1.4,0.3-1.7-0.1-4.6-0.3c-1.2-0.1-1,0-3.5,0c-2.5,0-2.7-0.1-3.1,0.1c-0.4,0.2-0.7,0.5-1,0.8
							c0.1,0.7,0.2,2.6,0.5,5.9c0.2,2.7,0.2,3.8-0.5,4.8c-0.2,0.2-0.5,0.5-1.3,1.1c-1.3,1.1-1.9,1.6-2.8,2c-1.8,0.9-2.5,0.2-3.3,1
							c-0.9,1-1,2.6-0.4,3.5c1,1.5,3.3,0.1,5.6,1.6c0.9,0.6,1.6,2,2.9,4.8c1.3,2.7,1.9,4.7,2.4,6.1c0.7,2.5,1.1,3.8,0.8,5
							c-0.4,1.5-1.2,1.3-2.8,3.6c-1.7,2.6-1.4,4-3.1,5.1c-1,0.7-2,0.8-2.4,0.9c-3.1,0.5-8.7,3.2-12.5,5.2c-0.6,0.9-2.4,2.6-2.4,4.8
							c0,2.5,2.1,2.4,3.7,6.7c0.8,2.2,0.4,2.8,1.7,6.5c0.3,1,0.5,1.4,0.5,2c0,2.2-1.9,4.4-3.8,5.3c-2,1-3.4,0.1-7.4-1.1
							c0.1,1.3,0.1,2.6,0.2,3.9c0.1,2,0.4,6.5,3.3,11c1.1,1.7,3.2,4.9,7.3,6.3c4,1.4,7.5,0.4,8.7,0c4.3-1.3,4.5-3.5,8.3-5
							c1.6-0.6,2.6-0.6,11.3,0c8.3,0.6,12.4,0.9,14.7,1.3c6.4,1.2,6.1,2.1,10.7,2.7c6.6,0.8,7.5-1,14.3-0.7c2.6,0.1,6.7,1.2,14.7,3.3
							c8.5,2.3,8.1,2.3,13.3,3.7c12.1,3.1,12.5,2.3,19.7,4.7c7.3,2.4,7.5,3.4,12.3,4.2c6.9,1.1,7.5-0.9,13.3,0.3c7.3,1.6,7.4,5,14.3,6
							c3.1,0.4,5.7,0.1,8.7,2c1,0.6,2.1,1.6,2.8,1.2c0.9-0.5,0.6-2.8,0.5-3.7c-1.2-9.1,1.2-8.3-1.3-25.7c-1-6.8-1.5-7.7-1.3-11
							c0.3-4.9,1.6-6.7,0.3-8.7c-2.2-3.3-7.8-0.9-11-5c-1.6-2-1.7-4.5-1.7-4.7c-0.1-3.4,2.4-4.6,2-7.7c-0.3-2.5-2-2.4-2.3-4.7
							c-0.5-3.5,3.6-4.7,4.7-10c0.3-1.3,0.8-4.1-0.7-6c-2-2.5-6-1.3-6.7-3c-0.6-1.6,2.9-2.9,4.7-7c1.7-3.9-0.3-5.1,1-10
							c1.3-4.7,3.2-4.3,4.7-9c1.5-5.1-0.6-6.2,1-10.7c1.4-4,3.3-3.7,6.3-9c1.7-2.9,3.9-6.7,3.3-10.3c-0.3-2.3-1.8-4.3-3.6-5.9
							C513.6,456.4,513.5,457.1,512.8,457.3z'
							/>
							<text id='XMLID_265_' transform='matrix(1 0 0 1 392.6214 540.1647)' className='st4 st5 text-map'>
								{t('kab-kebumen')}
							</text>
						</g>
						<g id='Kabupaten_Purworejo' className='region' from='home' post-name='kabupaten-purworejo'>
							<path
								id='XMLID_108_'
								className='st18'
								d='M625.8,536c1.4-2.7,2-4,1.6-5.1c-0.2-0.5-0.7-1.1-1.9-2.3c-1.3-1.4-1.5-1.2-1.8-1.8
							c-0.9-1.6,0.5-2.8,0-4.8c-0.4-1.3-1.2-1.4-2.8-3.1c-1.7-1.9-1.4-2.6-3.1-5.5c-1.3-2.1-2.6-3.4-5.1-6.1c-2.6-2.7-3.6-3.3-4.8-3.5
							c-1.2-0.2-1.5,0.2-2.9-0.1c-2-0.5-2-1.5-4-2.1c-1-0.3-2.4-0.4-5.1-0.5c-1,0-1.8,0-2.4-0.6c-0.1-0.1-0.6-0.6-0.8-2
							c-0.2-1.5,0.1-2.1,0.1-3.3c0-2-0.8-3.5-1.3-4.3c-0.5-0.9-1.4-2.4-3.1-3.3c-1.3-0.6-2.5-0.6-3.8-0.6c-1.1,0-1.6,0-1.9,0.3
							c-1.1,1.1,1.9,4.3,0.8,6.5c-0.8,1.4-2.5,1-4.3,3.4c-0.4,0.5-0.3,0.5-1,1.8c-1.7,3-3.1,5.4-4.4,5.3c-0.6-0.1-0.6-0.5-1.9-2
							c-2.1-2.3-3-2.1-3.6-3.5c-0.6-1.4,0.2-1.9-0.4-3.6c-0.3-0.7-0.6-1.2-2.3-3c-3.2-3.5-3.2-3.1-3.8-4c-1.3-2.3-0.7-3.8-2.1-4.8
							c-0.7-0.5-0.8-0.1-2.3-0.8c-1.3-0.5-2.1-1.2-3.1-2c-1.5-1.2-1.8-1.8-3-2.1c-0.5-0.1-1.2-0.4-1.8,0c-0.5,0.4-0.3,0.9-0.8,2
							c-0.3,0.9-0.8,1.4-1.6,2.4c-0.8,1-1.2,1.5-1.9,1.6c-0.7,0.2-1.7,0.1-2.3-0.5c-0.7-0.9,0.3-2.2-0.3-3c-0.6-0.8-2.7,0-4.8,0.4
							c-6.1,1.2-10.7-1.6-11.9,0.4c-0.4,0.7,0,1.4-0.8,2c-0.8,0.7-1.8,0.2-2.8,0.9c-0.9,0.6-1,1.8-1.1,3c-0.1,0.8-0.6,4.4-1.3,6.1
							c-1.3,3.4-4.6,2.4-9.1,7.3c-0.8,0.8-2.2,2.3-3.4,4.6c-1.6,3-1.2,4.3-2.9,6.1c-1.2,1.3-1.6,0.9-4.8,2.9c-1,0.6-1.9,1.4-3.4,2.5
							c1.3,0.8,4.5,0.2,6.2,2.4c1.5,1.9,0.9,4.7,0.7,6c-1.1,5.3-5.1,6.5-4.7,10c0.3,2.3,2,2.1,2.3,4.7c0.4,3-2.1,4.2-2,7.7
							c0,0.2,0.1,2.7,1.7,4.7c3.2,4.1,8.8,1.7,11,5c1.3,2,0,3.7-0.3,8.7c-0.2,3.3,0.3,4.2,1.3,11c2.6,17.3,0.1,16.6,1.3,25.7
							c0.1,0.4,0.2,1.2,0.1,2c1.2,0.6,2.6,1.3,4.2,2c3.8,1.6,5.2,1.7,6.3,1.5c1.4-0.3,2-0.8,3.3-0.7c1.4,0.2,2,0.9,4.2,2.5
							c2.7,2,4,3,5.2,3.2c2.7,0.5,3.9-1.3,5.7-0.3c1.3,0.8,1.2,2.1,2.7,2.5c1.1,0.3,1.6-0.4,2.8-0.3c1.6,0.1,2.4,1.3,3.8,2.5
							c2.6,2.1,3.4,0.7,8.5,3.2c2.7,1.3,3.5,2.2,5.8,2.5c1.2,0.1,1.6,0,3.2,0.2c0.5,0.1,2.1,0.3,4.8,1.3c2,0.8,2.7,1.2,7.3,3.3
							c1.3,0.6,2.2,1,3.3,0.8c1.4-0.2,2.5-1.1,3.2-1.7c0.7-0.6,0.7-0.7,2.7-3.3c1.4-1.8,1.5-1.9,1.7-2.3c0.4-1.2,0.1-1.9,0-3.8
							c0,0-0.1-1.8,0.3-4c0.4-2.3,1.4-3.5,2.5-5.5c1.5-2.7,0.7-2.4,3.7-10.8c0.7-1.9,1.3-3.7,2.6-5.7c1.6-2.6,2.8-3.4,3.3-3.6
							c1-0.6,1.4-0.5,3-1.4c2.1-1.2,1.9-1.6,3-1.9c1.9-0.5,2.8,0.7,4.4-0.1c0.2-0.1,1-0.5,1.1-1.3c0.1-0.5-0.2-0.8-0.3-1.3
							c-0.3-1.1,1-2,2.4-3.8c2-2.6,1.3-3.6,3.1-5.1c0.9-0.8,1.5-0.9,1.8-1.6c0.3-1.1-0.8-1.6-1.1-3.4c-0.2-1.4,0.1-2.6,0.5-3.8
							c0.4-1.3,0.9-2.1,1.1-2.5c1.6-3,0-4.7,0.5-11.6c0.2-2.3,0.5-3.5-0.4-4.9c-0.6-0.9-1.3-1.3-1.6-2.5c-0.1-0.6-0.2-1.3,0.1-2.6
							C624.1,538.9,624.7,538.2,625.8,536z'
							/>
							<text id='XMLID_107_' transform='matrix(1 0 0 1 528.9658 555.9376)' className='st4 st5 text-map'>
								{t('kab-purworejo')}
							</text>
						</g>
						<g id='Kabupaten_Pekalongan' className='region' from='home' post-name='kabupaten-pekalongan'>
							<path
								id='XMLID_140_'
								className='st15'
								d='M414.8,220.8c1,0.4,0.9,3.7,0,6.5c-0.8,2.7-1.6,2.3-2.8,5.5c-1.1,2.9-0.7,3.8-2,5.5
							c-1,1.3-1.7,1.4-2,2.6c-0.4,1.5,0.5,1.9,0.3,3.8c-0.1,1.2-0.5,1-1.5,3.9c-0.5,1.5-0.6,2.1-0.5,2.8c0.2,1.2,0.8,1.3,0.9,2.1
							c0.3,2.1-3.1,4.6-5.8,5.8c-1.5,0.7-1.6,0.4-3.3,1c-4.6,1.6-7.5,4.9-9.3,6.9c-2.2,2.5-1.5,2.5-3.9,5.3c-0.8,0.9-1.6,1.7-1.9,3.1
							c-0.2,1.1,0.2,1.3-0.1,2.6c-0.2,1.1-0.8,1.9-1.4,3c-0.4,0.8-0.8,1.3-0.9,1.4c-1.7,2.4,0.3,9.8,0.3,16.8c0,4.1-0.8,7.7,0.5,10.4
							c0.1,0.2,0.7,1.4,1.1,3c0.2,0.8,0.6,2,0.6,3.5c0,1.5-0.2,2.2-0.5,3.6c-0.4,2.4-0.9,5,0,7.5c0.8,2.1,2.1,2.8,1.9,4.6
							c-0.1,1-0.5,1.2-0.8,2.1c-0.6,2,0.6,3.9,1.8,6c3.1,5.8,5,8.7,4.3,11.9c-0.2,0.7-0.7,2.3-0.1,4c0.6,1.7,1.6,1.9,2,3.6
							c0.4,1.8-0.6,2.1-0.1,4c0.3,1.2,0.7,1.3,1.6,3.4c0.6,1.4,0.9,2.1,1,3c0,0.3,0,0.6,0,0.9l0,0c1.9,2.5,2.6,4.1,2.8,5.2c0,0,0,0,0,0
							c0.6-0.6,1.2-1.2,1.9-1.9c1.8-1.6,3.3-2.5,6.1-4.2c4-2.4,6.1-3.6,9.3-4.9c5.2-2,7.7-3,9.4-3.2c0.5,0,1.8-0.1,2.9-1
							c0.7-0.6,0.8-1.1,1.4-1.8c1-1.2,2.1-1.5,3.1-2c2.5-1.3,2.1-3.8,4.1-4.3c0.2-0.1,0.4-0.1,0.9-0.1c5.8-0.1,9.4,1.6,11.6,0.1
							c0.2-0.1,0.6-0.4,0.9-0.3c0.7,0.2,0.6,1.7,1.2,3.1c1.2,3.1,5.1,4,5.3,4.1c2.2,0.5,2.3-0.5,4.8-0.3c4.1,0.3,5.3,3.2,7.8,2.5
							c0.6-0.2,0.5-0.3,3-2.7c2.3-2.2,2-1.8,4.9-4.5c2.6-2.5,3-3,4.4-3.9c2.1-1.3,3.4-1.5,3.9-1.5c0.6,0,0.8,0,3.6,0.6
							c3.7,0.8,4.6,1,5,1.8c0.4,0.7,0.1,1.2,0.6,1.9c0.6,0.7,1.6,0.7,2.1,0.8c4.7,0.1,7.6-1.7,9.4-0.4c0.3,0.2,0.5,0.4,0.6,0.7
							c0.1-6.2-2.8-14.4-4-17.1c-1.1-2.5-2.1-3.4-3.3-6.8c-0.6-1.8-1.1-3.7-2.8-5.3c-1.1-0.9-2.2-1.4-2.3-1.4c-1.8-0.7-2.5-0.1-3.8-0.7
							c-1.8-0.8-2.5-3-2.8-4.1c-0.6-1.9-0.4-3.5-0.2-4.8c0.1-0.7,0.2-1.5,0.7-2.4c0.6-1.2,1-1.2,1.3-1.9c0.4-1.1-0.4-2-2-4.5
							c-1.8-3-1.4-2.8-3.2-5.5c-1-1.5-2.5-3.7-2.5-3.7c-0.9,0.9-3.7,3.4-4.7,3.8c-2,0.8-3.9,0.4-4.2,0.3c-3.9-0.9-5.9-5-6.2-7.7
							c-0.1-0.8,0-1.7,0-1.7c0.1-2,0.8-3,1-4c0.3-1.3-0.1-2.5-1.3-5.3c-1.9-4.3-3.7-7.8-4.5-9.3c-2.2-4.4-3.4-6.5-3.5-7.3
							c-0.3-1.6-0.4-3.7-1.8-6.2c-0.6-1-0.9-1.5-1.5-1.8c-1.9-1-3.4,1.9-7.3,1.8c-2.7,0-3.8-0.9-5.8-0.2c-2.6,0.9-3.9,3.3-4.7,2.8
							c-0.4-0.2-0.1-0.9-0.3-2.3c-0.2-1.6-1-3.2-1.8-4.2c-1.6-1.8-3.1-1.1-4.2-2.3c-1.3-1.5,0-3.5,0.8-8.3c0.8-4.3,1.5-8.4,0.7-12.7
							c-0.3-1.4-0.8-3-0.2-5c0.6-2,1.7-2.6,1.3-3.5c-0.3-1-1.7-0.9-5.8-2c-2.7-0.8-2.8-1-4.7-1.3c-1.9-0.4-3.3-0.6-4.8-0.3
							c-1.4,0.3-2.6,0.9-3.6,1.7C414,221.1,414.4,220.6,414.8,220.8z'
							/>
							<text id='XMLID_234_' transform='matrix(1 0 0 1 385.0874 320.832)' className='st4 st5 text-map'>
								{t('kab-pekalongan')}
							</text>
						</g>
						<g id='Kabupaten_Batang' className='region' from='home' post-name='kabupaten-batang'>
							<path
								id='XMLID_102_'
								className='st16'
								d='M589,267c-0.3-0.6-0.8-1.3-1.8-2.8c-1.8-2.6-2.6-3.3-2.4-4c0.4-1.1,2.6-0.4,3.9-1.9
							c0.7-0.8,0.7-1.9,0.6-4c-0.1-3.2-0.7-3.4-0.8-6.1c0-2.5,0.5-3.7-0.3-4.4c-0.4-0.3-0.9-0.4-2.1-0.1c-2.4,0.5-4.1,1.6-7.4,3.8
							c-2.6,1.7-3,2-3.9,2.4c-1.9,0.9-3.5,1.3-4.6,1.5c-6,1.3-9,2-10.3,2c-6.2,0.2-6.4-2.4-13.9-2.6c-2.3-0.1-1.9,0.2-8.6,0.5
							c-0.7,0-6.6,0.3-9.6,0.3c-4.2-0.1-9.1-1.5-18.9-4.5c-10.7-3.2-16-4.9-17.3-5.6c-1.1-0.7-3.9-2.6-7.8-2.8c-2.3-0.1-2.2,0.6-4.3,0.5
							c-4.2-0.2-5.5-3-10.8-4.8c-2.8-0.9-2.8-0.2-4.6-1c-3.5-1.6-4.7-4.3-6.1-5.4c0.5,2.8-0.1,10.1-0.3,18.9c-0.1,2.5-0.1,5.3-1.8,8.4
							c0,0-0.6,1.2-1.8,2.6l0,0c0.2,0.3,0.4,0.6,0.7,1.1c1.4,2.5,1.5,4.6,1.8,6.2c0.1,0.8,1.3,3,3.5,7.3c0.8,1.6,2.6,5,4.5,9.3
							c1.3,2.9,1.7,4,1.3,5.3c-0.2,1-0.9,2-1,4c0,0-0.1,0.9,0,1.7c0.2,2.6,2.3,6.8,6.2,7.7c0.3,0.1,2.2,0.5,4.2-0.3c1-0.4,3.8-3,4.7-3.8
							c0,0,1.5,2.1,2.5,3.7c1.8,2.7,1.3,2.5,3.2,5.5c1.6,2.5,2.4,3.4,2,4.5c-0.2,0.7-0.7,0.8-1.3,1.9c-0.4,0.9-0.6,1.7-0.7,2.4
							c-0.2,1.3-0.4,2.9,0.2,4.8c0.3,1.1,1,3.3,2.8,4.1c1.3,0.6,1.9,0,3.8,0.7c0.1,0,1.3,0.5,2.3,1.4c1.7,1.5,2.2,3.4,2.8,5.3
							c1.2,3.5,2.2,4.4,3.3,6.8c1.2,2.6,4.1,10.8,4,17.1c0.5,0.7,0.8,1.5,2,1.9c0.5,0.2,0.9,0.4,1.4,0.3c1.7-0.4,1.7-3.4,3.3-3.8
							c0.6-0.1,0.9,0.3,2,0.9c1.8,0.9,3.5,0.8,5.9,0.6c3.2-0.2,3.1-0.9,5-0.8c0.5,0,1.4,0.1,5.6,2.1c5.4,2.6,6.1,3.5,6.4,4.3
							c0.1,0.3,0.2,0.6,0.2,1.1c1.1-0.5,2-1.2,2-2c0-0.3-0.1-0.7-0.1-1.5c0-0.9,0-1.4,0.3-1.6c0.9-0.9,4.2,2.2,5.8,1.1
							c0.3-0.2,0.4-0.6,0.8-1.5c0.6-1.8,0.7-3.3,0.8-3.4c0.2-3.1,1.8-8.1,2.4-9.8c1.2-3.2,2.1-3.1,5.1-7.8c6.2-9.6,5-14.7,9-19.1
							c0.5-0.6,2-2.1,2-4.1c0-0.3,0-0.5-0.8-3c-0.8-2.8-0.9-3-1.1-3.9c-0.3-1.3-0.5-2.6-0.3-4c0.5-2.2,2.2-3.6,3.6-4.6c3.4-2.6,5-1.8,9-4
							c3.1-1.7,1.9-2,6.3-4.9c4-2.6,6.9-3.7,7.3-6.3c0.1-0.5,0-0.6-0.1-1.6C588.2,273,590.5,270.5,589,267z'
							/>
							<text id='XMLID_101_' transform='matrix(1 0 0 1 496.344 288.7437)' className='st4 st5 text-map'>
								{t('kab-batang')}
							</text>
						</g>
						<g id='Kota_Pekalongan' className='region' from='home' post-name='kota-pekalongan'>
							<path
								id='XMLID_124_'
								className='st24'
								d='M432.2,223.3c0.3,0.9-0.8,1.5-1.3,3.5c-0.6,2-0.1,3.6,0.2,5c0.9,4.3,0.1,8.4-0.7,12.7
							c-0.9,4.8-2.1,6.8-0.8,8.3c1.1,1.3,2.6,0.5,4.2,2.3c0.9,1,1.6,2.5,1.8,4.2c0.2,1.4-0.1,2.1,0.3,2.3c0.8,0.4,2-1.9,4.7-2.8
							c2.1-0.7,3.1,0.1,5.8,0.2c3.9,0.1,5.4-2.8,7.3-1.8c0.3,0.2,0.6,0.4,0.8,0.7c1.2-1.4,1.8-2.6,1.8-2.6c1.6-3.1,1.7-5.9,1.8-8.4
							c0.3-11.2,1.2-19.8-0.4-20.1c-0.1,0-0.1,0-1.3,0.3c-0.9,0.2-1.6,0.3-2.1,0.4c-2,0.3-2.9,0.5-3.3,0.4c-1.3-0.4-1.4-1.6-2.4-1.6
							c-1.2-0.1-1.5,1.6-2.6,1.6c-1.4,0-1.8-2.5-3.6-2.8c-1.3-0.2-2.1,1-2.8,0.6c-0.8-0.5,0.2-2.4-0.5-3.4c-0.9-1.3-4.5-1-7.9,0.2
							C431.8,222.7,432.1,222.9,432.2,223.3z'
							/>
							<text id='XMLID_235_' transform='matrix(1 0 0 1 431.3405 239.8673)' className='st4 st5 text-map'>
								{t('pekalongan')}
							</text>
						</g>
						<g id='Kabupaten_Wonosobo' className='region' from='home' post-name='kabupaten-wonosobo'>
							<path
								id='XMLID_96_'
								className='st24'
								d='M595.5,427.6c-7.8-8-7.1-9.6-15.3-15.7c-2.2-1.6-3.1-2.1-4.2-3.5c-2.2-2.8-1.6-4.7-3.7-8.3
							c-1.9-3.5-3.3-3.2-4.5-5.7c-1-2.1-1.7-5.5,0.5-11.2c-8.2-9.9-10.7-12.7-10.7-12.7c-3.2-3.7-4-4.4-5.2-5.8c0,0-0.7-0.8-1.4-1.7
							c-1.3-1.5-3.4-4.6-5.4-7.4c-0.3,0.7-0.4,1.1-0.7,1.3c-1.5,1.1-4.8-2-5.8-1.1c-0.2,0.2-0.2,0.7-0.3,1.6c0,0.8,0.1,1.2,0.1,1.5
							c0,0.8-1,1.5-2,2c0,0.6,0,1.5,0,2.6c0,2.6,0,4.9-1.1,5.4c-0.5,0.2-0.8-0.1-2-0.4c-1.2-0.2-2-0.1-3.6,0.1c-3.8,0.6-5.7,0.9-6.4,1.1
							c-4.5,1.8-6.9,6-7.6,7.3c-0.6,1.1-1.4,2.7-2,4.5c-0.2,0.5-0.2,1.4-0.8,2.4c-0.5,0.8-0.8,1-1.3,1.6c-0.8,1.2-0.8,2.2-1,3.5
							c-0.2,1.4-0.8,2.6-1.9,4.9c-1.4,2.9-2.1,3.1-3.6,6c-1.7,3.3-0.9,3.1-2.1,5c-2.3,3.6-5.2,4-6.1,7.5c-0.2,0.7,0,0.4-0.3,1.8
							c-0.5,2.9-1.2,3.6-3.9,9.5c0,0.1-1.1,2.4-2.3,3c-0.2,0.1-1.4,0.7-1.9,0.5c0,0-0.1,0-0.1,0c-0.2,0.1,0.6,1,0.8,2
							c0.1,0.6-0.2,0.9-0.5,1.8c-0.4,1.1-0.8,3.3,0.1,4.3c0.4,0.5,0.9,0.4,1.9,0.6c1.2,0.3,2.2,0.8,4.3,1.9c3.7,2,3.6,2.8,5.8,3.3
							c1.3,0.3,1.7,0,2.8,0.5c1.3,0.7,1.4,1.5,2.8,2.5c1.2,0.8,1.4,0.4,3,1.3c0.9,0.5,0.9,0.7,4,3.4c1.5,1.3,1.9,1.6,2,2.3
							c0.1,0.8-0.4,1.1-1.3,2.9c-0.2,0.4-0.3,0.7-0.4,1c1.8,1.6,3.3,3.5,3.6,5.9c0.5,3.6-1.7,7.4-3.3,10.3c-3,5.3-4.9,5-6.3,9
							c-1.6,4.5,0.5,5.6-1,10.7c-1.4,4.7-3.4,4.3-4.7,9c-1.3,4.9,0.7,6.1-1,10c-1.8,4.1-5.3,5.4-4.7,7c0.1,0.2,0.3,0.4,0.5,0.6
							c1.4-1.2,2.4-1.9,3.3-2.5c3.1-2,3.5-1.5,4.8-2.9c1.6-1.8,1.3-3.1,2.9-6.1c1.2-2.3,2.6-3.8,3.4-4.6c4.5-4.8,7.8-3.8,9.1-7.3
							c0.7-1.7,1.1-5.4,1.3-6.1c0.2-1.2,0.3-2.4,1.1-3c0.9-0.6,1.9-0.1,2.8-0.9c0.7-0.6,0.3-1.3,0.8-2c1.2-1.9,5.7,0.8,11.9-0.4
							c2.1-0.4,4.1-1.2,4.8-0.4c0.6,0.8-0.5,2.1,0.3,3c0.5,0.6,1.6,0.7,2.3,0.5c0.6-0.2,1-0.7,1.9-1.6c0.8-1,1.3-1.5,1.6-2.4
							c0.4-1.1,0.2-1.6,0.8-2c0.5-0.4,1.3-0.1,1.8,0c1.2,0.4,1.5,0.9,3,2.1c1,0.8,1.9,1.5,3.1,2c1.4,0.6,1.6,0.3,2.3,0.8
							c1.5,1,0.8,2.5,2.1,4.8c0.5,0.9,0.5,0.5,3.8,4c1.7,1.8,2,2.3,2.3,3c0.6,1.7-0.2,2.2,0.4,3.6c0.6,1.4,1.6,1.2,3.6,3.5
							c1.3,1.5,1.3,1.9,1.9,2c1.3,0.1,2.7-2.3,4.4-5.3c0.7-1.2,0.6-1.2,1-1.8c1.8-2.4,3.5-1.9,4.3-3.4c1.2-2.2-1.9-5.4-0.8-6.5
							c0.3-0.3,0.8-0.3,1.9-0.3c0.9,0,1.7,0,2.6,0.2c-0.5-2.7-0.8-6.3,0.5-10.3c0.7-2.2,1.7-3.7,2.5-7.3c0.6-2.9,0.4-3.9,0.8-7
							c0.7-4.9,1.6-4.6,2.3-9c1-5.9-0.3-7.8,0.7-13c0.3-1.4,0.6-2.6,0.5-4.5c-0.1-1.8-0.6-2.4-0.2-3.5c0.6-1.4,1.9-1.7,2-2.8
							C596.8,428.9,596,428,595.5,427.6z'
							/>
							<text id='XMLID_95_' transform='matrix(1 0 0 1 522.8774 426.034)' className='st4 st5 text-map'>
								{t('wonosobo')}
							</text>
						</g>
						<g id='Kabupatan_Kendal' className='region' from='home' post-name='kabupaten-kendal'>
							<path
								id='XMLID_194_'
								className='st18'
								d='M705,342c-0.1-0.2-0.7-1.6-0.7-1.6c2.2-5.1,2.4-6.2,2.4-6.2c0.5-3.1-0.3-5.9-1-7
								c-0.3-0.5-0.6-1-0.9-1.5c-0.5-0.8-0.7-1-0.8-1.4c0-0.5,0.4-0.9,0.8-1.3c0.8-0.9,1.1-1.8,1.5-2.7c0.3-0.6,0.8-1.9,0.8-3.5
								c0-1.1,0-2.6-1.1-3.5c-0.4-0.3-0.6-0.3-1.6-1c-1.1-0.8-1.1-1-1.2-1.2c-0.1-0.4,0.3-0.7,1.2-1.8c0.4-0.5,0.9-1.2,1.9-2.7
								c0.2-0.3,0.5-0.8,0.7-1.5c0.1-0.4,0.2-0.8-0.1-1.1c-0.3-0.3-1,0.1-1.7,0.2c-1.5,0.2-1.9-1.8-4.2-2.7c-1.9-0.7-2.7-0.4-4.5-1.3
								c-0.4-0.2-1.9-1-3.2-2.5c-1-1.1-1.5-2.2-2.3-4.2c-0.7-1.6-1.1-2.5-1.2-3c-0.4-2.6,1.6-3.6,3-7.3c0.7-2,0.8-3.6,1-6.7
								c0.2-3.6,0.3-5.4-0.3-7c-0.4-1-0.9-1.7-0.7-2.7c0.4-1.2,1.6-1.4,1.8-2.7c0.1-0.8-0.3-0.9-0.3-2c0-1.4,0.7-1.8,0.7-2.5
								c-0.1-2.7-12.5-4.1-16.5-7.7c-2.2-2-2.7-2.5-2.7-2.5c-1.2-1.4-1.5-2-2.3-2.3c-1.3-0.4-1.8,0.6-3.3,0.7c-2.4,0-3-2.6-7.7-5.5
								c-1.8-1.1-1.8-0.8-2.7-1.5c-2.7-2.3-1.7-4.7-3.8-8c-1.7-2.7-4.3-4.1-7-5.5c-2.5-1.3-4.7-1.9-5.8-2.2c-1.5-0.4-4.2-0.9-7.5-0.5
								c-3.4,0.5-5.7,1.8-8.7,3.5c-1.7,1-3.4,2.3-7,5c-4.4,3.4-7,5.5-11.3,7.7c-0.9,0.4-1.3,0.6-1.8,0.8c-4.4,1.6-7.9,0-10.7-0.3
								c-2.8-0.3-5.2,0.6-8,2.4c0,0,0.1,0.1,0.1,0.1c0.8,0.7,0.2,1.9,0.3,4.4c0,2.7,0.7,2.9,0.8,6.1c0,2.1,0,3.2-0.6,4
								c-1.3,1.5-3.5,0.8-3.9,1.9c-0.3,0.7,0.6,1.4,2.4,4c1,1.5,1.5,2.2,1.8,2.8c1.6,3.5-0.8,5.9-0.1,12.1c0.1,1,0.2,1.1,0.1,1.6
								c-0.4,2.6-3.3,3.6-7.3,6.3c-4.4,2.9-3.2,3.2-6.3,4.9c-4,2.2-5.6,1.4-9,4c-1.4,1-3.2,2.4-3.6,4.6c-0.3,1.4,0,2.7,0.3,4
								c0.2,0.9,0.3,1.1,1.1,3.9c0.7,2.5,0.8,2.7,0.8,3c0,2-1.5,3.5-2,4.1c-4,4.4-2.8,9.5-9,19.1c-3,4.7-4,4.5-5.1,7.8
								c-0.6,1.7-2.2,6.6-2.4,9.8c0,0.1-0.1,1.6-0.8,3.4c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0,0c1.5,2.1,3.1,4.3,4.3,6c0.5-0.1,1.1-0.3,1.8-0.5
								c3.2-1,4.8-1.4,5.3-1.6c2.9-1,3.2-1.5,6.9-3.2c4.8-2.1,4.4-1.3,6.4-2.5c1.4-0.8,3.3-2.7,7.1-6.4c1.5-1.5,2.3-2.3,2.7-2.8
								c2.1-2.8,1.5-4.2,3.5-6.5c0.6-0.7,1.6-1.4,3.7-2.9c1.5-1.1,2.5-1.8,3.8-2.5c0.8-0.4,1.5-0.7,5.3-1.7c6.3-1.7,6.8-1.6,8.3-2.3
								c0.8-0.4,1.7-0.8,3.2-1.3c1.4-0.5,1.8-0.4,2.8-0.8c1.6-0.7,2.2-1.6,2.9-2.3c2.4-1.9,4.8,0.6,11.8,0.2c3.9-0.2,5.8-0.4,6.8-0.7
								c1-0.3,1.7-0.3,3-0.3c0.6,0,1,0.1,1.2,0.3c0.1,0.2,0.1,0.6-0.8,1.5c-1.6,1.7-3,1.9-5.5,3.5c-1.7,1.1-1.5,1.2-3,2.1
								c-1.1,0.6-1.3,0.6-3.7,1.7c-2.7,1.3-3.4,1.7-3.3,2c0,0.1,0.1,0.1,0.1,0.1c0.9,0.5,1.8,2.4,6.8,5.2c3.3,1.9,3.6,1.5,7.3,3.7
								c1.7,1,2.6,1.5,3.2,2.1c2.4,2.3,2.1,4.4,4.2,6.6c1.1,1.1,2.2,1.6,4.3,2.6c4.1,1.8,7.9,2.2,11.2,2.5c4.9,0.4,5.4-0.1,7.2,0.8
								c1.7,0.9,3.4,1.7,5,2.8c0.5,0.3,1.2,0.8,2.1,0.7c0.5-0.1,1.1-0.6,2.3-1.6c1-0.8,2-1.7,2-1.8c2.9,3.5,3.5,3.8,3.5,3.8
								c1.8,0.9,3.8,0.3,6.9-0.6c2.8-0.8,2.8-1.3,5.2-1.8c2.5-0.5,2.7,0,5.3-0.5c1.2-0.2,3.2-0.6,5.3-2c1.1-0.7,2.5-1.6,3.5-3.3
								c0.5-0.8,0.7-1.7,1.1-2.9c0.4-1.4,0.6-2.2,0.7-2.8C707.3,346.8,706.7,346.1,705,342z'
							/>
							<text id='XMLID_238_' transform='matrix(1 0 0 1 614.0107 288.744)' className='st4 st5 text-map'>
								{t('kab-kendal')}
							</text>
						</g>
						<g id='Kota_Semarang' className='region' from='home' post-name='kota-semarang'>
							<path
								id='XMLID_90_'
								className='st24'
								d='M754.3,280.3c-0.2-1.6-0.5-2-0.4-3.4c0.1-1.9,0.7-2.2,0.6-3.6c-0.1-1.5-1-1.8-1.9-3.8
								c-1-2.1-1.1-4-1.3-5.4c-0.2-2.6,0.4-2.8,0.1-5.4c-0.2-1.9-0.3-2.9-1-4.1c-1.2-2.1-3.1-2.4-3-3.8c0-0.1,0.4-0.8,1.1-2.2
								c0.5-0.9,0.9-1.6,0.7-1.8c-0.2-0.2-0.8,0.1-0.8,0.1c0,0-1.8,0.3-2.7,1.2c-1.3,1.2-0.1,2.8-1.3,4c-0.8,0.8-2,1-4.2,1.3
								c-1.4,0.2-2,0.1-2.4,0.6c-0.6,0.7,0.1,1.6-0.5,2.7c-0.4,0.8-1.4,1.5-1.9,1.3c-0.6-0.3,0-1.9-0.8-2.4c-0.8-0.5-2.5,0.6-3.3,1.1
								c-1.5,1-1.9,1.7-2.6,1.6c-0.9-0.2-0.9-1.5-1.8-1.6c-0.7-0.1-1.6,0.8-1.9,1.6c-0.5,1.1-0.1,2.1-0.4,2.3c-0.4,0.2-1.2-1.5-1.9-1.3
								c-0.5,0.1-0.3,0.8-1,1.5c-0.6,0.7-1.3,0.5-2.6,1c-1.4,0.5-1.3,0.9-2.4,1.3c-0.2,0.1-3,1.2-5.3-0.1c-0.9-0.5-1-1-2.3-1.6
								c-0.5-0.2-1.2-0.5-2-0.6c-1-0.1-1.2,0.3-1.8,0.1c-1.1-0.4-1.1-1.9-1.8-1.9c-0.4,0-0.7,0.3-0.9,0.6c-0.5,0.6-0.4,1-0.8,1.3
								c-0.3,0.1-0.4-0.1-0.9,0c-0.8,0.1-1,0.7-1.4,0.6c-0.4-0.1-0.5-0.7-1.4-2c-0.6-0.9-0.8-0.9-0.9-0.9c-0.4,0.1-0.3,0.9-0.8,1.4
								c-0.3,0.3-0.8,0.4-1.2,0.3c-0.2,0.5-0.6,1-0.6,2.2c0,1.1,0.5,1.2,0.3,2c-0.2,1.3-1.5,1.4-1.8,2.7c-0.3,0.9,0.3,1.7,0.7,2.7
								c0.7,1.6,0.5,3.4,0.3,7c-0.2,3.1-0.3,4.6-1,6.7c-1.4,3.7-3.4,4.7-3,7.3c0.1,0.5,0.4,1.4,1.2,3c0.9,2,1.3,3,2.3,4.2
								c1.3,1.5,2.7,2.3,3.2,2.5c1.8,0.9,2.6,0.6,4.5,1.3c2.3,0.8,2.7,2.8,4.2,2.7c0.7-0.1,1.3-0.5,1.7-0.2c0.1,0.1,0.1,0.2,0.1,0.4
								c1-0.6,2-1.1,3.5-1.1c1.8,0,2.4,0.8,5.3,1.8c1,0.3,3.4,1.1,6.3,1.2c4.7,0.1,5.7-1.6,8.8-0.6c1.3,0.5,2.1,1.1,3.3,0.8
								c1.2-0.3,1.2-1.1,3-2.5c1.8-1.4,2.4-1,4.3-2.6c0.6-0.5,1.5-1.2,2.3-2.4c1.3-1.9,0.8-2.7,2-4.1c0.4-0.5,1.3-1.5,2.7-1.9
								c1-0.3,1.6-0.2,2.2-0.8c0.5-0.5,0.4-1,0.8-1.9c0.5-1.1,1.3-1.4,1.9-2.1C755.1,286.3,754.6,282.6,754.3,280.3z'
							/>
							<text id='XMLID_89_' transform='matrix(1 0 0 1 710.7872 276.7472)' className='st4 st5 text-map'>
								{t('semarang')}
							</text>
						</g>
						<g id='Kabupaten_Semarang' className='region' from='home' post-name='kabupaten-semarang'>
							<path
								id='XMLID_210_'
								className='st24'
								d='M822.5,466.4c-0.7-1-1.7-1.6-3.8-2.9c-1.2-0.7-1.9-1-2.5-1.9c-0.2-0.3-0.9-1.3-0.9-2.5
								c0.1-1.7,1.6-2.5,1.3-3c-0.3-0.4-1.5,0-2.8,0.4c-2,0.6-2.3,1-3,0.9c-1.2-0.3-1.8-1.8-2.1-2.5c-0.2-0.5-1.1-2.6-0.1-3.6
								c0.6-0.7,1.4-0.3,2-0.9c0.9-0.9,0.3-2.8-0.1-4.6c-0.5-2.5,0.1-1.4-0.6-7c-0.9-7.6-1.9-8.4-1.2-11.4c0.6-2.3,1.2-2,1.8-4.5
								c0.7-3.3-0.4-3.8,0.3-7.5c0.2-1.2,0.6-2.2,0.1-3.3c-0.4-0.9-1-1.1-1.3-2.1c-0.2-1.1,0.3-1.6-0.2-2.1c-0.4-0.4-1.3-0.3-1.8-0.1
								c-0.5,0.2-0.8,0.5-1.2,0.9c-0.6,0.6-0.6,0.9-1.1,1.3c-0.3,0.2-0.7,0.6-1.2,0.5c-0.8-0.1-1-1.5-1.7-3.5c-0.9-2.7-1.3-2.3-1.8-4.3
								c-0.4-1.5-0.4-2.7-0.4-5.3c0-2.3,0.2-2.7,0.5-2.9c0.9-0.7,2.1,0.8,3.6,0.3c0.7-0.2,1-0.8,2.6-4.4c1.2-2.7,0.6-1.5,2.2-5.2
								c0.9-2.2,1.3-3,1.3-4.3c0-0.5,0.1-1.5-0.3-2.7c-0.3-0.9-0.6-1.9-1.5-2.8c-0.4-0.3-0.7-0.6-0.8-1c0-0.5,0.6-0.7,0.6-1.1
								c0-0.7-1.9-1-3.3-1.6c-2.9-1.2-4.5-4-5.4-5.5c-1.7-2.9-2.4-4.7-2.4-4.8c-0.4-1-0.5-1.6-1.1-2c-1.1-0.7-2.2,0.1-4.3,0.5
								c-3.2,0.7-3.3-0.6-8.5-0.4c-2.3,0.1-2.9,0.4-3.9-0.1c-1.4-0.7-1.6-1.8-3.4-2.6c-1.2-0.5-1.5-0.2-2.1-0.8c-1-0.9-1-2.4-1-4
								c0-1.7,0.4-3,0.6-3.6c0.4-1.2,1-2.1,1.4-2.6c0.4-0.5,1.6-2.4,3.6-3.8c3.2-2.2,7-2.2,7-2.4c0-0.1-2.7,0.9-4.5-0.4
								c-1-0.7-0.8-1.4-1.9-1.9c-1-0.5-1.7-0.1-3.4-0.3c-0.8-0.1-1.6-0.3-3.1-0.8c-4-1.2-3.8-1.6-5.6-1.9c-0.1,0-1.7-0.1-5-0.3
								c-1.5-0.1-2.6-0.1-3.6-0.9c-0.8-0.6-1.2-1.2-1.4-1.6c-0.9-1.6-0.8-3.3-0.8-4.4c0.2-2.7,1.2-3.3,0.6-4.5c-0.3-0.7-1-1.1-2.5-2
								c-1.1-0.7-1.5-0.7-3.4-1.6c-2.4-1.2-2.8-1.6-2.9-2.1c0-0.5,0.3-0.8,0.6-1.3c1.6-1.9,2.7-2.6,3.6-3.5c1.5-1.5,2.5-3.8,2-4.4
								c-0.4-0.5-1.5,0.7-3.4,0.5c-2.5-0.2-4.4-2.4-5.6-3.8c-0.5-0.6-0.9-1.1-1-1.3c-0.8-1.1-1.5-2.4-2-3.5c-0.5,0.5-0.9,0.9-1.3,1.2
								c-1.8,1.5-2.4,1.1-4.3,2.6c-1.8,1.4-1.8,2.2-3,2.5c-1.2,0.3-1.9-0.4-3.3-0.8c-3.1-1.1-4,0.7-8.8,0.6c-2.9-0.1-5.4-0.9-6.3-1.2
								c-2.9-0.9-3.5-1.7-5.3-1.8c-1.5,0-2.6,0.5-3.5,1.1c0,0,0,0,0,0c0,0.2,0,0.5-0.1,0.7c-0.1,0.7-0.5,1.2-0.7,1.5
								c-1,1.5-1.5,2.2-1.9,2.7c-0.9,1.1-1.2,1.3-1.2,1.8c0,0.1,0.1,0.4,1.2,1.2c1,0.7,1.2,0.7,1.6,1c1,0.9,1.1,2.4,1.1,3.5
								c0,1.6-0.5,2.9-0.8,3.5c-0.4,0.9-0.7,1.7-1.5,2.7c-0.4,0.5-0.8,0.8-0.8,1.3c0,0.4,0.2,0.7,0.8,1.4c0.3,0.5,0.6,1,0.9,1.5
								c0.7,1.1,1.5,3.9,1,7c0,0-0.2,1.1-2.4,6.2c0,0,0.6,1.4,0.7,1.6c1.8,4.2,2.3,4.8,2.3,6.3c0,0.7-0.2,1.4-0.7,2.8
								c-0.4,1.2-0.6,2.1-1.1,2.9c-1,1.7-2.4,2.6-3.5,3.3c-2.2,1.4-4.1,1.8-5.3,2c-2.6,0.5-2.9,0-5.3,0.5c-2.4,0.5-2.3,1-5.2,1.8
								c-3.2,0.9-5.1,1.5-6.9,0.6c0,0-0.6-0.3-3.5-3.8c0,0-1,0.9-2,1.8c-1.2,1-1.8,1.5-2.3,1.6c-0.9,0.1-1.5-0.3-2.1-0.7
								c-0.3-0.2-0.6-0.3-0.8-0.5c-0.6,0.6-1.2,1.1-1.8,1.4c-0.3,0.1-2,0.9-2,2c0,1.2,2.1,1.6,5.4,4.3c1.8,1.4,2.6,2.1,2.9,3.3
								c0.3,1.4-0.6,2-0.6,4.4c0,0.6,0,1.9,0.6,3.4c0.9,2.1,2.6,3.3,3.4,3.9c1.7,1.2,2.3,0.8,3.8,2c1.5,1.2,1.5,1.9,2.6,3.1
								c2.2,2.4,4,1.5,7.5,3.8c1,0.6,4.6,3,4.3,5c-0.4,2-4.4,1.6-5.3,3.8c-1,2.6,2.9,7.7,8,10.3c7.4,3.7,13.8,0.4,16.5,4.5
								c1,1.6,1,3.5,3.1,5.1c1.2,0.9,2.1,1.1,3.3,2.5c1.1,1.4,0.9,2.1,1.8,2.5c1.3,0.6,2.2-1,4.3-0.8c1.4,0.2,2.8,1.1,3.4,2.4
								c1,2-0.2,4.3-0.5,4.8c-0.7,1.4-1.5,1.6-1.6,2.6c-0.1,1.1,0.6,1.8,1.5,3.1c0.9,1.3,1.6,2.8,2.9,5.8c0.4,0.9,0.4,1,2.4,5.8
								c0.9,2.3,1.4,3.4,2.3,3.5c0.6,0.1,0.8-0.5,2.3-1.5c1.7-1.2,2.1-0.9,3.6-2.1c0.8-0.6,1.5-1.2,2.1-2.3c0.6-1.1,0.4-1.5,0.8-2.3
								c1-2.1,4.5-3,7.1-2.6c0.6,0.1,1.6,0.5,3.6,1.4c3.3,1.4,4.9,2.1,5.9,2.8c3.2,2.1,3,3.6,5.5,4.3c0.8,0.2,1.7,0.3,3.6,0.9
								c0.9,0.3,1.5,0.4,2.3,0.8c1.8,0.7,3.6,1.4,4.9,3c0.9,1.1,1,1.9,1.8,2c0.8,0.1,1.5-0.6,2.1-1.3c1.3-1.3,1.2-2.2,2.3-3
								c0,0,0.9-0.7,2.3-0.9c2.9-0.3,6.3,2.9,6.4,5.8c0,1.6-1,3.4-2,5.3c-0.8,1.4-1.2,1.7-1.1,2.4c0.2,1.7,3,3.3,5.5,3.5
								c2.1,0.2,2.6-0.6,4.6-0.1c1.3,0.3,1.8,0.7,3.4,1.6c0.7,0.4,0.6,0.3,5.9,2.5c1.8,0.7,3.9,1.7,6.9,2.6c2.2,0.7,2.8,0.8,3.5,0.6
								c2.6-0.7,3.5-4.1,3.6-4.5C823.1,471,823.8,468.4,822.5,466.4z M758,421.3c-1.7,2.6-5.2,4.7-5.3,4.8c0,0-3.3-2.3-6-4.5
								c-2.4-1.9-4-3.2-5.8-5.3c-2.1-2.4-3.6-4.1-4.3-6.8c-0.6-2.3-0.2-4.2,0.5-8c0.8-4.2,1.2-6.3,2.8-7.3c0.4-0.2,0.8-0.4,6.5,0
								c4.1,0.3,4.8,0.4,5.8,1c1.5,1,2.3,2.4,3,3.8c1.1,2.1,1.1,3.3,1.8,6c1.3,5.5,2.2,5.1,2.5,8.3C759.6,414.9,759.9,418.4,758,421.3z'
							/>
							<text id='XMLID_258_' transform='matrix(1 0 0 1 699.2108 372.1653)' className='st4 st5 text-map'>
								{t('kab-semarang')}
							</text>
						</g>
						<g id='Kabupaten_Magelang' className='region' from='home' post-name='kabupaten-magelang'>
							<path
								id='XMLID_77_'
								className='st24'
								d='M743,493.3c-0.8-1.4-2-1.3-4.5-3c-1.6-1.1-1.8-1.6-3.2-2.7c-5-4.1-11.7-5.5-11.7-5.5
								c0,0,0.2-1.5,0.5-2.3c0.4-1,1.1-1.4,3-2.7c2.2-1.4,3.3-2.2,3.7-2.5c1.9-1.6,2.8-3.5,3.5-4.8c0.9-1.7,0.6-1.7,1.7-4.2
								c1.5-3.4,2.1-3.4,2.5-5.2c0.5-1.9,0.2-3.5-0.2-5.7c-0.2-1.1-0.4-2-0.6-2.9c-0.7,0.6-1,0.9-1.4,0.9c-0.8-0.1-1.3-1.2-2.3-3.5
								c-2-4.7-2-4.9-2.4-5.8c-1.3-3-2-4.5-2.9-5.8c-0.9-1.3-1.6-2-1.5-3.1c0.1-1.1,0.9-1.2,1.6-2.6c0.3-0.5,1.5-2.8,0.5-4.8
								c-0.6-1.3-2-2.2-3.4-2.4c-1.4-0.2-2.2,0.5-3.1,0.8c-1,0.5-1.9,0.8-2.8,0.9c-2,0.3-4,0.3-4,0.3c-1.4,0-2.4-0.1-3.5-0.2
								c-6.7-0.6-9-0.5-9.3-1.7c-0.2-0.6,0.4-1.2,0-1.7c-0.4-0.5-1.4-0.2-2.5,0c-3,0.4-3.6-0.8-5.8-0.3c-1.2,0.2-1.3,0.6-3.8,2.2
								c-1.6,1-3.5,2.1-5.8,3c-3.5,1.3-3.9,0.4-7.8,1.8c-2.2,0.8-2.6,1.3-3.8,1.2c-1-0.1-1.6-0.5-4.8-2.8c-2.2-1.6-3.3-2.4-3.8-2.3
								c-1.2,0.1-1.6,1.1-5,4.5c-1.9,1.8-2.5,2.2-3.2,2.2c-1.3-0.1-1.6-1.4-3.3-2c-1.2-0.4-1.6,0.1-4.2,0.3c-3.1,0.3-3.1-0.2-5.7,0.2
								c-2.5,0.4-4.7,1.2-4.7,1.2c-1.7,0.7-2.3,1.1-3.7,1.2c-1.5,0-2.6-0.5-3-0.7c-1.1-0.5-1.8-1.2-2.2-1.5c-2.6-2.2-6.6-2.2-8.8-2.2
								c-1.5,0-2.8,0.3-5.5,0.8c-6.2,1.3-6.6,2.2-9.2,2c-3.2-0.2-3.6-1.7-7.2-2.7c-1-0.3-2-0.4-3-0.5c0.1,0.3,0.2,0.6,0.2,1
								c-0.1,1.2-1.4,1.5-2,2.8c-0.5,1.1,0,1.7,0.2,3.5c0.1,1.9-0.2,3.1-0.5,4.5c-1,5.2,0.4,7.1-0.7,13c-0.8,4.4-1.6,4.1-2.3,9
								c-0.5,3.1-0.2,4.1-0.8,7c-0.8,3.6-1.8,5.1-2.5,7.3c-1.3,3.9-1,7.5-0.5,10.3h0c0.4,0.1,0.8,0.2,1.2,0.4c1.8,0.8,2.6,2.3,3.1,3.3
								c0.4,0.7,1.3,2.3,1.3,4.3c0,1.1-0.3,1.8-0.1,3.3c0.2,1.4,0.6,1.9,0.8,2c0.6,0.6,1.4,0.6,2.4,0.6c2.7,0.1,4.1,0.2,5.1,0.5
								c2,0.7,2,1.7,4,2.1c1.4,0.3,1.7-0.1,2.9,0.1c1.1,0.2,2.1,0.8,4.8,3.5c2.6,2.7,3.9,4,5.1,6.1c1.8,2.9,1.4,3.6,3.1,5.5
								c1.5,1.7,2.4,1.8,2.8,3.1c0.5,1.9-0.9,3.1,0,4.8c0.3,0.5,0.4,0.3,1.8,1.8c1.1,1.2,1.7,1.8,1.9,2.3c0.2,0.5,0.1,1,0,1.6
								c1.6,0.5,3.4,0.7,5,0.2c0,0,2.5-0.8,4.6-0.5s2.1,1.3,4.1,1.8c2.6,0.6,3.1-0.7,6.9-0.3c1.1,0.1,1.1,0.2,3.1,0.5
								c0.8,0.1,2.2,0.3,4.1,0.4c1.9,0.1,4.1,0.2,6.8,0c3-0.2,4.5-0.7,6.4,0.3c0.8,0.4,1.2,0.9,1.3,1c1.4,1.9-0.1,5-0.1,5.1
								c-0.7,1.5-1.4,1.4-1.9,2.9c-0.4,1.3-0.3,2.4-0.3,3.1c0.1,0.6,0.3,2,1.1,3.5c0.6,1,0.9,1.5,1.5,1.8c0.9,0.4,1.5-0.3,3.1-0.6
								c0.3-0.1,0.6-0.1,4,0.1c3.2,0.2,3.9,0.3,4.6-0.3c0.4-0.4,0.3-0.5,1.3-1.8c0.8-1,1.1-1.1,1.5-1.8c0.7-1,0.4-1.3,1.1-2.8
								c1-2.2,2-2.5,2.3-4.3c0.1-0.8-0.1-0.9,0.1-1.4c0.5-1.2,2.1-1.3,3.8-1.9c1.1-0.4,1.1-0.7,4.8-3.5c2.8-2.2,2.5-1.8,5-3.7
								c4.1-3.1,4-3.5,6.3-5c3.5-2.3,3.6-1.5,5.7-3.2c2.9-2.4,2.7-4.1,5.8-6.2c2.1-1.4,2.3-0.7,4-2c3-2.2,2.3-4.1,5.3-7.5
								c1.5-1.7,3.4-3.8,6.3-4.7c1.6-0.4,2.9-0.4,5-1.5c1.1-0.6,2-1.1,2.7-2.2C743.7,496.9,743.9,494.8,743,493.3z M657,480.5
								c-0.3,0.4-1,1.3-2.4,1.9c-2.2,0.9-4.2,0-5.6-0.6c-1.4-0.6-2.7-1.2-3.1-2.4c-0.4-1.1,0.4-1.4,0.1-3.4c-0.1-1-0.4-1.2-0.8-3.1
								c-0.2-1.1-0.3-1.7-0.3-2c0.4-1.4,2.4-2.1,3.8-2.5c1.1-0.3,1.2-0.2,3.5-0.6c3-0.6,3.7-0.9,4.1-0.5c0.6,0.6-0.3,1.7,0.3,4
								c0.2,0.7,0.2,0.6,0.8,2.1c0.6,1.8,0.9,2.7,0.9,3.6C658.2,478.9,657.2,480.2,657,480.5z'
							/>
							<text id='XMLID_76_' transform='matrix(1 0 0 1 633.538 509.517)' className='st4 st5 text-map'>
								{t('kab-magelang')}
							</text>
						</g>
						<g id='Kabupaten_Temanggung' className='region' from='home' post-name='kabupaten-temanggung'>
							<path
								id='XMLID_239_'
								className='st21'
								d='M552.5,364.8c1.3,1.4,2,2.2,5.2,5.8c0,0,2.4,2.8,10.7,12.7c-2.2,5.7-1.5,9.1-0.5,11.2
								c1.2,2.5,2.6,2.2,4.5,5.7c2,3.6,1.5,5.5,3.7,8.3c1.1,1.4,1.9,1.9,4.2,3.5c8.3,6.1,7.5,7.7,15.3,15.7c0.3,0.3,0.7,0.8,1,1.4
								c1,0.1,2,0.2,3,0.5c3.6,0.9,4,2.5,7.2,2.7c2.5,0.2,3-0.7,9.2-2c2.7-0.5,4-0.8,5.5-0.8c2.2,0,6.3-0.1,8.8,2.2c0.4,0.3,1,1,2.2,1.5
								c0.4,0.2,1.5,0.7,3,0.7c1.4,0,1.9-0.5,3.7-1.2c0,0,2.1-0.8,4.7-1.2c2.6-0.4,2.6,0.2,5.7-0.2c2.6-0.3,3-0.7,4.2-0.3
								c1.8,0.6,2,1.9,3.3,2c0.7,0,1.3-0.3,3.2-2.2c3.4-3.4,3.8-4.4,5-4.5c0.5,0,1.6,0.8,3.8,2.3c3.2,2.3,3.8,2.7,4.8,2.8
								c1.2,0.1,1.7-0.4,3.8-1.2c3.9-1.4,4.3-0.5,7.8-1.8c2.3-0.9,4.3-2,5.8-3c2.5-1.5,2.6-1.9,3.8-2.2c2.3-0.4,2.9,0.8,5.8,0.3
								c1.1-0.2,2.1-0.5,2.5,0c0.4,0.5-0.2,1,0,1.7c0.4,1.1,2.6,1.1,9.3,1.7c1.1,0.1,2.1,0.2,3.5,0.2c0,0,2,0,4-0.3
								c0.9-0.1,1.8-0.5,2.8-0.9c-0.4,0.1-0.8,0.2-1.2,0c-0.8-0.4-0.7-1.1-1.8-2.5c-1.1-1.4-2.1-1.6-3.3-2.5c-2.1-1.7-2.1-3.6-3.1-5.1
								c-2.7-4.1-9.1-0.8-16.5-4.5c-5.1-2.6-9-7.7-8-10.3c0.8-2.2,4.9-1.7,5.3-3.8c0.4-2-3.3-4.4-4.3-5c-3.5-2.3-5.3-1.4-7.5-3.8
								c-1.1-1.2-1.1-2-2.6-3.1c-1.5-1.2-2.1-0.8-3.8-2c-0.8-0.6-2.5-1.7-3.4-3.9c-0.6-1.5-0.6-2.8-0.6-3.4c0-2.4,0.9-2.9,0.6-4.4
								c-0.2-1.1-1.1-1.8-2.9-3.3c-3.3-2.6-5.4-3.1-5.4-4.3c0-1.1,1.7-1.9,2-2c0.6-0.3,1.2-0.8,1.8-1.4c-1.4-0.8-2.8-1.5-4.2-2.3
								c-1.8-1-2.3-0.4-7.2-0.8c-3.3-0.3-7.1-0.7-11.2-2.5c-2.2-1-3.3-1.5-4.3-2.6c-2.1-2.2-1.7-4.3-4.2-6.6c-0.6-0.6-1.5-1.1-3.2-2.1
								c-3.7-2.2-4.1-1.8-7.3-3.7c-5-2.9-5.9-4.7-6.8-5.2c0,0-0.1,0-0.1-0.1c-0.1-0.3,0.6-0.7,3.3-2c2.4-1.1,2.6-1.1,3.7-1.7
								c1.5-0.8,1.3-1,3-2.1c2.5-1.6,3.9-1.8,5.5-3.5c0.8-0.9,0.9-1.3,0.8-1.5c-0.1-0.3-0.6-0.3-1.2-0.3c-1.3,0-2-0.1-3,0.3
								c-1,0.3-2.9,0.4-6.8,0.7c-7,0.4-9.4-2.1-11.8-0.2c-0.8,0.6-1.3,1.5-2.9,2.3c-0.9,0.4-1.4,0.4-2.8,0.8c-1.5,0.5-2.4,1-3.2,1.3
								c-1.6,0.7-2,0.7-8.3,2.3c-3.8,1-4.5,1.2-5.3,1.7c-1.3,0.7-2.3,1.4-3.8,2.5c-2,1.5-3.1,2.2-3.7,2.9c-2,2.3-1.4,3.7-3.5,6.5
								c-0.4,0.5-1.1,1.2-2.7,2.8c-3.8,3.7-5.7,5.6-7.1,6.4c-2,1.2-1.6,0.4-6.4,2.5c-3.7,1.6-4,2.2-6.9,3.2c-0.4,0.1-2,0.6-5.3,1.6
								c-0.7,0.2-1.3,0.4-1.8,0.5c0.4,0.5,0.8,1,1.1,1.4C551.8,364,552.5,364.8,552.5,364.8z'
							/>
							<text id='XMLID_259_' transform='matrix(1 0 0 1 576.6938 388.1876)' className='st4 st5 text-map'>
								{t('kab-temanggung')}
							</text>
						</g>
						<g id='Kota_Salatiga' className='region' from='home' post-name='kota-salatiga'>
							<path
								id='XMLID_71_'
								className='st24'
								d='M757,405c-0.6-2.7-0.7-3.9-1.8-6c-0.7-1.4-1.5-2.8-3-3.8c-0.9-0.6-1.7-0.7-5.8-1
								c-5.7-0.4-6.1-0.2-6.5,0c-1.6,1-2,3.1-2.8,7.3c-0.7,3.8-1.1,5.7-0.5,8c0.7,2.6,2.1,4.3,4.3,6.8c1.8,2,3.4,3.3,5.8,5.3
								c2.7,2.2,6,4.5,6,4.5c0,0,3.5-2.1,5.3-4.8c2-2.9,1.6-6.4,1.5-8C759.2,410.2,758.3,410.6,757,405z'
							/>
							<text id='XMLID_70_' transform='matrix(1 0 0 1 736.5719 407.332)' className='st4 st5 text-map'>
								{t('salatiga')}
							</text>
						</g>
						<g id='Kota_Magelang' className='region' from='home' post-name='kota-magelang'>
							<path
								id='XMLID_220_'
								className='st24'
								d='M656.6,471.3c-0.5-2.3,0.4-3.4-0.3-4c-0.5-0.4-1.1-0.1-4.1,0.5c-2.3,0.4-2.4,0.3-3.5,0.6
								c-1.3,0.4-3.4,1.1-3.8,2.5c-0.1,0.3,0,0.9,0.3,2c0.4,2,0.6,2.1,0.8,3.1c0.2,1.9-0.5,2.3-0.1,3.4c0.4,1.2,1.7,1.8,3.1,2.4
								c1.4,0.6,3.5,1.5,5.6,0.6c1.3-0.5,2.1-1.5,2.4-1.9c0.2-0.3,1.2-1.6,1.3-3.5c0-0.9-0.3-1.8-0.9-3.6
								C656.8,471.9,656.7,472,656.6,471.3z'
							/>
							<text id='XMLID_260_' transform='matrix(1 0 0 1 641.6485 467.093)' className='st4 st5 text-map'>
								{t('magelang')}
							</text>
						</g>
						<g id='Kabupaten_Demak' className='region' from='home' post-name='kabupaten-demak'>
							<path
								id='XMLID_65_'
								className='st22'
								d='M876.2,232.8c-0.1-1.9-0.3-1.2-0.9-5.1c-0.1-0.6-0.2-1.4-0.8-2.1c-0.2-0.2-0.8-0.9-1.6-0.9
								c-0.9,0-1.3,0.9-1.9,0.8c-0.3-0.1-0.4-0.5-0.5-0.8c-0.4-1.5,0-2.9-0.1-3.8c-0.1-1.4-0.3-3-1-4.9c-0.5-1.4-0.8-2.2-1.6-3.1
								c-1.1-1.2-2.5-1.7-3.6-2.1c-1.2-0.4-2.7-0.7-4.3-1.5c-0.4-0.2-0.9-0.4-1.3-1c-0.4-0.6-0.2-1-0.5-1.3c-0.6-0.5-2.1,1-4.3,1.6
								c-2.8,0.9-3.4-0.7-6.9,0.1c-1.1,0.3-1.9,0.7-3.6,0.9c-0.7,0.1-1.4,0.1-2.6,0.1c-2.9,0-4.3,0-5.1-0.3c-3.6-1-2.5-5.4-7-8.3
								c-1.5-1-2.4-0.9-4.9-2.6c-1.6-1.1-2.5-1.7-3.3-2.9c-0.8-1.2-0.7-1.9-1.9-3.6c-1-1.5-1.2-1.3-1.6-2.1c-1.1-2.2,0.3-3.7-0.3-6.5
								c-0.2-1.1-0.5-2.7-1.8-3.6c-2.4-1.8-6.3,0.2-10.3-0.6c-1.1-0.2-1.8-0.6-2.3-0.9c-1-0.6-1.2-1.1-1.9-1.6c-2-1.8-3.5-0.8-5.1-2.3
								c-1.5-1.4-1.1-3.2-1.9-3.4c-1.3-0.2-1.7,4.1-4.9,5.4c-1.4,0.6-1.5-0.2-4.6,0.3c-2.8,0.4-3.7,1.1-4.8,0.5c-1.1-0.7-0.6-1.8-1.9-2.8
								c-1.2-0.9-2-0.1-3.9-1c-1.6-0.7-2.2-1.9-2.6-1.6c-0.4,0.2,0.2,1.1-0.1,2.5c-0.3,1.2-1.2,1.9-1.4,2.1c-1,0.8-1.5,0.6-1.9,1.3
								c-0.4,0.8,0.3,1.4,0.5,2.9c0.2,2-0.9,2.4-0.8,4.1c0.1,0.7,0.8,1.7,2.3,3.8c1.7,2.4,1.8,2.1,2.6,3.5c0.9,1.5,1,2.2,1.6,2.4
								c1,0.2,1.7-1.5,2.9-1.4c0.8,0.1,1.2,0.9,1.9,2c0.6,1,1.5,2.6,1.5,5c0,1.7-0.5,2.9-0.9,3.9c-0.7,1.7-1.1,1.9-2.9,4.5
								c-2.2,3.3-2.9,5.2-4,5.1c-0.7-0.1-0.7-0.8-1.4-0.9c-1.3-0.1-1.5,3.1-3.5,3.4c-1.5,0.2-2.5-0.9-4.9-0.5c-0.2,0-1,0.2-2,0.6
								c-0.8,0.4-2.5,1.2-2.5,2.3c0,0.8,1,0.9,1.3,2.3c0.1,0.7-0.1,0.8,0,1.8c0,0.2,0.1,1.1,0.5,1.9c0.6,1.3,1.5,1.4,2,2.1
								c0.9,1.5-0.7,4.1-1.8,5.6c-2.2,3.5-3.6,3.5-5.9,7c-1.3,2-1,2.2-2.4,4.3c-1,1.5-2,2.7-2.9,3.6c0,0,0.1,0,0.1,0
								c0.2,0.2-0.2,0.8-0.7,1.8c-0.7,1.3-1.1,2-1.1,2.2c-0.1,1.3,1.8,1.7,3,3.8c0.7,1.2,0.8,2.2,1,4.1c0.3,2.6-0.3,2.9-0.1,5.4
								c0.1,1.4,0.2,3.3,1.3,5.4c1,2,1.8,2.2,1.9,3.8c0.1,1.3-0.5,1.7-0.6,3.6c-0.1,1.4,0.2,1.8,0.4,3.4c0.3,2.4,0.8,6-1.1,8
								c-0.7,0.7-1.4,1-1.9,2.1c-0.4,0.9-0.2,1.4-0.8,1.9c-0.6,0.6-1.2,0.4-2.2,0.8c-1.4,0.5-2.2,1.4-2.7,1.9c-1.2,1.4-0.7,2.2-2,4.1
								c-0.3,0.5-0.6,0.9-1,1.2c0,0,0,0,0,0c0.5,1.2,1.2,2.4,2,3.5c0.1,0.2,0.5,0.7,1,1.3c1.2,1.3,3.2,3.5,5.6,3.8c1.9,0.2,2.9-1,3.4-0.5
								c0.5,0.6-0.5,2.9-2,4.4c-0.9,0.9-2,1.6-3.6,3.5c-0.4,0.4-0.7,0.8-0.6,1.3c0,0.5,0.5,1,2.9,2.1c1.9,0.9,2.3,1,3.4,1.6
								c1.5,0.9,2.2,1.3,2.5,2c0.5,1.2-0.4,1.8-0.6,4.5c-0.1,1-0.2,2.7,0.8,4.4c0.2,0.4,0.6,1.1,1.4,1.6c1,0.8,2.1,0.8,3.6,0.9
								c3.3,0.2,4.9,0.2,5,0.3c1.8,0.3,1.6,0.7,5.6,1.9c1.5,0.5,2.3,0.7,3.1,0.8c1.7,0.1,2.4-0.3,3.4,0.3c1,0.5,0.9,1.2,1.9,1.9
								c1.8,1.3,4.5,0.3,4.5,0.4c0,0.1,1,0.7,2.1-0.3c0.3-0.3,0.8-0.8,1.1-1.1c1.2-1.4,1.5-2.5,2.8-4.5c1.8-2.8,2.4-3.6,2.2-4.7
								c-0.1-0.6-0.4-0.9-2.3-3c-3-3.3-3.3-3.7-3.7-4.3c-0.3-0.5-1.6-3.3-0.5-4.8c0.7-0.9,1.8-0.9,2-1.8c0.2-0.8-0.6-1.1-0.7-2
								c-0.1-1.3,1.6-2.4,2.7-3.2c2.9-1.9,4.2-1.3,5.8-2.8c0.8-0.8,0.6-1,2-3.8c1.7-3.4,2.6-5.2,4.3-7c2.2-2.2,3.5-2.1,4-3.8
								c0.4-1.4-0.3-1.6-0.5-4.7c-0.2-2.9,0.5-2.9,0.3-6c-0.1-1.7-0.3-2.4,0.2-3c1-1.2,3.3-0.7,3.7-0.7c1.5,0.3,2.5,1.1,3,1.5
								c1.5,1.3,2.2,2.8,3,4.5c1.2,2.8,0.7,3.2,1.7,4.3c0.9,1.1,1.5,1,4.7,2.7c3.1,1.6,2.7,1.8,4.7,2.7c3.2,1.4,3.8,0.7,6,2
								c2.2,1.3,1.8,2.1,4.2,3.7c1.1,0.7,3.1,2,5.5,1.8c1.2-0.1,1.6-0.5,5.2-2.7c1.4-0.8,2.6-1.6,6.8-4.2c2.2-1.4,3.4-2.1,3.7-3.5
								c0.2-0.9,0-1.8-0.2-2.5c-0.3-1.4-0.8-1.6-1.3-3.2c-0.4-1.2-0.9-2.5-0.7-3.8c0.2-1.3,0.9-1.5,1.7-2.8c0.9-1.6,0.8-3.9,0.7-8.5
								c-0.1-2-0.2-3.3,0.7-4c0.7-0.7,1.6-0.4,2.2-1.2c0.6-0.8,0.1-1.6,0.2-2.8c0.2-1.8,1.5-2.5,3.2-4.8c0.3-0.4,1-1.8,2.3-4.5
								c2.4-4.7,3.6-7.2,4-8.8c0.3-1.2,0.4-1.8,0.8-2.8c1-2.2,2-2.4,2.7-4.2C876.3,236,876.3,234.9,876.2,232.8z'
							/>
							<text id='XMLID_64_' transform='matrix(1 0 0 1 776.5106 244.1658)' className='st4 st5 text-map'>
								{t('kab-demak')}
							</text>
						</g>
						<g id='Kabupaten_Jepara' className='region' from='home' post-name='kabupaten-jepara'>
							<path
								id='XMLID_224_'
								className='st9'
								d='M933.3,61c0.8-0.9,1-1.1,0.9-1.2c-0.2-0.3-2,0.5-4.7,0.5c-1,0-1.8-0.1-2.2-0.7
								c-0.3-0.4-0.1-0.7-0.4-1.2c-0.4-0.5-1.2-0.6-2.9-0.8c-1.8-0.2-2.8-0.3-3-0.3c-1.7,0.1-1.7,0.4-3.2,0.5c-2.1,0.1-3.9-0.6-4.7-0.9
								c-1.7-0.7-1.5-1.1-2.4-1.3c-2-0.4-2.4,1.6-5.6,1.8c-1.1,0.1-2.2-0.1-3.8,0.3c-0.6,0.1-1,0.3-1.7,0.3c-1-0.1-1.2-0.5-2-0.3
								c-0.5,0.1-0.9,0.4-1.4,0.8c-0.4,0.3-1,0.8-1.3,1.2c-0.5,0.4-0.6,0.6-0.8,0.6c-0.4,0-0.6-0.5-0.9-0.8c-0.7-0.9-1.3-0.5-3.1-1.4
								c-0.6-0.3-1.5-0.8-2.4-1.8c-0.5-0.5-1-1.2-1.1-1.3c-0.9-1.2-1-1.6-1.3-1.6c-0.7,0-0.6,2.3-2.3,3.2c-0.6,0.4-0.8,0.1-1.9,0.4
								c-0.3,0.1-1.4,0.4-3.3,2c-2.1,1.7-1.7,2.1-3.3,3.2c-1.5,1-2.9,1.4-3.8,1.7c-1.2,0.3-2.3,0.4-4.4,0.5c-1.1,0.1-2.3,0.1-4,0
								c-1.6-0.1-1.7-0.3-2.3-0.3c-2,0-3.1,1.5-5.2,3.1c-0.8,0.6-4.5,3.5-9.1,4c-1.6,0.2-2,0-4.7,0.2c-1.7,0.1-2.5,0.3-3.6-0.1
								c-0.4-0.1-0.6-0.2-0.8-0.2c-0.9,0.2-0.7,2-1.5,3.8c-0.6,1.2-1.4,2.9-2.8,4.6c-0.9,1.2-1.8,1.9-3.7,3.5c-3,2.5-3.5,2.5-4.6,4
								c-0.7,1-1.5,2.5-1.9,3.3c-1.1,2-1.2,2.5-1.8,2.8c-0.3,0.1-1.5-0.1-3.8-0.7c-0.7-0.2-1.4-0.3-2.2,0c-0.1,0.1-0.6,0.2-0.8,0.6
								c-0.3,0.4-0.6,1.4,2.1,7.2c0.4,0.9,0.8,1.7,1,2.8c0.1,0.5,0.2,1.5,0,2.8c-0.1,0.4-0.3,1.2-0.9,2.8c-0.6,1.8-0.8,2.1-1.2,2.6
								c-0.2,0.2-0.7,0.8-1.6,1.1c-1,0.4-1.4,0-2.7,0.3c-0.8,0.2-1.4,0.4-1.8,0.9c-0.3,0.5-0.1,0.9-0.3,2.3c-0.1,0.5-0.2,0.9-0.4,1.8
								c-0.6,2-0.9,2.3-0.7,2.8c0.3,0.5,0.7,0.3,1.8,1.2c0.4,0.3,1,0.8,1.3,1.5c0.3,0.8,0.4,1.9-0.1,2.2c-0.6,0.3-1.6-1.4-2.4-1.1
								c-0.3,0.1-0.3,0.4-0.7,1.6c-0.6,2-0.7,2-0.8,2.8c-0.2,1.3-0.3,1.9,0.1,2.5c0.4,0.7,0.9,0.3,2.3,1.3c0.6,0.4,0.9,0.8,1.5,1.4
								c0.6,0.7,0.7,0.9,0.7,1c0,0.6-1.1,1.3-2.1,1.3c-0.8,0-1.1-0.5-1.8-0.3c-0.5,0.1-0.8,0.6-1.1,1c-1.1,1.7-1.7,2.6-2,3.3
								c-0.8,1.7-1.2,2.6-1.4,3.6c-0.3,1.4-0.2,2.4-0.1,3.3c0.2,1.9,0.6,2.5,1.2,4.9c0.6,2.3,0.8,3.4,0.8,4.3c-0.2,2.6-2.1,4.5-3.7,6.3
								c-1.2,1.3-1.8,1.6-2.8,3.1c-0.8,1.1-0.8,1.5-1.6,2.4c-1,1.2-2.1,1.8-2.7,2.3c-0.4,0.4-0.6,0.8-0.7,1.4c0.2-0.2,0.4-0.3,0.6-0.2
								c0.8,0.1,0.4,2,1.9,3.4c1.6,1.5,3.1,0.5,5.1,2.3c0.6,0.6,0.9,1,1.9,1.6c0.4,0.3,1.2,0.6,2.3,0.9c3.9,0.9,7.9-1.2,10.3,0.6
								c1.2,0.9,1.5,2.5,1.8,3.6c0.6,2.8-0.8,4.3,0.3,6.5c0.4,0.9,0.6,0.6,1.6,2.1c1.1,1.8,1.1,2.4,1.9,3.6c0.8,1.2,1.7,1.8,3.3,2.9
								c2.5,1.7,3.3,1.7,4.9,2.6c4.5,2.9,3.4,7.2,7,8.3c0.8,0.2,2.3,0.2,5.1,0.3c1.2,0,1.9,0,2.6-0.1c1.7-0.2,2.5-0.6,3.6-0.9
								c3.5-0.9,4.1,0.7,6.9-0.1c1.7-0.5,3-1.6,3.8-1.7c0.2-1.3,0.5-2.5,0.7-3.8c0.1-0.9,0.2-1.6,0.8-2.3c0.9-1.2,2.3-1.1,4.4-1.8
								c1.1-0.3,0.7-0.3,4.8-2.4c2.9-1.4,4.3-2.2,6.3-3c3.2-1.4,3.9-1.5,4.6-2.5c0.8-1,1-2.2,2.1-2.5c0.9-0.2,1.5,0.4,1.9,0
								c0.4-0.4-0.2-1.2-0.4-3.1c-0.2-1.4,0.1-1.6-0.2-2.6c-0.3-1-0.7-1.2-0.8-2c-0.1-0.7,0.2-1.3,0.5-2c0,0,0.2-0.4,1.8-4.4
								c0.6-1.6,0.9-2.3,1.1-3.2c0.2-1.4,0-1.6,0.1-3.4c0.1-1.7,0.2-3.2,1-4.6c0.8-1.3,1.4-1.1,2.6-2.5c1.3-1.5,1.9-3.3,3-7.1
								c0.5-1.7,0.9-2.9,1.1-4.6c0,0,0.2-1.5,0.5-6.1c0,0,2.6-0.9,5-0.8c2.6,0.2,3.5,1.5,5.3,0.9c0.8-0.3,1.3-0.9,1.5-1.1
								c1.5-1.8,0.7-4,1-6.6c0.3-3.4,2-2.9,3.1-7c1.1-3.9-0.5-4.2,0.5-10.1c0.3-2,0.7-3.2,0.6-5.4c0-0.8-0.2-2.6-1-4.7
								c-0.6-1.5-1.3-2.8-2.9-5.3c-1.6-2.5-1.5-2.2-2.2-3.3c-0.8-1.6-1.2-3-2.1-5.8c-0.6-2-0.9-3.1-0.9-4c0-1.4,0.4-1.7,0.3-3.6
								c0-0.6,0-0.9,0.2-1.2c0.6-0.8,2.4-0.5,3.6,0c0.2,0.1,1,0.4,1.8,1.3c0.8,0.9,0.7,1.5,1.7,3c0.6,0.9,0.9,1.4,1.3,1.7
								c1.1,0.7,2.4,0,4.3-0.6c3.4-1,3.3-0.3,5.8-1.2c0.9-0.3,3.7-1.3,6.3-3.8c1.9-1.9,1.1-2.1,4-5.9c0.4-0.5,1-1.3,1.3-2.6
								c0.2-1,0.1-1.9,0-2.9c-0.3-2-0.8-2.4-0.8-3.9c0-1.1,0.2-1,0.4-2.8c0.3-3-0.4-3.9,0.4-5C932,62.4,932,62.5,933.3,61z'
							/>
							<text id='XMLID_243_' transform='matrix(1 0 0 1 830.6772 125.832)' className='st4 st5 text-map'>
								{t('kab-jepara')}
							</text>
						</g>
						<g id='Kabupaten_Kudus' className='region' from='home' post-name='kabupaten-kudus'>
							<path
								id='XMLID_58_'
								className='st11'
								d='M930.5,206.1c-0.8-0.8-2.6-4.1-6.3-10.7c-0.7-1.3-1.3-2.3-1.3-3.7c-0.1-1.5,0.5-2.1,0.5-4.3
								c0-1.3,0-2.4-0.7-2.8c-0.9-0.5-2.1,1-3.3,0.5c-0.7-0.3-0.5-0.8-1.8-2.5c-0.8-1-1.6-1.8-1.8-2c-1.2-1.2-1.9-1.4-1.8-2
								c0-0.5,0.6-0.6,1.2-1.3c0,0,0.6-0.8,1-2.3c0.5-2.3-1.1-5.4-1.5-6.2c-1.6-2.9-3-6-4.7-8.8c-2.8-4.8-4.2-7.2-4.7-8.7
								c-1.2-3.8-0.8-6.1-0.8-8.4c-0.2,0.1-0.4,0.3-0.7,0.4c-1.7,0.7-2.6-0.7-5.3-0.9c-2.4-0.2-5,0.7-5,0.8c-0.3,4.7-0.5,6.1-0.5,6.1
								c-0.3,1.7-0.6,2.9-1.1,4.6c-1.1,3.8-1.7,5.7-3,7.1c-1.3,1.4-1.9,1.2-2.6,2.5c-0.8,1.4-0.9,2.9-1,4.6c-0.1,1.8,0.1,2-0.1,3.4
								c-0.2,0.9-0.5,1.6-1.1,3.2c-1.6,4-1.7,4.4-1.8,4.4c-0.3,0.7-0.6,1.3-0.5,2c0.1,0.8,0.5,1,0.8,2c0.3,1,0,1.1,0.2,2.6
								c0.2,1.9,0.8,2.7,0.4,3.1c-0.4,0.4-1-0.2-1.9,0c-1.1,0.3-1.3,1.5-2.1,2.5c-0.7,1-1.4,1.1-4.6,2.5c-1.9,0.8-3.4,1.6-6.3,3
								c-4.1,2-3.6,2-4.8,2.4c-2,0.6-3.4,0.5-4.4,1.8c-0.5,0.7-0.6,1.4-0.8,2.3c-0.2,1.3-0.5,2.5-0.7,3.8c0.2,0,0.3,0,0.5,0.1
								c0.3,0.2,0.1,0.6,0.5,1.3c0.4,0.6,0.9,0.8,1.3,1c1.6,0.8,3,1.1,4.3,1.5c1.2,0.4,2.5,0.9,3.6,2.1c0.8,0.9,1.1,1.7,1.6,3.1
								c0.7,1.9,0.9,3.5,1,4.9c0.1,0.8-0.3,2.3,0.1,3.8c0.1,0.3,0.2,0.6,0.5,0.8c0.5,0.2,1-0.7,1.9-0.8c0.8,0,1.4,0.7,1.6,0.9
								c0.6,0.7,0.7,1.5,0.8,2.1c0.6,3.9,0.8,3.3,0.9,5.1c0.1,2.1,0.1,3.2-0.3,4.3c-0.7,1.8-1.7,2-2.7,4.2c-0.5,1.1-0.5,1.6-0.8,2.8
								c-0.4,1.7-1.6,4.1-4,8.8c-1.4,2.7-2,4.1-2.3,4.5c-1.7,2.4-3,3-3.2,4.8c-0.1,1.2,0.3,1.9,0,2.6c1.3,0.8,2.7,1.5,4.1,1.9
								c1.2,0.3,3.5,0.8,4,2.4c0.3,1-0.3,1.8,0.1,3.4c0.3,0.9,0.8,1.9,1.3,1.9c0.5-0.1,0.3-1.8,1.1-4c0.7-1.8,1.2-1.7,1.9-3.3
								c1.3-3.1-0.3-4.6,0.9-5.9c1.4-1.4,3.8,0.3,6.4-1.5c1-0.7,1.3-1.5,1.4-1.8c0.2-0.8-0.2-1.2-0.3-2c-0.1-0.9,0.3-1.7,4.5-6.4
								c0.8-0.8,0.8-0.9,1-1c1.9-1,4,1.3,6.3,0.6c1.4-0.4,2.2-1.7,2.6-2.3c1.7-2.8,0.9-6.8,0.2-10.5c-0.3-1.6-0.5-2-0.2-2.5
								c1.1-1.6,4.8,1,10,0.7c3.7-0.2,3.2-1.6,13-5c2.8-1,3.6-1.1,4.8-2c3.8-2.6,5.4-7,5.7-7.7c0.8-2.1,0.6-3.1,1.8-5.2c1-1.7,1.7-2,1.8-3
								C935.4,209.9,933.3,209.1,930.5,206.1z'
							/>
							<text id='XMLID_57_' transform='matrix(1 0 0 1 873.689 204.4987)' className='st4 st5 text-map'>
								{t('kab-kudus')}
							</text>
						</g>
						<g id='Kabupaten_Pati' className='region' from='home' post-name='kabupaten-pati'>
							<path
								id='XMLID_228_'
								className='st20'
								d='M1039.7,213.2c-0.7-1.2-1.7-2.8-2.3-4.9c-0.8-3,0-3.6-0.4-8.2c-0.1-1.2-0.4-2.9-1.1-6.2
								c-0.8-4-0.9-4.1-1.3-6.3c-0.3-2.2-0.4-3.8-0.6-6.6c-0.4-6.3,0.1-5.6-0.3-7.8c-0.4-2-1-4-0.8-6.8c0.1-1.1,0.3-1.6,0.6-2.1
								c1.2-1.9,3.7-1.8,3.6-2.5c0-0.5-1.5-0.9-2.5-1c-1.2-0.1-2.1,0.1-3.3,0.4c-0.3,0.1-3.2,0.7-4.1,0.9c-3.1,0.4-4.1-2.4-9.4-4.8
								c-3.8-1.6-4.4-0.7-8.3-2.6c-2.5-1.3-2.6-1.8-5.5-3.5c-3.4-1.9-5.1-2.2-8.1-3.5c-3.1-1.3-6.3-2.9-9.6-5.9c-0.6-0.5-3.3-3.1-5.8-7.3
								c-1.7-3-2.2-4.9-3.6-9c-2.1-6-2.4-5.3-4.9-11.9c-3.2-8.5-2.7-9.5-6.3-18.8c-1.9-5-2.8-7.5-3-7.8c-2.6-6-4.3-8.4-3.5-11.7
								c0.4-1.8,1.1-2,1.3-4c0.3-2.4-1.9-3.8-1.7-6.7c0.1-0.8,0.2-0.9,0.3-1.3c0-0.3,0-0.3,0.1-0.7c0-0.1,0-0.1,0-0.1
								c-0.1-0.3-1.4,0.1-2.3,0.3c-1.2,0.2-1.9,0-3.4-0.2c-4.1-0.5-4.2,0.5-6.4-0.2c-2.3-0.7-2.2-1.7-4.6-2.1c-1.3-0.2-1.8,0-3.4-0.3
								c-1.8-0.4-2.3-0.8-3.1-1c-1-0.2-2.1,0.2-3,0.8c0.5-0.1,0.9-0.2,0.9,0c0.1,0.1-0.1,0.3-0.9,1.2c-1.3,1.4-1.3,1.4-1.4,1.5
								c-0.8,1.1-0.1,2-0.4,5c-0.2,1.8-0.5,1.6-0.4,2.8c0.1,1.5,0.6,1.9,0.8,3.9c0.1,1,0.2,1.9,0,2.9c-0.3,1.2-0.9,2.1-1.3,2.6
								c-2.9,3.9-2.1,4-4,5.9c-2.5,2.5-5.3,3.5-6.3,3.8c-2.6,0.9-2.5,0.1-5.8,1.2c-1.9,0.6-3.2,1.2-4.3,0.6c-0.4-0.3-0.7-0.7-1.3-1.7
								c-1-1.5-0.8-2.1-1.7-3c-0.8-0.8-1.6-1.2-1.8-1.3c-1.2-0.5-3-0.8-3.6,0c-0.2,0.3-0.2,0.6-0.2,1.2c0,1.9-0.3,2.2-0.3,3.6
								c0,0.9,0.3,2,0.9,4c0.8,2.8,1.3,4.2,2.1,5.8c0.6,1.2,0.6,0.9,2.2,3.3c1.6,2.5,2.4,3.7,2.9,5.3c0.8,2.1,1,3.9,1,4.7
								c0.1,2.2-0.3,3.4-0.6,5.4c-1,5.9,0.6,6.2-0.5,10.1c-1.1,4.1-2.7,3.6-3.1,7c-0.3,2.6,0.5,4.8-1,6.6c-0.1,0.2-0.4,0.5-0.8,0.7
								c0,0,0,0,0,0c0,2.3-0.4,4.7,0.8,8.4c0.5,1.4,1.9,3.8,4.7,8.7c1.7,2.9,3.1,5.9,4.7,8.8c0.4,0.8,2,3.8,1.5,6.2c-0.4,1.6-1,2.3-1,2.3
								c-0.6,0.7-1.1,0.8-1.2,1.3c0,0.6,0.6,0.8,1.8,2c0.2,0.2,1.1,1,1.8,2c1.3,1.7,1.1,2.2,1.8,2.5c1.3,0.5,2.4-1,3.3-0.5
								c0.7,0.4,0.7,1.5,0.7,2.8c0,2.2-0.6,2.8-0.5,4.3c0.1,1.4,0.6,2.4,1.3,3.7c3.7,6.6,5.6,9.8,6.3,10.7c2.8,3,4.9,3.8,4.7,5.7
								c-0.1,1-0.8,1.3-1.8,3c-1.2,2.1-1.1,3-1.8,5.2c-0.2,0.6-1.9,5-5.7,7.7c-1.3,0.9-2,1-4.8,2c-9.8,3.4-9.3,4.8-13,5
								c-5.2,0.3-8.9-2.2-10-0.7c-0.3,0.5-0.1,0.9,0.2,2.5c0.7,3.7,1.5,7.7-0.2,10.5c-0.4,0.6-1.2,1.9-2.6,2.3c-2.3,0.6-4.4-1.7-6.3-0.6
								c-0.2,0.1-0.2,0.2-1,1c-4.2,4.7-4.6,5.5-4.5,6.4c0.1,0.8,0.5,1.2,0.3,2c-0.1,0.3-0.3,1-1.4,1.8c-2.6,1.8-5,0.1-6.4,1.5
								c-1.2,1.2,0.4,2.8-0.9,5.9c-0.7,1.6-1.2,1.4-1.9,3.3c-0.9,2.2-0.6,3.9-1.1,4c-0.2,0-0.3-0.1-0.5-0.4c-0.1,0.3-0.3,0.6-0.6,0.9
								c-0.9,0.7-2,0.1-2.6,0.9c-0.8,1,0.2,3.2,0.8,4.4c0.6,1.2,0.9,1.5,2.5,3.8c2.4,3.3,2.9,4.5,4.1,4.6c0.6,0,1.1-0.2,1.8-0.6
								c4.1-2.4,6.2-3.6,7.9-3.6c2.6,0,3.3,2,5.1,1.4c1.6-0.5,2.3-2.4,2.9-3.8c1.6-4.2,0.1-6.8,1.9-8.1c1-0.7,2.5-0.6,3.4-0.1
								c0.8,0.4,1.2,1.1,2.1,3c2,4.2,2.3,5.4,3.1,5.5c0.7,0.1,1.3-0.6,2.5-2.1c4.7-6,4.7-6.9,6-7.3c2.1-0.6,4.7,0.9,7.8,2.8
								c2.1,1.3,2.9,2.2,4.3,2c1.7-0.3,1.4-1.9,3.8-3.1c2.2-1.2,3.7-0.4,5.4-2c0.7-0.7,0.7-1,1.4-1.4c0.7-0.4,1.6-0.4,4.1,0.3
								c2.3,0.6,3.6,0.9,5.5,1.8c1.8,0.8,2,1.1,3.1,1.6c2.2,1,3.3,0.6,11,0.4c6.1-0.1,5.6,0.1,6.6-0.1c1.2-0.3,2.7-0.8,4.1-0.1
								c0.8,0.4,1,0.8,1.8,0.9c1,0.1,1.6-0.6,2.9-1.6c3-2.4,3.9-2.2,4.1-3.3c0.2-1.1-0.8-1.4-1.3-3.8c-0.2-1.1-0.4-2.3,0.3-3.1
								c1.1-1.3,3.8-0.3,4-1c0.1-0.5-1.1-0.8-1.5-2c-0.2-0.7-0.1-1.5,0.9-3.5c1.1-2.1,1.5-2.4,2.4-4c1.7-3.1,1.1-4.3,2.4-5.1
								c1.4-0.9,3.8-0.7,4.9,0.5c1.1,1.1,0.3,2.5,1.4,3.3c1.1,0.8,3,0.1,4-0.6c1.2-0.8,1.8-2.1,2.6-3.8c0.5-1.1,0.3-1.8,0.9-2.3
								c0.7-0.5,1.4-0.1,2.6,0.1c0.6,0.1,2.4,0,6.1-0.1c3-0.1,3.6-0.2,4.1-0.4c1.2-0.3,1.9-0.7,3.3-1.4c2.2-1.1,3.3-1.7,3.8-2.8
								c0.4-1-0.2-1.4,0-2.8c0.2-1.8,1.5-3.5,3.1-4.4c2.3-1.2,4.2-0.1,5.3-1.5c0.4-0.6,0.5-1.3,0.5-2.1c0.1-2.1-1.1-3.1-0.5-3.9
								c0.4-0.6,1.4-0.8,2.1-0.5c0.7,0.3,0.6,0.8,1.7,1.7c0.4,0.3,0.8,0.7,1.4,0.9c0.8,0.3,1,0.1,2.6,0.4c1,0.2,1.3,0.4,1.5,0.6
								c0.2,0.2,0.2,0.4,0.5,0.8c0.3,0.3,0.5,0.6,0.8,0.6c0.3,0,0.4-0.7,0.8-1.3c0.8-1.2,2.1-0.8,2.6-1.8c0.4-0.6-0.2-0.9-0.2-2.5
								c0-1.6,0.5-2.8,0.8-3.6c0.6-1.5,0.9-1.3,1.1-2.1C1043.5,217.8,1041.6,216.3,1039.7,213.2z'
							/>
							<text id='XMLID_245_' transform='matrix(1 0 0 1 949.0106 189.4987)' className='st4 st5 text-map'>
								{t('kab-pati')}
							</text>
						</g>
						<g id='Kabupaten_Rembang' className='region' from='home' post-name='kabupaten-rembang'>
							<path
								id='XMLID_52_'
								className='st25'
								d='M1209.4,189.5c-0.2-0.9-1.1-1.3-1.8-1.7c-3.2-1.6-5.9-4.1-9-5.8c-6.5-3.6-4.4-6.7-12.2-12.2
								c-4.3-3-6.1-2.9-9.8-6.5c-1.1-1.1-1.1-1.2-5-6.3c-5.9-7.7-9.1-11.5-11.7-10.8c-0.7,0.2-1.2,0.7-2,0.5c-1.5-0.4-1.3-2.6-2.7-4
								c-1.7-1.7-4.5-1-7.8-0.8c-5.5,0.2-8.3-0.1-11-1.8c-0.4-0.2-1.8-1.5-4.8-4c-2-1.7-3.3-2.8-4.7-2.5c-1,0.2-1.5,1-1.8,1.3
								c-2.1,2.6-4.8,4.7-6.7,7.5c-1.2,1.8-5.1,5.2-12.8,12.2c-5.9,5.2-7.8,6.6-10.5,7.8c-2.3,1-4.2,1.9-7,2.2c-2.3,0.2-3.5-0.1-5.8,0.5
								c-2.4,0.6-2.8,1.4-4.7,2.2c-1.9,0.7-3.8,0.8-13-1.3c-5.5-1.3-4.7-1.3-7.5-1.8c-1.3-0.3-3.7-0.7-12.3-1.5c-3.5-0.3-5.8-0.5-7.4-0.6
								c0,0,0,0,0,0c0,0.7-2.4,0.6-3.6,2.5c-0.3,0.5-0.5,1-0.6,2.1c-0.3,2.8,0.4,4.8,0.8,6.8c0.4,2.3-0.1,1.5,0.3,7.8
								c0.2,2.8,0.2,4.4,0.6,6.6c0.3,2.1,0.5,2.3,1.3,6.3c0.6,3.3,1,4.9,1.1,6.2c0.5,4.6-0.4,5.2,0.4,8.2c0.6,2.1,1.5,3.7,2.3,4.9
								c1.9,3.1,3.8,4.6,3.3,6.5c-0.2,0.8-0.5,0.6-1.1,2.1c-0.3,0.7-0.8,2-0.8,3.6c0,0.6,0.1,1,0.2,1.3c1.4,0,2.9,0.2,4.2,1.2
								c1,0.8,1.1,1.4,2.3,2.2c1.8,1.2,3.8,1,6,0.8c9.4-0.8,10.6-1.3,13.2-0.5c1.9,0.6,3.5,1.5,4.5,2.2c1.6,1,2.3,1.6,5.5,4.2
								c2.2,1.8,3.3,2.6,3.7,2.8c1.8,1.2,5.6,3.5,9.3,2.7c2.8-0.6,2.7-2.4,6-2.8c1.9-0.3,4.5,0,4.7,0c1.9,0.2,2,0.4,2.8,0.3
								c2.3-0.2,3-2,4.8-3c2.2-1.2,3.3,0.4,7.8,0.7c6.6,0.4,9.8-2.8,14-0.7c0.4,0.2,0.4,0.2,1.8,1c2.7,1.4,5.8,3,5.8,3
								c2.3,1.1,2.8,1.4,3.5,2.2c0.8,1,0.7,1.5,1.3,1.8c1.6,0.9,3.5-1.5,5.2-0.7c1.1,0.5,0.6,1.7,1.7,2.3c1.9,1.2,4.7-2,7-0.8
								c0.9,0.5,0.5,1,1.8,2.3c1.9,1.9,3.2,1.4,4.3,3c1.1,1.6,0.6,3,1.5,3.3c0.6,0.2,1.4-0.4,3-1.7c1.7-1.3,1.8-1.8,3-2.3
								c1-0.5,1.3-0.3,2-0.8c1-0.7,1.5-1.7,1.8-2.5c1.4-3.3,2.2-5.1,2.2-7.8c0-2.7-0.8-3.7-0.2-6.2c0.3-1.2,0.6-1.2,1-2.3
								c1.7-4.7-1.4-8.7,0.3-10.2c0.4-0.3,1.1-0.3,2.5-0.3c2.9,0,3.9,0.6,6.3,0.8c0.4,0,6,0.5,6.8-1.5c0.1-0.4,0-0.5-0.2-1.7
								c-0.3-1.7-0.6-3.9,0.2-5.8c0.4-1.2,0.8-1,1.3-2.3c0.6-1.4,0.6-2.6,0.7-3.7c0.2-5.5-0.8-6.5,0.2-7.5c1.1-1.2,4-1.4,5.8-0.7
								c0.5,0.2,1.1,0.5,1.8,0.3c0.9-0.2,1.3-1.1,1.5-1.5C1206.4,192.7,1209.9,191.4,1209.4,189.5z'
							/>
							<text id='XMLID_51_' transform='matrix(1 0 0 1 1084.6772 199.1653)' className='st4 st5 text-map'>
								{t('kab-rembang')}
							</text>
						</g>
						<g id='Kabupaten_Blora' className='region' from='home' post-name='kabupaten-blora'>
							<path
								id='XMLID_233_'
								className='st26'
								d='M1158.4,247.8c-1.4-1.4-0.9-1.9-1.8-2.3c-2.3-1.2-5.1,2-7,0.8c-1.1-0.7-0.6-1.8-1.7-2.3
								c-1.7-0.8-3.6,1.5-5.2,0.7c-0.7-0.4-0.5-0.8-1.3-1.8c-0.7-0.8-1.2-1-3.5-2.2c0,0-3.2-1.6-5.8-3c-1.4-0.8-1.5-0.8-1.8-1
								c-4.2-2.1-7.4,1-14,0.7c-4.5-0.3-5.6-1.8-7.8-0.7c-1.8,1-2.5,2.8-4.8,3c-0.9,0.1-0.9-0.1-2.8-0.3c-0.1,0-2.8-0.3-4.7,0
								c-3.3,0.4-3.2,2.2-6,2.8c-3.8,0.8-7.5-1.5-9.3-2.7c-0.3-0.2-1.4-1.1-3.7-2.8c-3.2-2.5-3.9-3.2-5.5-4.2c-1-0.6-2.6-1.6-4.5-2.2
								c-2.5-0.8-3.7-0.3-13.2,0.5c-2.2,0.2-4.2,0.3-6-0.8c-1.2-0.7-1.3-1.4-2.3-2.2c-1.3-1-2.8-1.2-4.2-1.2c0,0,0,0,0,0
								c0.1,0.5,0.3,0.8,0,1.2c-0.5,1-1.8,0.6-2.6,1.8c-0.4,0.6-0.4,1.2-0.8,1.3c-0.2,0-0.5-0.3-0.8-0.6c-0.3-0.4-0.3-0.6-0.5-0.8
								c-0.2-0.2-0.5-0.4-1.5-0.6c-1.6-0.3-1.8-0.1-2.6-0.4c-0.6-0.2-1-0.6-1.4-0.9c-1.1-0.9-1-1.4-1.7-1.7c-0.7-0.3-1.7-0.1-2.1,0.5
								c-0.6,0.8,0.6,1.7,0.5,3.9c0,0.8-0.1,1.5-0.5,2.1c-1,1.4-3,0.3-5.3,1.5c-1.6,0.9-2.9,2.6-3.1,4.4c-0.2,1.4,0.4,1.7,0,2.8
								c-0.4,1-1.6,1.6-3.8,2.8c-1.3,0.7-2.1,1.1-3.3,1.4c-0.6,0.2-1.1,0.2-4.1,0.4c-3.7,0.2-5.5,0.2-6.1,0.1c-1.2-0.2-2-0.6-2.6-0.1
								c-0.6,0.4-0.3,1.1-0.9,2.3c-0.8,1.7-1.4,2.9-2.6,3.8c-1,0.7-2.9,1.4-4,0.6c-1-0.7-0.3-2.1-1.4-3.3c-1.1-1.2-3.5-1.4-4.9-0.5
								c-1.3,0.9-0.7,2-2.4,5.1c-0.8,1.6-1.3,1.9-2.4,4c-1,2-1.1,2.8-0.9,3.5c0.4,1.2,1.6,1.5,1.5,2c-0.1,0.2-0.3,0.3-0.7,0.3
								c0.7,0.7,1.4,1.6,1.8,2.7c1.1,2.8-0.1,4.3,1.5,7.8c0.5,1.2,1.8,3.8,4.6,5.1c2.5,1.1,5.1,0.6,5.8,0.5c1.2-0.2,1.1-0.4,3.1-1
								c2.3-0.6,3.5-1,4.9-0.9c2.2,0.2,3.7,1.3,5.1,2.4c0.5,0.4,2.3,1.8,4.3,4.6c2.9,4.3,2.3,6.4,4.8,7.8c1.2,0.7,1.5,0.3,3.9,1.4
								c2.2,1,3.7,1.9,3.7,1.9c0.6,0.4,1.2,0.7,2.1,0.9c1,0.3,1.3,0.1,1.4,0.4c0.6,0.8-1.8,2.9-3.5,6.3c-0.4,0.8-1.3,2.6-1.6,4.9
								c-0.4,2.8,0.3,4,1,9.8c0.4,3.8,0.1,3.2,0.4,4.1c1.5,4.9,5.9,5.7,8.5,11.5c0.5,1.2,0.8,2.2,0.9,2.4c0.8,2.9,0.5,4.4,0.8,6.6
								c0.4,3.3,1.6,2.8,2.4,6.5c0.6,2.9,0.4,5.3,0,8.4c-0.3,2.2-0.4,3.4-1.1,4.8c-1.1,2-2.1,2-2.4,3.5c-0.4,2.3,1.4,3.9,0.5,5.3
								c-0.6,0.9-1.6,0.5-2.4,1.6c-0.6,0.9-0.4,1.6-0.6,2.8c-0.4,2.5-2.2,4.7-4,5.9c-1.7,1.1-2.8,0.8-3.8,2.3c-0.2,0.3-0.7,1.1-0.6,2
								c0.1,2.1,2.7,2.5,6,5.8c2.2,2.1,2.1,3.1,3.6,3.5c0.9,0.3,1.1-0.1,5-0.9c1.5-0.3,3.3-0.6,5.5-0.8c1.6-0.1,2.1,0,2.6,0.1
								c1.1,0.3,1.8,0.8,3.4,2c3.2,2.6,4.8,3.8,5.6,5.1c0.8,1.2,1.1,2,2,2.3c1.1,0.2,1.7-0.7,2.9-0.8c1,0,1.8,0.6,3.8,2.6
								c2.8,2.9,3.1,3.5,5.9,6.6c0.7,0.8,1.5,1.7,2.4,3c1,1.6,0.8,1.8,1.3,2.2c1.9,1.5,4.9-1.3,7.2,0.2c1.4,0.9,0.9,2.5,2.3,3.2
								c1.3,0.6,2.2-0.5,4.8-0.8c2.7-0.4,2.8,0.7,6,0.5c3.2-0.2,3.8-1.3,5.7-0.7c2.1,0.6,3.3,2.5,3.7,3c1,1.5,0.5,2,1.7,3.8
								c1.1,1.7,1.8,3,3.2,3.3c1.7,0.5,2.5-0.9,3.8-0.3c1.4,0.6,0.9,2.2,2.3,3.3c1.7,1.4,5.1,1.2,7-0.5c1-0.9,1.5-2,1.7-2.5
								c0.2-0.5,0.7-2,0.3-3.7c-0.4-1.7-1.3-1.6-1.5-3c-0.3-2,1.3-3.1,0.7-4c-0.3-0.5-1.3-0.5-3.3-0.7c-1.7-0.1-2.4,0-2.8-0.5
								c-0.5-0.5-0.5-1.3-0.3-2c0,0,0.7-1.4,1.7-2.3c0.9-0.9,1.2-0.7,1.8-1.3c0.4-0.4,1-1.2,1-4.2c0-3.4,0-6.2-1.7-7.2
								c-0.3-0.2-1.6-0.4-4-0.7c-1.3-0.2-2-0.2-2.7-0.8c-0.2-0.2-0.9-0.9-0.8-1.8c0.1-1.6,2.5-1.7,3.2-3.8c0.3-0.8-0.1-0.8,0-3
								c0.1-2,0.1-3.8,1-4.2c0.7-0.3,1.1,0.6,2.3,0.7c1.1,0.1,1.9-0.6,4.3-2.5c3.5-2.7,3.9-2.9,4.3-3c1.9-0.4,2.5,0.7,4.8,1
								c1.8,0.2,2.8-0.2,9.3-2.8c4.2-1.7,6.4-2.6,6.8-2.8c4.8-2.3,7.3-3.5,8.5-5.8c1.1-2.1,0.1-2.5,1.3-5.5c0.9-2.1,1.8-2.8,4.3-6.3
								c1-1.4,2.1-3,3.3-4.8c3.7-6.9,4.3-7.7,4.3-7.8c2.9-4.6,3.5-4.1,5.3-7.5c1.2-2.4,2.3-4.6,2.3-7.5c-0.1-3-1.4-3.4-1-6
								c0.4-2.8,2-3.2,2.3-6c0.2-2.5-1-3-0.5-4.8c0.6-2.3,2.9-2.4,4.3-4.3c1.8-2.5,0.2-5.3-1.3-12.5c-1.6-7.7-2.4-11.6-1.5-15
								c1.1-4.1,3.4-6.4,2.5-10.3c-0.2-0.8-0.7-2.7-2.3-3.8c-1.5-1-2.3-0.2-4.8-1c-1.4-0.4-1.1-0.7-5.5-3.3c-2.6-1.5-3.4-1.8-4.3-2
								c-1.9-0.4-2.5,0-3-0.5c-1.1-1.2,1.6-4.6,3.5-8.6c-0.1-0.6-0.3-1.2-0.8-1.9C1161.6,249.2,1160.3,249.7,1158.4,247.8z'
							/>
							<text id='XMLID_248_' transform='matrix(1 0 0 1 1074.0106 311.1653)' className='st4 st5 text-map'>
								{t('kab-blora')}
							</text>
						</g>
						<g id='Kabupaten_Grobogan' className='region' from='home' post-name='kabupaten-grobogan'>
							<path
								id='XMLID_47_'
								className='st27'
								d='M1036.3,348.8c-0.8-3.7-2-3.2-2.4-6.5c-0.3-2.2,0.1-3.8-0.8-6.6c0-0.2-0.4-1.2-0.9-2.4
								c-2.6-5.8-7-6.6-8.5-11.5c-0.3-0.9,0.1-0.3-0.4-4.1c-0.7-5.8-1.4-6.9-1-9.8c0.3-2.3,1.2-4,1.6-4.9c1.7-3.4,4.1-5.5,3.5-6.3
								c-0.2-0.2-0.4-0.1-1.4-0.4c-0.9-0.3-1.4-0.6-2.1-0.9c0,0-1.5-0.8-3.7-1.9c-2.4-1.2-2.7-0.8-3.9-1.4c-2.5-1.4-1.9-3.5-4.8-7.8
								c-1.9-2.8-3.7-4.2-4.3-4.6c-1.4-1.1-3-2.2-5.1-2.4c-1.4-0.1-2.6,0.2-4.9,0.9c-2,0.6-2,0.8-3.1,1c-0.7,0.1-3.2,0.6-5.8-0.5
								c-2.8-1.3-4.1-3.9-4.6-5.1c-1.6-3.5-0.4-4.9-1.5-7.8c-0.5-1.2-1.1-2-1.8-2.7c-0.9,0-2.5-0.2-3.3,0.7c-0.7,0.8-0.4,2-0.3,3.1
								c0.4,2.3,1.5,2.6,1.3,3.8c-0.2,1.1-1.1,0.9-4.1,3.3c-1.3,1-1.9,1.7-2.9,1.6c-0.7-0.1-1-0.5-1.8-0.9c-1.5-0.7-2.9-0.2-4.1,0.1
								c-1.1,0.3-0.5,0-6.6,0.1c-7.7,0.2-8.8,0.6-11-0.4c-1.1-0.5-1.3-0.8-3.1-1.6c-1.9-0.9-3.2-1.2-5.5-1.8c-2.6-0.6-3.4-0.6-4.1-0.3
								c-0.7,0.4-0.7,0.7-1.4,1.4c-1.7,1.6-3.2,0.8-5.4,2c-2.3,1.2-2.1,2.9-3.8,3.1c-1.3,0.2-2.1-0.7-4.3-2c-3-1.8-5.6-3.4-7.8-2.8
								c-1.3,0.4-1.3,1.2-6,7.3c-1.2,1.5-1.8,2.2-2.5,2.1c-0.8-0.1-1.1-1.3-3.1-5.5c-0.9-1.9-1.3-2.6-2.1-3c-0.9-0.4-2.4-0.6-3.4,0.1
								c-1.7,1.3-0.2,4-1.9,8.1c-0.6,1.4-1.3,3.2-2.9,3.8c-1.9,0.6-2.6-1.4-5.1-1.4c-1.7,0-3.8,1.2-7.9,3.6c-0.7,0.4-1.1,0.7-1.8,0.6
								c-1.2-0.1-1.8-1.3-4.1-4.6c-1.6-2.2-1.9-2.5-2.5-3.8c-0.6-1.2-1.6-3.4-0.8-4.4c0.6-0.7,1.7-0.1,2.6-0.9c0.3-0.2,0.5-0.5,0.6-0.9
								c0,0,0,0,0,0c-0.3-0.4-0.6-1-0.7-1.5c-0.5-1.6,0.2-2.4-0.1-3.4c-0.5-1.5-2.8-2-4-2.4c-1.5-0.4-2.8-1.1-4.1-1.9
								c0,0.1-0.1,0.1-0.1,0.2c-0.6,0.8-1.4,0.5-2.2,1.2c-0.8,0.7-0.7,2-0.7,4c0.1,4.6,0.2,6.9-0.7,8.5c-0.7,1.3-1.4,1.5-1.7,2.8
								c-0.2,1.4,0.2,2.7,0.7,3.8c0.6,1.6,1,1.8,1.3,3.2c0.2,0.7,0.4,1.6,0.2,2.5c-0.3,1.4-1.5,2.1-3.7,3.5c-4.2,2.6-5.4,3.3-6.8,4.2
								c-3.6,2.2-3.9,2.6-5.2,2.7c-2.4,0.2-4.4-1.1-5.5-1.8c-2.4-1.5-2-2.4-4.2-3.7c-2.2-1.3-2.8-0.6-6-2c-2-0.9-1.5-1-4.7-2.7
								c-3.1-1.6-3.8-1.6-4.7-2.7c-1-1.2-0.4-1.5-1.7-4.3c-0.8-1.7-1.5-3.2-3-4.5c-0.5-0.4-1.5-1.2-3-1.5c-0.4-0.1-2.7-0.5-3.7,0.7
								c-0.5,0.6-0.3,1.3-0.2,3c0.2,3.1-0.5,3.1-0.3,6c0.2,3.1,0.9,3.3,0.5,4.7c-0.5,1.7-1.8,1.6-4,3.8c-1.7,1.8-2.6,3.6-4.3,7
								c-1.4,2.9-1.2,3.1-2,3.8c-1.6,1.5-2.9,0.9-5.8,2.8c-1.1,0.7-2.7,1.8-2.7,3.2c0.1,0.9,0.8,1.2,0.7,2c-0.2,0.9-1.3,0.9-2,1.8
								c-1.1,1.6,0.2,4.3,0.5,4.8c0.3,0.6,0.6,1.1,3.7,4.3c2,2.1,2.2,2.4,2.3,3c0.2,1.1-0.4,1.9-2.2,4.7c-1.3,2-1.6,3.1-2.8,4.5
								c-0.3,0.4-0.8,0.9-1.1,1.1c-1.1,1-2.1,0.4-2.1,0.3c0,0.1-3.8,0.2-7,2.4c-2,1.4-3.3,3.2-3.6,3.8c-0.4,0.6-0.9,1.4-1.4,2.6
								c-0.2,0.6-0.6,1.9-0.6,3.6c0,1.6,0,3.1,1,4c0.6,0.5,0.9,0.2,2.1,0.8c1.8,0.8,2,1.9,3.4,2.6c1,0.5,1.6,0.2,3.9,0.1
								c5.2-0.2,5.3,1,8.5,0.4c2-0.4,3.2-1.2,4.3-0.5c0.6,0.4,0.8,1,1.1,2c0,0,0.7,1.9,2.4,4.8c0.9,1.5,2.5,4.3,5.4,5.5
								c0.8,0.3,1.8,0.6,2.5,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0.8-0.4,1.7-0.9,3-1.2c3.3-0.9,6-0.3,9.7,0.5c8,1.7,11.3,5.2,14.3,3.3
								c0.9-0.5,1.4-1.3,2.2-2.5c0.5-0.7,3.6-5.2,2-7.7c-0.1-0.2-0.7-0.6-2-1.5c-2.8-2-3.2-2.2-3.7-2.8c-0.7-1.1-1.4-2.7-0.8-3.5
								c0.3-0.4,0.8-0.3,1.8-0.7c1.1-0.4,1.6-1,2.5-1.7c2.7-2.1,3.9-1.2,5.5-3c1.2-1.4,0.6-2.1,1.8-3c1.6-1.2,2.9-0.3,4.5-1.5
								c1.4-1.1,1.8-2.9,1.8-3.3c0.4-1.9-0.4-2.5,0.2-3.7c0.5-1,1.7-1.4,3-1.8c0.8-0.3,2.7-0.9,5.2-0.5c1.3,0.2,2.2,0.5,9.2,5.5
								c2.3,1.6,3.3,2.4,3.3,3.5c0,1.2-1.1,1.6-2.3,3.3c-1.4,1.9-1.3,3.2-2.2,6.5c0,0-1,3.6-2.8,7.5c-2.7,5.7-5,7-4.3,9c0.8,2.5,5,3,5.2,3
								c1.6,0.2,3.1-0.4,6-1.5c3.2-1.2,4.8-1.9,6.2-3.2c2-1.9,1.4-2.9,3.5-5.2c2-2.1,5-3.9,5.8-3.2c0.6,0.5,0,2.1-1.2,5.5
								c-1.3,3.8-2,4.9-2.3,7.5c-0.3,2.8,0.3,3.8,0.5,4.2c0.7,1.1,1.6,1.2,3.2,2.2c0.5,0.3,0.9,0.6,1.2,0.9c1.2,1,1.9,2,2.6,2.8
								c2.6,2.8,6.6,3.6,10,4.3c3.4,0.7,3.5,0.2,15.5-0.2c10.7-0.3,12.1,0.1,13.5,0.7c1.6,0.7,3.2,1.7,5.7,1.5c2.3-0.2,2.6-1.2,5.8-1.8
								c3.5-0.7,4.2,0.2,5.7-0.8c2.1-1.5,1.2-3.8,3.2-5.2c1.2-0.8,2-0.2,5.5-0.2c5.7,0.1,7.1-1.5,10-0.2c1.4,0.6,1.5,1.2,3.2,1.7
								c0.5,0.1,2.2,0.6,5,0.2c2.2-0.4,3.7-1,6.5-2.3c4.3-2,4.5-2.7,6-2.7c1.6,0,1.7,0.7,6,2.5c2.2,0.9,3.9,1.5,5.3,1.8
								c2.8,0.7,4.8,1.2,5.8,0.2c0.2-0.2,0.3-0.4,0.4-0.6c-0.1-0.9-0.2-1.8,0.2-2.1c0.6-0.4,1.4,0.4,3.4,1.1c2.5,0.9,4.8,0.7,6.9,0.6
								c1.4-0.1,2.1-0.2,3.1-0.4c1.9-0.2,3.6-0.2,5.1,0c0.1-0.7,0.4-1.3,0.6-1.6c0.9-1.4,2.1-1.2,3.8-2.3c1.8-1.1,3.6-3.4,4-5.9
								c0.2-1.2,0-1.9,0.6-2.8c0.8-1.1,1.8-0.7,2.4-1.6c0.9-1.3-0.9-3-0.5-5.3c0.2-1.5,1.3-1.5,2.4-3.5c0.7-1.4,0.9-2.5,1.1-4.8
								C1036.7,354.1,1037,351.7,1036.3,348.8z'
							/>
							<text id='XMLID_45_' transform='matrix(1 0 0 1 892.0106 335.4987)' className='st4 st5 text-map'>
								{t('kab-grobogan')}
							</text>
						</g>
						<g id='Kabupaten_Sragen' className='region' from='home' post-name='kabupaten-sragen'>
							<path
								id='XMLID_229_'
								className='st25'
								d='M997,386c-1.4-0.4-3.1-0.9-5.3-1.8c-4.3-1.8-4.4-2.5-6-2.5c-1.5,0-1.7,0.6-6,2.7
								c-2.8,1.3-4.3,2-6.5,2.3c-2.8,0.4-4.5,0-5-0.2c-1.6-0.5-1.8-1-3.2-1.7c-2.9-1.3-4.3,0.2-10,0.2c-3.5,0-4.3-0.7-5.5,0.2
								c-2,1.3-1.1,3.7-3.2,5.2c-1.4,1-2.2,0.1-5.7,0.8c-3.2,0.7-3.6,1.7-5.8,1.8c-2.4,0.2-4-0.8-5.7-1.5c-1.4-0.6-2.8-1-13.5-0.7
								c-12,0.3-12.1,0.9-15.5,0.2c-3.4-0.7-7.4-1.5-10-4.3c-0.7-0.8-1.4-1.8-2.6-2.8c-0.4,0.3-0.9,0.6-1.4,1c-1.1,0.9-0.9,1-1.7,1.5
								c-1.7,1.1-3,0.4-6.7,0.8c-2.5,0.3-3.3,0.3-4,1c-1,0.9-0.7,2-0.8,5.8c-0.2,5.3-0.9,5.8-0.2,7.8c0.8,2.3,1.9,2.3,2.8,4.3
								c1.1,2.5,0.1,4.3-1,9.5c-2.1,9.9-0.5,12.6-3.3,15.3c-0.8,0.7-2.2,1.8-3.2,4c-0.4,1-0.7,2-0.8,3.7c-0.5,5.4,1,6.7,0.5,11
								c-0.4,3.4-1.5,4-0.8,5c1.1,1.6,4.3,0.2,12,0.8c2.5,0.2,4.3,0.5,5,0.7c2.9,0.6,5.4,1.4,6.8,2c4.5,1.9,5.4,4.6,8.8,7.3
								c2.7,2.2,9.2,3.6,22.2,6.3c4.1,0.9,6.4,1.3,9.7,2.7c3.4,1.4,4.1,2.3,8.2,4c4.5,1.9,4.2,1,8.2,2.7c5.1,2.1,7.5,4.4,10.5,3.5
								c0.4-0.1,1.3-0.4,1.7-1.2c0.6-1.4-1.7-2.7-1.5-4.3c0.2-1.9,3.8-2,9.3-4.8c4.4-2.3,5.5-3.9,7.5-3.3c1.6,0.5,2.5,2,3.8,4.2
								c1.2,2,1.1,2.7,2.5,5.5c1.7,3.5,2.5,5.3,3.8,5.5c1.6,0.3,2.3-1.6,6.3-4.7c2.8-2.1,5.2-3.8,7.3-3.2c1.9,0.6,2.5,2.8,3.2,2.5
								c0.5-0.2,0.2-1.7,0-4.7c-0.2-4.3,0.3-5-0.5-7c-0.6-1.6-1.7-3.2-2.4-3.5c-0.3,0.8-0.6,1.3-0.6,1.3c-0.1,0-0.1-1.2,0.3-1.3
								c0.1,0,0.2,0,0.3,0c0.6-1.6,1.2-4.3,0.4-6.9c-0.2-0.8-0.3-0.6-1.8-3.5c-1.3-2.5-2.1-4.1-2.8-6c-1.2-3.2-1.9-5.1-1.3-7.5
								c0.6-2.8,2.7-4.9,3.7-5.8c1.8-1.8,2.5-1.7,3.2-3c0.7-1.5,0.2-2.3-0.2-6.2c-0.4-5,0.4-5.4-0.5-7.7c-1-2.6-2.4-3-2.2-4.5
								c0.3-1.7,2.1-1.8,3.5-4.3c0.3-0.6,0.5-1.5,1-3.3c1-4,1.5-6.1,1-7.2c-1-2.1-3.3-1.7-3.7-3.3c-0.3-1.7,2-2.7,3.3-5
								c1.3-2.3,1-4.9,0.5-7.1c-0.1,0.6-0.1,1.2-0.6,1.8C1001.8,387.1,999.8,386.6,997,386z'
							/>
							<text id='XMLID_250_' transform='matrix(1 0 0 1 904.9622 433.8801)' className='st4 st5 text-map'>
								{t('kab-sragen')}
							</text>
						</g>
						<g id='Kabupaten_Karanganyar' className='region' from='home' post-name='kabupaten-karanganyar'>
							<path
								id='XMLID_40_'
								className='st15'
								d='M1021.7,547.1c-0.2-4.9-0.1-4.6-0.2-5.5c-0.4-3.6-1.1-4.1-1.8-8.2c-0.8-4.3,0-3.8-0.8-8.3
								c-0.4-2.2-0.8-3.5-0.3-5.3c0.4-1.7,1.2-1.9,1.7-3.7c0.3-1.1,1-3.7,0-6c-0.8-1.9-2.1-1.9-7.2-5.5c-2.8-1.9-4.2-2.9-4.8-4.2
								c-1.3-2.4-0.2-3.2-1.5-6.7c-1-2.7-1.9-2.7-2.8-5.5c-0.6-1.9-0.5-2.7-0.8-4.8c0,0-0.2-1-0.6-2.7c0,0,0,0,0,0
								c-0.6,0.3-1.3-1.9-3.2-2.5c-2.1-0.7-4.5,1.1-7.3,3.2c-4.1,3-4.8,4.9-6.3,4.7c-1.3-0.2-2.1-2-3.8-5.5c-1.4-2.8-1.3-3.5-2.5-5.5
								c-1.3-2.2-2.3-3.7-3.8-4.2c-2-0.6-3.1,1.1-7.5,3.3c-5.6,2.8-9.1,2.9-9.3,4.8c-0.2,1.6,2.1,2.9,1.5,4.3c-0.3,0.8-1.2,1-1.7,1.2
								c-3,0.9-5.4-1.4-10.5-3.5c-3.9-1.7-3.7-0.8-8.2-2.7c-4.1-1.7-4.8-2.6-8.2-4c-3.3-1.4-5.5-1.8-9.7-2.7c-13-2.8-19.5-4.2-22.2-6.3
								c-3.5-2.8-4.3-5.4-8.8-7.3c-1.5-0.6-4-1.4-6.8-2c-0.7-0.1-2.5-0.5-5-0.7c-7.6-0.6-10.7,0.7-11.9-0.7c-0.6,0.2-1.2,0.3-1.8,0.4
								c-0.4,0-1.3,0.1-2.2,0.8c-1.1,0.9-1.2,2.1-1.7,4.8c-0.7,3.6-0.7,2.7-1,4.8c-0.7,4.6-1,6.9-0.2,8.8c0.4,0.9,1.9,2.3,4.8,5
								c3.3,3,4.3,3.6,5,3.8c2.5,1,2.9,0.1,5.2,1c3.2,1.3,2.6,3.2,6.5,5.5c2.4,1.4,3.9,1.4,4.3,2.8c0.4,1.4-0.9,2.3-0.3,3.7
								c0.4,1.1,1.8,1.7,2.8,1.8c1.9,0.2,2.6-1.4,4.5-1.7c1.9-0.2,2.5,1.2,5.5,2.7c4.3,2.1,5.7,0.5,9.2,2.7c1,0.6,3.3,2,4.3,4.7
								c1.1,2.6-0.2,3.6,0.8,6.5c0.2,0.4,1.6,4.1,4.7,5c0.5,0.2,0.5,0.1,4.7-0.2c3.6-0.2,4.6-0.2,5.8,0.3c0.4,0.2,1.6,0.6,2.5,1.7
								c0.6,0.6,1.7,2.2,1,9.7c-0.4,4.6-1.1,7.2-0.2,9.3c0.1,0.3,0.5,1.1,0.8,2.3c0.3,1.1,0.3,2,0.3,3c0,1.8-0.2,2.5-0.7,5.3
								c-0.5,3.5-0.4,3.7-0.8,5.5c-0.3,1-0.6,2.2-1.3,3.7c-1.2,2.4-1.9,2.3-2.3,3.7c-0.6,2.1,0.8,2.7,1.2,6.3c0.2,2-0.2,2-0.3,6.2
								c-0.1,3.5-0.2,5.2,0.5,5.7c1.3,0.9,3.1-1.5,9.5-3.7c2.6-0.9,4-1.2,6.5-2.3c3.9-1.7,3.5-2.1,5.3-2.5c4.1-0.9,7.9,0.9,9.2,1.5
								c0.7,0.3,1.6,0.7,2.7,1.5c2.5,1.7,2.8,3.1,3.7,3c1.4-0.2,2.1-4.3,2.3-6.3c0.1-1.3,0-1.6,0.3-2c1.2-1.4,4.1,0.6,8.2,0.3
								c1.3-0.1,3.6-1,8-3c4.4-1.9,6.6-2.9,8-4.2c2.3-2.1,1.8-2.9,4.5-5.5c2.4-2.3,4.7-3.5,6-4.2c1.5-0.8,4.1-2.1,7.8-2.7
								c1.3-0.2,3.8-0.6,7,0c1.7,0.3,3,0.8,4,1.2C1021.9,551.6,1021.8,548.9,1021.7,547.1z'
							/>
							<text id='XMLID_39_' transform='matrix(1 0 0 1 960.5441 509.517)' className='st4 st5 text-map'>
								{t('kab-karanganyar')}
							</text>
						</g>
						<g id='Kabupaten_Wonogiri' className='region' from='home' post-name='kabupaten-wonogiri'>
							<path
								id='XMLID_226_'
								className='st18'
								d='M1067.7,602.8c-1-2.9-2.3-3.3-3.3-5.3c-1.4-2.9,0.5-3.8-0.3-8.3c-0.2-1-1-5.8-4.8-9
								c-1.6-1.3-2.6-1.5-3-2.8c-0.6-1.9,1.2-3,2-6.3c0.8-3.1,0.5-7.3-1.3-8c-0.3-0.1-1.4-0.4-3.5,1.3c0,0-2.2-1.5-3.3-2
								c-0.9-0.5-2.2-0.7-8.5,0.8c-3.3,0.8-6.6,1.7-9.1,0.8c-0.9-0.3-1.3-0.8-1.4-1.1c-0.4-1,0.5-1.7,0.5-2.9c0-0.9-1-1.9-3-3.8
								c-2.4-2.4-3.5-2.8-4.2-3.1c-0.8-0.3-0.9-0.1-1.6-0.4c-0.8-0.3-1.5-0.9-2-1.4c-0.9-0.3-2-0.7-3.3-0.9c-3.2-0.6-5.7-0.2-7,0
								c-3.7,0.6-6.4,1.9-7.8,2.7c-1.3,0.7-3.6,1.9-6,4.2c-2.7,2.6-2.2,3.4-4.5,5.5c-1.4,1.2-3.6,2.2-8,4.2c-4.4,2-6.7,2.9-8,3
								c-4.1,0.2-7-1.7-8.2-0.3c-0.3,0.4-0.2,0.7-0.3,2c-0.2,2-0.9,6.1-2.3,6.3c-0.8,0.1-1.2-1.3-3.7-3c-1.1-0.8-2-1.2-2.7-1.5
								c-1.3-0.6-5.1-2.4-9.2-1.5c-1.8,0.4-1.5,0.8-5.3,2.5c-2.5,1.1-3.9,1.5-6.5,2.3c-6.4,2.2-8.2,4.6-9.5,3.7c-0.3-0.2-0.4-0.7-0.5-1.5
								c-1.8,0.1-3.6-0.2-5.3-0.8c-1.7-0.6-2.9-1.4-3.5-1.9c-1.7-1.3-1.9-2-3.4-3.1c-1.6-1.2-3.2-1.6-4.8-2c-2.7-0.7-5-0.6-6.1-0.5
								c-1.9,0.1-3.3,0.5-3.9,0.6c-2,0.5-3.3,0.9-4.4,1.8c-2.5,2-1.6,4.4-4.1,6.1c-0.6,0.4-1.5,0.6-3.3,1.1c-2.9,0.8-4,0.7-5.9,1.3
								c-0.8,0.2-1.7,0.6-5.1,2.5c-5.4,3.1-8.1,4.6-9.8,6.5c-1.2,1.3-3,3.4-3.8,6.5c-0.7,2.7,0.1,3.6,0.3,8c0.1,3.7,0.3,6.9-1,10.3
								c-1.8,4.7-4.3,4.4-5.5,8.8c-0.5,1.9-0.3,4.3,0.3,9.3c0.5,4.6,0.8,4.8,1.3,9.5c0.6,6.2,0.2,7.8-0.3,9c-0.6,1.6-1.1,2.1-1.3,3.8
								c-0.3,3,1.3,4.2,0.5,5.5c-1,1.6-3.8,0.3-5,2c-0.6,0.8-0.3,1.5,0,4.5c0.3,3.6,0,3.7,0.3,5.5c0.4,2.7,1.3,3.6,3,7.3
								c2.8,6,4.3,9.2,4.3,13.3c0,1.1-0.1,3.1,1,5.3c0.7,1.3,1.4,1.8,2.3,2.8c2.1,2.3,3.3,5.3,3.8,7.3c0.8,3.7-1.1,4.3-0.8,10.3
								c0.2,2.7,0.6,3.9,1.5,4.8c1,1.1,2.9,2,4.5,1.5c1.4-0.4,1.4-1.6,4-4.3c1.3-1.4,2-2.1,2.5-2c2,0.3,1.1,6.8,5,9.3
								c1.6,1,2.2,0.1,3.8,1.3c1.9,1.4,1.5,3.3,3.8,7.8c1.1,2.1,1.6,3.2,2.8,3.8c1.8,0.9,2.9-0.2,5.3,0.5c1.7,0.6,1.6,1.4,4.3,3.3
								c2,1.4,4.6,3.3,6.8,2.5c1.2-0.4,1.7-1.5,2.5-3c1.7-3.3,2-6.5,2-9.3c0-5.1-0.9-5-0.8-8.3c0.2-3.8,1.5-4.1,5-12.8
								c2.5-6.2,2.3-7.3,4.3-9.5c1-1.1,1-0.8,5.5-5c3.2-3,3.9-3.8,4.5-5.3c1-2.6,0.4-4.9,1-5c0.8-0.2,1.2,4.1,3.8,5c2,0.7,3.3-1.3,5.8-0.5
								c1.9,0.6,2.5,2.3,3.3,2c0.8-0.3,0.3-2,0.8-4.5c0.4-2.1,1.3-3.5,3-6.3c1.7-2.7,2.6-4.1,4.3-5.3c2.3-1.6,4.7-1.9,5.8-2
								c2.4-0.3,2.7,0.4,8.8,1c3.5,0.4,4.3,0.3,5,0c2.6-0.9,3.3-2.8,4.5-2.5c0.8,0.2,0.8,1.3,2.3,5.3c0.8,2.3,1.2,3.4,1.8,4
								c1.5,1.6,3.8,1.8,6.3,2c2.4,0.2,4,0.3,5.5-0.8c1.2-0.9,1.7-2.1,2.3-3.8c0.9-2.5,0.5-3.3,1-5.5c0.9-4.1,2.9-4.1,3.5-7.5
								c0.5-2.6-0.2-4.7-1-7c-1.6-4.8-4-5.7-3.5-8.8c0.1-0.8,0.4-2.8,2-4.3c1.2-1.1,1.9-0.8,2.8-1.8c1.5-1.7,0.1-3.6,1.3-6
								c1.3-2.8,4.6-3.7,5.8-4c2.9-0.8,5.4-0.1,6.8,0.3c2.4,0.7,3,1.5,6.5,3.3c1.6,0.8,3.7,1.8,6,2.5c5.9,1.7,11.1,0.4,13.8-0.3
								c3.4-0.9,5.2-1.3,6.8-2.8c1.9-1.7,1.7-3.1,4.8-7.5c1.3-1.8,2-2.9,3.3-4.3c3.2-3.4,5.2-3.6,5.5-5.5c0.2-1.6-1.2-1.9-2-5.3
								c-0.4-1.7-0.9-3.6,0-5.3c0.7-1.4,1.6-1.1,3.8-3.3c1.2-1.2,2.2-2.2,2.5-3.8C1068.5,605.1,1068.1,603.9,1067.7,602.8z'
							/>
							<path
								id='XMLID_23_'
								className='st0'
								d='M1003.3,385.5C1003.3,385.5,1003.3,385.5,1003.3,385.5c0,0.1-0.1,0.2-0.1,0.3
								C1003.2,385.7,1003.3,385.6,1003.3,385.5z'
							/>
							<path id='XMLID_24_' className='st0' d='M1020.8,551.2c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0,0,0C1021.1,551.3,1020.9,551.3,1020.8,551.2z' />
							<text id='XMLID_253_' transform='matrix(1 0 0 1 916.0106 628.4987)' className='st4 st5 text-map'>
								{t('kab-wonogiri')}
							</text>
						</g>
						<g id='Kabupaten_Sukoharjo' className='region' from='home' post-name='kabupaten-sukoharjo'>
							<path
								id='XMLID_33_'
								className='st15'
								d='M869,590.5c1.7-1.9,4.4-3.4,9.8-6.5c3.4-1.9,4.3-2.3,5.1-2.5c1.9-0.5,3-0.4,5.9-1.3
								c1.8-0.5,2.7-0.8,3.3-1.1c2.6-1.7,1.6-4.1,4.1-6.1c1.1-0.9,2.3-1.2,4.4-1.8c0.6-0.2,2-0.5,3.9-0.6c1.2-0.1,3.4-0.2,6.1,0.5
								c1.5,0.4,3.1,0.8,4.8,2c1.5,1.1,1.7,1.9,3.4,3.1c0.6,0.5,1.8,1.3,3.5,1.9c1.7,0.6,3.5,0.9,5.3,0.8c-0.1-0.9,0-2.3,0-4.2
								c0.1-4.2,0.5-4.2,0.3-6.2c-0.4-3.6-1.8-4.3-1.2-6.3c0.4-1.4,1.1-1.3,2.3-3.7c0.7-1.5,1.1-2.7,1.3-3.7c0.5-1.8,0.3-2,0.8-5.5
								c0.4-2.8,0.7-3.6,0.7-5.3c0-1-0.1-1.9-0.3-3c-0.3-1.2-0.7-2.1-0.8-2.3c-1-2.1-0.3-4.7,0.2-9.3c0.7-7.5-0.4-9-1-9.7
								c-0.9-1-2.1-1.5-2.5-1.7c-1.2-0.5-2.3-0.5-5.8-0.3c-4.2,0.2-4.1,0.3-4.7,0.2c-3.1-0.9-4.5-4.6-4.7-5c-1.1-2.9,0.2-3.9-0.8-6.5
								c-1.1-2.7-3.4-4.1-4.3-4.7c-3.5-2.1-4.9-0.5-9.2-2.7c-3-1.5-3.6-2.9-5.5-2.7c-0.7,0.1-1.2,0.3-1.6,0.6c-0.9,1.1-1.8,2.4-2.6,3.7
								c-1.7,2.9-3.3,6.9-7.3,8.3c-1.4,0.5-3.8,0.9-4.8-0.3c-1.4-1.7,1.6-5.1,0.3-6.8c-0.9-1-3-0.6-5.3-0.3c-3.7,0.7-5.3,2.1-6.3,1.3
								c-0.6-0.5-0.2-1.4-0.5-3c-0.5-2.5-2.1-4.1-3-5c-0.4-0.4-3.6-3.5-8.3-3.5c-3.3,0-4.4,1.6-7,0.8c-2-0.7-2.6-2.1-3.5-1.8
								c-1.2,0.4-0.4,2.8-1.5,6.8c-1.1,3.6-2.6,4.5-2.3,7c0.1,0.4,0.3,2,1.5,3.3c2.1,2.2,4.8,1.3,8.5,2.5c2.9,0.9,4.7,2.6,6.8,4.5
								c2.2,2,6.9,6.5,7,12.3c0,3-1.3,3-3.5,10.3c-1,3.3-0.7,3-2.3,9.3c-1.7,6.8-2.6,10.3-4,12.8c-2,3.6-3.1,3.7-4,6.8
								c-0.9,2.9,0,3.1-0.5,8.5c-0.4,4.1-1.2,6.9-2,9.8c-0.7,2.6-1.1,3.4-1.5,4c-1.2,2-2.4,2.6-2.3,3.5c0.2,1,1.8,1,4.3,2
								c3.9,1.6,3.7,3.2,7.5,4.8c3,1.2,5.5,1.3,6,1.3c1.4,0,2.7-0.2,3.7-0.5c0-0.6,0.1-1.2,0.3-2C866,593.9,867.8,591.9,869,590.5z'
							/>
							<text id='XMLID_32_' transform='matrix(1 0 0 1 882.7446 540.165)' className='st4 st5 text-map'>
								{t('kab-sukoharjo')}
							</text>
						</g>
						<g id='Kabupaten_Klaten' className='region' from='home' post-name='kabupaten-klaten'>
							<path
								id='XMLID_241_'
								className='st15'
								d='M843.5,591.5c-0.2-0.9,1-1.5,2.3-3.5c0.4-0.6,0.8-1.4,1.5-4c0.8-2.9,1.6-5.7,2-9.8
								c0.5-5.4-0.4-5.6,0.5-8.5c0.9-3,2-3.2,4-6.8c1.4-2.5,2.3-5.9,4-12.8c1.6-6.2,1.2-6,2.3-9.3c2.2-7.3,3.5-7.2,3.5-10.3
								c-0.1-5.8-4.8-10.2-7-12.3c-2-1.9-3.8-3.6-6.8-4.5c-2.1-0.7-3.9-0.7-5.5-1c-0.1,0.7-0.3,1.5-0.5,2.3c-0.7,2.6-1.1,2.5-1.9,5.4
								c-0.8,3-0.6,4-1.6,5.3c-1.1,1.3-2.7,1.7-4.1,2c-4.8,1-7.2,1.6-10.6,2c-6.6,0.8-12.2,2.3-13.9,2.8c-4.1,1.1-4.9,1.6-7.8,1.9
								c-1.7,0.2-4.1,0-8.8-0.3c-4.3-0.3-6.5-0.4-9-0.8c-3.3-0.5-5-0.7-6.8-1.3c-4.1-1.3-4.8-3.5-10.5-8.4c-5.1-4.4-9.5-7.1-10.6-7.8
								c-6.3-3.8-6.7-2.6-9.8-5c-3.1-2.5-4.9-5.6-6.1-8.3c-0.6,0.6-1.3,1-2.1,1.4c-0.6,0.3-1.2,0.5-1.7,0.7c1,1.2,2.1,2.6,2.9,4.6
								c1.3,3.4-0.2,3.8,0.8,9.2c0.6,3.8,1.5,4,3.8,10.8c2,6,3,9,3.3,11.8c0.4,4.9-0.7,8.2,1.8,11c0.8,0.9,1.5,1.3,2,2.5
								c0.8,2,0,3.6-0.5,6.3c-0.4,2.2-0.3,3.8,0,7c0.3,3.8,0.6,6.9,2.5,10c1.2,1.9,2.7,3.3,3.5,4c2.4,2.2,3.3,2.1,5,4
								c1.8,2.1,1.4,3.1,3,4.5c0.8,0.7,2.2,1.6,5.8,2c7.8,0.8,12.5-2.2,14-0.3c0.9,1.2-0.3,2.8,1,4.5c0.9,1.1,2.3,1.4,3,1.5
								c2.2,0.4,3.5-0.5,9.3-2.5c2.9-1,3.8-1.3,5-1c1.8,0.4,1.9,1.4,3.8,2.3c3.4,1.6,7.3,0.3,8.3,0c3.1-1,4.5-2.7,5.8-2
								c0.8,0.5,0.5,1.4,1.5,2.5c1.3,1.5,3.5,1.7,4.8,1.8c3.9,0.3,4.7-3.1,8-2.8c2.1,0.2,2.4,1.5,4.3,1.5c1.5,0,2.7-0.8,3.8-1.9
								C843.9,592.2,843.5,592,843.5,591.5z'
							/>
							<text id='XMLID_254_' transform='matrix(1 0 0 1 773.6772 560.4987)' className='st4 st5 text-map'>
								{t('kab-klaten')}
							</text>
						</g>
						<g id='Kota_Surakarta' className='region' from='home' post-name='kota-surakarta'>
							<path
								id='XMLID_27_'
								className='st15'
								d='M854,491.5c4.6,0,7.9,3.1,8.3,3.5c0.9,0.9,2.5,2.5,3,5c0.3,1.6-0.1,2.5,0.5,3
								c1,0.8,2.6-0.6,6.3-1.3c2.2-0.4,4.4-0.8,5.3,0.3c1.4,1.6-1.6,5.1-0.3,6.8c0.9,1.1,3.3,0.8,4.8,0.3c4-1.4,5.6-5.4,7.3-8.3
								c0.8-1.4,1.7-2.6,2.6-3.7c0.2-0.1,0.3-0.2,0.5-0.3c-1.2,0.5-1.9,1.5-3.4,1.3c-1.1-0.1-2.4-0.8-2.8-1.8c-0.6-1.4,0.7-2.3,0.3-3.7
								c-0.4-1.4-1.9-1.4-4.3-2.8c-3.9-2.3-3.3-4.2-6.5-5.5c-2.3-0.9-2.7,0-5.2-1c-0.7-0.3-1.7-0.8-5-3.8c-0.3-0.3-0.6-0.6-0.9-0.9
								c-0.6,1-0.9,2.1-0.7,3.2c0.2,1.2,1.1,2,0.6,2.8c-0.5,0.8-2.1,0.8-4.9,0.8c-1.8,0-2.5-0.4-3.5,0.1c-1.4,0.7-0.9,2-2.3,3.1
								c-1.5,1.2-2.9,0.3-4.5,1.3c-1,0.6-1.6,1.6-2,2.5C849.6,493.1,850.7,491.5,854,491.5z'
							/>
							<text id='XMLID_26_' transform='matrix(1 0 0 1 863.8367 496.1655)' className='st4 st5 text-map'>
								{t('surakarta')}
							</text>
						</g>
						<g id='Kabupaten_Boyolali' className='region' from='home' post-name='kabupaten-boyolali'>
							<path
								id='XMLID_296_'
								className='st30'
								d='M839.7,504.3c-0.4-2.5,1.2-3.4,2.3-7c1.1-3.9,0.3-6.3,1.5-6.8c0.9-0.3,1.5,1.1,3.5,1.8
								c1.7,0.6,2.7,0.1,4.2-0.3c-1.4,0.4-2.5,0.9-4.1,0.3c0.4-1,1-2,2-2.5c1.6-0.9,3,0,4.5-1.3c1.4-1.1,0.9-2.4,2.3-3.1
								c1-0.5,1.7-0.2,3.5-0.1c2.8,0.1,4.4,0.1,4.9-0.8c0.4-0.7-0.4-1.6-0.6-2.8c-0.2-1.1,0.1-2.2,0.7-3.2c-2.3-2.2-3.6-3.3-3.9-4.1
								c-0.8-1.9-0.5-4.2,0.2-8.8c0.3-2.2,0.3-1.3,1-4.8c0.5-2.7,0.6-3.9,1.7-4.8c0.8-0.7,1.8-0.8,2.2-0.8c0.5-0.1,1.1-0.2,1.8-0.4
								c0,0-0.1-0.1-0.1-0.1c-0.7-1,0.4-1.6,0.8-5c0.5-4.3-1-5.6-0.5-11c0.2-1.7,0.4-2.7,0.8-3.7c1-2.2,2.4-3.3,3.2-4
								c2.8-2.7,1.2-5.4,3.3-15.3c1.1-5.2,2.1-7,1-9.5c-0.9-2-2-2-2.8-4.3c-0.7-2,0-2.5,0.2-7.8c0.1-3.8-0.1-4.9,0.8-5.8
								c0.7-0.7,1.5-0.7,4-1c3.6-0.4,4.9,0.3,6.7-0.8c0.7-0.5,0.6-0.6,1.7-1.5c0.5-0.4,0.9-0.7,1.4-1c-0.4-0.3-0.7-0.6-1.2-0.9
								c-1.5-1-2.4-1.1-3.2-2.2c-0.2-0.4-0.8-1.4-0.5-4.2c0.3-2.6,1-3.7,2.3-7.5c1.2-3.4,1.7-5,1.2-5.5c-0.9-0.7-3.8,1-5.8,3.2
								c-2.1,2.2-1.5,3.3-3.5,5.2c-1.4,1.3-3,1.9-6.2,3.2c-2.9,1.1-4.4,1.7-6,1.5c-0.2,0-4.3-0.5-5.2-3c-0.7-2,1.6-3.3,4.3-9
								c1.9-3.9,2.8-7.5,2.8-7.5c0.9-3.3,0.8-4.6,2.2-6.5c1.2-1.7,2.3-2.1,2.3-3.3c0-1.1-1-1.9-3.3-3.5c-7-5-7.9-5.3-9.2-5.5
								c-2.5-0.4-4.3,0.2-5.2,0.5c-1.3,0.4-2.5,0.8-3,1.8c-0.6,1.1,0.2,1.8-0.2,3.7c-0.1,0.4-0.4,2.2-1.8,3.3c-1.6,1.2-2.9,0.3-4.5,1.5
								c-1.2,0.9-0.6,1.6-1.8,3c-1.6,1.8-2.8,0.9-5.5,3c-0.9,0.7-1.4,1.3-2.5,1.7c-1,0.4-1.5,0.2-1.8,0.7c-0.6,0.8,0.1,2.4,0.8,3.5
								c0.5,0.7,0.9,0.9,3.7,2.8c1.3,0.9,1.9,1.3,2,1.5c1.6,2.5-1.5,6.9-2,7.7c-0.8,1.2-1.3,2-2.2,2.5c-3.1,1.8-6.3-1.6-14.3-3.3
								c-3.6-0.8-6.3-1.4-9.7-0.5c-1.3,0.3-2.2,0.8-3,1.2l0,0c0.5,0.2,0.8,0.4,0.8,0.7c0,0.4-0.6,0.6-0.6,1.1c0,0.4,0.4,0.7,0.8,1
								c0.9,0.8,1.2,1.8,1.5,2.8c0.4,1.2,0.4,2.2,0.3,2.7c-0.1,1.3-0.4,2.2-1.3,4.3c-1.5,3.6-1,2.5-2.2,5.2c-1.6,3.6-1.9,4.2-2.6,4.4
								c-1.4,0.5-2.7-1-3.6-0.3c-0.3,0.2-0.5,0.6-0.5,2.9c0,2.5,0,3.8,0.4,5.3c0.5,2,0.9,1.5,1.8,4.3c0.7,2,0.9,3.4,1.7,3.5
								c0.4,0.1,0.9-0.3,1.2-0.5c0.5-0.4,0.5-0.7,1.1-1.3c0.3-0.4,0.7-0.7,1.2-0.9c0.5-0.2,1.4-0.4,1.8,0.1c0.4,0.4-0.1,1,0.2,2.1
								c0.2,1,0.8,1.2,1.3,2.1c0.5,1.1,0.2,2.1-0.1,3.3c-0.7,3.7,0.4,4.2-0.3,7.5c-0.5,2.5-1.2,2.2-1.8,4.5c-0.8,3,0.3,3.9,1.2,11.4
								c0.7,5.6,0,4.5,0.6,7c0.4,1.8,1,3.7,0.1,4.6c-0.6,0.6-1.4,0.2-2,0.9c-0.9,1.1-0.1,3.2,0.1,3.6c0.3,0.7,0.9,2.2,2.1,2.5
								c0.7,0.2,1-0.3,3-0.9c1.2-0.4,2.5-0.8,2.8-0.4c0.3,0.5-1.2,1.3-1.3,3c-0.1,1.2,0.6,2.2,0.9,2.5c0.6,0.9,1.3,1.1,2.5,1.9
								c2.1,1.3,3.1,1.9,3.8,2.9c1.3,2,0.7,4.6,0.5,5.1c-0.1,0.4-1.1,3.8-3.6,4.5c-0.7,0.2-1.3,0.1-3.5-0.6c-2.9-1-5.1-1.9-6.9-2.6
								c-5.3-2.2-5.2-2.1-5.9-2.5c-1.6-0.9-2.1-1.3-3.4-1.6c-2-0.5-2.6,0.3-4.6,0.1c-2.5-0.2-5.3-1.8-5.5-3.5c-0.1-0.7,0.3-0.9,1.1-2.4
								c1-1.8,2-3.6,2-5.3c-0.1-2.9-3.5-6-6.4-5.8c-1.3,0.1-2.2,0.9-2.3,0.9c-1.1,0.8-1,1.7-2.3,3c-0.6,0.6-1.3,1.4-2.1,1.3
								c-0.7-0.1-0.8-0.9-1.8-2c-1.3-1.6-3-2.3-4.9-3c-0.8-0.3-1.4-0.5-2.3-0.8c-1.9-0.6-2.8-0.7-3.6-0.9c-2.5-0.7-2.3-2.1-5.5-4.3
								c-1-0.7-2.6-1.4-5.9-2.8c-2-0.9-3-1.3-3.6-1.4c-2.6-0.4-6.1,0.5-7.1,2.6c-0.4,0.8-0.2,1.2-0.8,2.3c-0.6,1.1-1.4,1.7-2.1,2.3
								c-1.5,1.2-1.9,0.9-3.6,2.1c-0.7,0.5-1.1,0.8-1.4,1.1c0.1-0.1,0.3-0.3,0.5-0.4c0.2,0.8,0.5,1.8,0.6,2.9c0.4,2.2,0.6,3.7,0.2,5.7
								c-0.4,1.8-1,1.8-2.5,5.2c-1.1,2.4-0.8,2.5-1.7,4.2c-0.7,1.4-1.6,3.2-3.5,4.8c-0.4,0.3-1.5,1.1-3.7,2.5c-1.9,1.2-2.6,1.7-3,2.7
								c-0.3,0.8-0.5,2.3-0.5,2.3c0,0,6.6,1.4,11.7,5.5c1.3,1.1,1.6,1.6,3.2,2.7c2.5,1.7,3.7,1.6,4.5,3c0.9,1.5,0.7,3.6-0.2,5
								c-0.2,0.3-0.4,0.5-0.6,0.7c1.3,2.6,3,5.7,6.1,8.3c3,2.4,3.4,1.2,9.8,5c1.1,0.7,5.5,3.4,10.6,7.8c5.7,4.9,6.4,7.1,10.5,8.4
								c1.7,0.6,3.5,0.8,6.8,1.3c2.5,0.4,4.7,0.5,9,0.8c4.7,0.3,7,0.4,8.8,0.3c2.8-0.3,3.7-0.8,7.8-1.9c1.7-0.4,7.3-1.9,13.9-2.8
								c3.4-0.4,5.8-1,10.6-2c1.4-0.3,3-0.7,4.1-2c1-1.2,0.9-2.3,1.6-5.3c0.8-2.9,1.2-2.8,1.9-5.4c0.2-0.9,0.4-1.7,0.5-2.3h0
								c-1.1-0.2-2.1-0.6-3-1.5C840,506.2,839.8,504.7,839.7,504.3z'
							/>
							<text id='XMLID_255_' transform='matrix(1 0 0 1 762.0106 496.1653)' className='st4 st5 text-map'>
								{t('kab-boyolali')}
							</text>
						</g>
					</svg>
				</div>
			</div>
		</>
	)
}
