import React, { useEffect } from 'react'
import PageDetail from '../../../components/PageDetail'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { successStoryDetailStore } from '../../../stores/_rootStore'
import i18n from '../../../i18n'

function SuccessStoryDetail() {
	const { t } = useTranslation()
	const { postName } = useParams()
	useEffect(() => {
		const fetchData = async () => {
			try {
				await successStoryDetailStore.fetchData(postName)
				await successStoryDetailStore.fetchOtherData()
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
		// eslint-disable-next-line
	}, [postName, i18n.language])

	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('invest') }, { label: t('success-story') }, { label: successStoryDetailStore.data?.post_title }]
	if (successStoryDetailStore.isLoading) return <div>Loading...</div>
	if (successStoryDetailStore.data !== undefined)
		return (
			<div>
				<PageDetail breadcrumbItems={breadcrumbItems} bannerImg={successStoryDetailStore.data.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}>
					<h1 className='font-bold text-2xl pb-2'>{successStoryDetailStore.data.post_title}</h1>
					<hr />
					<p dangerouslySetInnerHTML={{ __html: successStoryDetailStore.data.post_content }}></p>
				</PageDetail>
			</div>
		)
}

export default observer(SuccessStoryDetail)
