import React, { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules'
import { useTranslation } from 'react-i18next'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/swiper-bundle.css'

import useCrud from '../../hooks/useCRUD'
import MacroeconomicsChart from './MacroeconomicsChart/MacroeconomicsChart'
import { Link } from 'react-router-dom'
import { Chart, registerables } from 'chart.js'

// Register Chart.js components
Chart.register(...registerables)

export default function HomeInfo() {
	const { i18n, t } = useTranslation()
	const [berita, setBerita] = useState({})
	const [peluangInvestasi, setPeluangInvestasi] = useState({})
	const { getAllResources } = useCrud('post')
	const [seeAllChart, setSeeAllChart] = useState(false)

	async function fetchData() {
		try {
			const resBerita = await getAllResources({
				lang: i18n.language,
				type: 'berita',
				limit: 6
			})
			setBerita(resBerita)
			const resPeluangInvestasi = await getAllResources({
				lang: i18n.language,
				type: 'peluang_investasi',
				meta: true,
				limit: 6
			})
			setPeluangInvestasi(resPeluangInvestasi)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [i18n.language])

	const ViewAll = ({ onClick, isVisible }) => {
		return (
			<div className='flex w-full justify-end'>
				{!isVisible && (
					<p className='text-primary font-bold cursor-pointer mt-4' onClick={onClick}>
						{t('see-all')} <i className='fa fa-caret-right'></i>
					</p>
				)}
			</div>
		)
	}

	return (
		<div className='flex items-center justify-center py-16 px-8 xl:px-16 bg-white'>
			<div className='flex flex-col xl:flex-row justify-between w-full gap-8'>
				<div className='xl:w-1/3'>
					<h3 className='font-bold'>{t('makroekonomi_jateng')}</h3>
					<div className='flex flex-col mt-8 gap-4'>
						<Link to='/statistic/economic-growth'>
							<MacroeconomicsChart category='economic-growth' translationKey='economic_growth' />
						</Link>

						<Link to='/statistic/inflation'>
							<MacroeconomicsChart category='inflation' translationKey='inflation' />
						</Link>
						<Link to='/invest-realization'>
							<MacroeconomicsChart category='invest-realization' translationKey='investment-realization' yTitle='Triliun Rupiah' />
						</Link>
						<MacroeconomicsChart category='international-trade' translationKey='international-trade' yTitle='Milion Dollars' />

						{seeAllChart && (
							<>
								<Link to='/link/summary'>
									<div className='border w-full border-emerald-700 rounded p-4'>
										<div>
											<b className='' style={{ color: '#404040' }}>
												{t('assesment-economy')}
											</b>
											<div className='mt-4'>
												<div className='bg-primary-light h-4'></div>
											</div>
										</div>
									</div>
								</Link>

								<Link to='/link/central_java_statistics'>
									<div className='border w-full border-emerald-700 rounded p-4'>
										<div>
											<b className='' style={{ color: '#404040' }}>
												{t('statistic')}
											</b>
											<div className='mt-4'>
												<div className='bg-primary-light h-4'></div>
											</div>
										</div>
									</div>
								</Link>
							</>
						)}
					</div>
					{!seeAllChart && (
						<div className='' onClick={() => setSeeAllChart(true)}>
							<ViewAll />
						</div>
					)}
				</div>
				<div className='xl:w-1/3'>
					<h3 className='font-bold'>{t('newsevent')}</h3>
					<div className='flex flex-col mt-8 gap-4'>
						{berita?.data?.data?.map((item, index) => {
							const postDate = typeof item.post_date === 'string' ? new Date(item.post_date) : item.post_date

							if (isNaN(postDate)) {
								console.error('Invalid date:', item.post_date)
								return null
							}

							const day = postDate.getDate()
							const month = postDate.toLocaleString('default', { month: 'short' }).toUpperCase()
							const year = postDate.getFullYear()

							return (
								<Link to={`/news/${item.post_name}`} key={index} className='w-full flex items-center gap-4'>
									<div className='bg-primary-dark gap-1 flex flex-col justify-center font-bold items-center text-white min-w-[80px] h-[70px] rounded-xl'>
										<span className='text-2xl leading-none'>{day}</span>
										<span className='text-xs leading-none'>{month}</span>
										<span className='text-xs leading-none'>{year}</span>
									</div>
									<div>
										<h6 className='line-clamp-2 font-semibold'>{item.post_title}</h6>
										<small className='text-gray-500 line-clamp-2' dangerouslySetInnerHTML={{ __html: item.post_content }}></small>
									</div>
								</Link>
							)
						})}
					</div>
					<Link to='/news'>
						<ViewAll />
					</Link>
				</div>
				<div className='xl:w-1/3'>
					<h3 className='font-bold'>{t('opportunities')}</h3>
					<div className='border w-full border-emerald-700 rounded mt-8 p-8'>
						{peluangInvestasi && (
							<Swiper
								modules={[Navigation, Autoplay]}
								navigation={{
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev'
								}}
								loop={peluangInvestasi?.data?.data?.length > 1}
								autoplay={{
									delay: 3000,
									disableOnInteraction: false
								}}
								className='mySwiper mb-4 rounded-lg'
							>
								{peluangInvestasi?.data?.data?.map((item, index) => (
									<SwiperSlide key={index}>
										<Link to={`/investment/investment-potential/${item.post_name}`} key={index}>
											<div className='bg-slate-900 h-[260px] flex flex-col justify-center text-white'>
												<img src={`${process.env.REACT_APP_IMAGE_URL}${item?.post_meta.filter((meta) => meta.meta_key === 'banner')[0]?.meta_value}`} alt={item.post_title} className='object-fit w-full h-full' />
											</div>
										</Link>
									</SwiperSlide>
								))}

								<div className='swiper-button-prev text-white border-white'></div>
								<div className='swiper-button-next text-white border-white'></div>
							</Swiper>
						)}

						{peluangInvestasi?.data?.data?.map((item, index) => {
							return (
								<Link to={`/investment/investment-potential/${item.post_name}`} key={index}>
									<h6 className={`font-semibold mt-2 ${index % 2 ? 'text-gray-500' : 'text-gray-700'}`}>{item.post_title}</h6>
								</Link>
							)
						})}
					</div>
					<Link to='/investment'>
						<ViewAll />
					</Link>
				</div>
			</div>
		</div>
	)
}
